"use client";

import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { buttonVariants } from "src/components/ButtonNew/Button";

import { readAsSpacing } from "src/enums";
import Caption from "./Caption";
import ContactPerson from "./ContactPerson";
import Content from "./Content";
import Description from "./Description";
import Section from "./Section";
import StyledImage from "./StyledImage";
import { SmallText } from "../../../styles/FontFaces";
import { dimensions } from "../../../styles/variables";
import ButtonContainer from "../../Button/ButtonContainer";
import { CmsImage } from "../../Image/CmsImage";
import { readCmsImageData } from "../../Image/utils";
import Link from "../../Link/Link";

export const CallToActionSectionFragment = graphql(`
	fragment CallToActionSectionItem on CallToActionSectionRecord {
		__typename
		id
		ctaDescription: description
		spacingTop
		spacingBottom
		contactPersonImage {
			...ImageFields
			responsiveImage(imgixParams: { w: 768 }) {
				# 🥃 TODO: find correct image dimensions
				...ResponsiveImage
			}
		}
		contactPersonCaption
		ctaButtons: simpleTextGroups {
			id
			text: text1
			link: text2
		}
	}
`);

export const CallToActionSection: React.FC<{
	section: FragmentType<typeof CallToActionSectionFragment>;
}> = ({ section }) => {
	const {
		spacingTop,
		spacingBottom,
		ctaDescription,
		ctaButtons,
		contactPersonImage,
		contactPersonCaption,
	} = getFragmentData(CallToActionSectionFragment, section);
	const resolvedContactPersonImage = readCmsImageData(contactPersonImage);

	const hasButtons = ctaButtons.length > 0;

	return (
		<Section
			marginTop={readAsSpacing(spacingTop)}
			marginBottom={readAsSpacing(spacingBottom)}
		>
			<Content
				hasContactPerson={Boolean(
					resolvedContactPersonImage && contactPersonCaption,
				)}
			>
				<Description>{ctaDescription}</Description>
				{hasButtons && (
					<ButtonContainer
						align="center"
						marginTop={dimensions.spacing.px40}
					>
						{ctaButtons.map(
							({ id, link, text }) =>
								link &&
								text && (
									<Link
										key={id}
										to={link}
										className={buttonVariants({
											isLink: true,
											className: "mx-px-10",
										})}
									>
										{text}
									</Link>
								),
						)}
					</ButtonContainer>
				)}
				{resolvedContactPersonImage && contactPersonCaption && (
					<ContactPerson>
						<StyledImage>
							<CmsImage image={resolvedContactPersonImage} />
						</StyledImage>
						<Caption>
							<SmallText textAlign="left">
								{contactPersonCaption}
							</SmallText>
						</Caption>
					</ContactPerson>
				)}
			</Content>
		</Section>
	);
};
