import { createNavigation } from "next-intl/navigation";
import { defineRouting } from "next-intl/routing";
import { SiteLocale } from "src/__generated__/graphql";

export const locales = [
	SiteLocale.en,
	SiteLocale.de,
] as const satisfies Array<SiteLocale>;
export const routing = defineRouting({
	locales,
	defaultLocale: SiteLocale.en,
	// TODO alternate links aren't generated correctly; this is a temporary fix. See https://github.com/peerigon/nextwww/issues/306
	alternateLinks: false,
});

export const { usePathname } = createNavigation(routing);
