import { useTranslations } from "next-intl";
import React from "react";
import IconNoData from "../../../assets/Icon-No-Data.svg";

const NoEventsScreen: React.FC = () => {
	const t = useTranslations();

	return (
		<div className="flex flex-col items-center justify-center p-px-30">
			<p className="text-size-7 m-0 pb-px-10 text-center font-sans">
				{t("noEvents")}
			</p>
			<IconNoData />
		</div>
	);
};

export default NoEventsScreen;
