import { cn, tw } from "@peerigon/pupper/tailwind";
import Link from "src/components/Link/Link";

import { PageType, PageTypeType } from "src/enums";
import { SUCCESS_STORIES_HEADER_ITEM_ID } from "src/lib/cms-constants";
import type { NavLink } from "src/components/Navigation/Navigation";

type MobileNavLinkProps = {
	link: NavLink;
	size?: "big" | "small";
	currentPageType?: PageTypeType;
	selectedLinkRouteName: string | undefined;
	setSelectedLinkRouteName: (routeName: string) => void;
};

const MobileNavLink: React.FC<MobileNavLinkProps> = ({
	link,
	size = "big",
	currentPageType,
	selectedLinkRouteName,
	setSelectedLinkRouteName,
}) => {
	const linkSizeVariants = {
		big: tw`h-[62px] px-px-30 py-5`,
		small: tw`h-4 px-2 py-1.5`,
	};
	const textSizeVariants = {
		big: tw`text-[32px] leading-larger`,
		small: tw`text-xs leading-larger`,
	};

	return (
		<Link
			to={link.routeName}
			className={cn(
				"flex cursor-pointer select-none items-center whitespace-nowrap rounded-full border-none bg-transparent transition-colors disable-anchor-shadow-styles",
				{
					"bg-black text-white":
						selectedLinkRouteName === link.routeName ||
						(link.id === SUCCESS_STORIES_HEADER_ITEM_ID &&
							currentPageType === PageType.detailedCaseStudy),
				},
				"outline-1 outline-black focus-visible:!rounded-full focus-visible:!outline-[1px] focus-visible:!outline-black",
				linkSizeVariants[size],
			)}
			onClick={() => {
				setSelectedLinkRouteName(link.routeName);
			}}
		>
			<span className={textSizeVariants[size]}>{link.label}</span>
		</Link>
	);
};

export default MobileNavLink;
