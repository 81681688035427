import { cn } from "@peerigon/pupper/tailwind";
import slugify from "@sindresorhus/slugify";
import { useTranslations } from "next-intl";
import { useEffect } from "react";
import { Hx, HxBoundary } from "uberschrift";
import LinkIcon from "src/assets/link.svg";
import Anchor from "src/components/Anchor/Anchor";
import { buttonVariants } from "src/components/ButtonNew/Button";
import CardCarouselContainer from "src/components/Card/CardCarouselContainer";
import { CmsImageData } from "src/components/Image/utils";
import Label from "src/components/Label/Label";
import Link from "src/components/Link/Link";
import ShareButton, {
	SHARE_TOAST_DURATION,
} from "src/components/ShareButton/ShareButton";
import { useOneOffAnimationClass } from "src/components/helpers";
import { Markdown } from "src/components/sections/MarkdownSection/MarkdownSection";

export type CardProps = {
	name?: string;
	headline: string;
	labels?: Array<{
		name: string;
		isActive: boolean;
	}>;
	description?: string;
	action?: {
		label: string;
		url: string;
	};
	websiteUrl?: string;
	canShare?: boolean;
	imagesData?: Array<CmsImageData>;
};

const Card: React.FC<CardProps> = ({
	name,
	headline,
	labels,
	description,
	action,
	websiteUrl,
	canShare = true,
	imagesData,
}) => {
	const t = useTranslations();
	const slug = slugify(name ?? headline);
	const [ref, triggerHighlight] = useOneOffAnimationClass<HTMLDivElement>(
		"card-highlight",
		SHARE_TOAST_DURATION,
	);

	const isCaseStudyLinked =
		typeof window !== "undefined" && window.location.hash === `#${slug}`;

	useEffect(() => {
		if (isCaseStudyLinked) {
			triggerHighlight();
		}
	}, [isCaseStudyLinked, triggerHighlight]);

	return (
		<div className="h-full @container">
			<Anchor id={slug} />
			<div
				ref={ref}
				style={{
					"--share-toast-duration": `${SHARE_TOAST_DURATION}ms`,
				}}
				className={cn(
					"flex h-full flex-col overflow-hidden rounded-lg border border-gray-nav bg-white shadow-card @8xl:flex-row @8xl:gap-px-60 @8xl:border-2 @8xl:py-px-20 @8xl:pl-px-20 @8xl:pr-px-60",
					"group transition duration-[var(--share-toast-duration)] ease-in-out [&.card-highlight]:border-peppermint [&.card-highlight]:bg-foam",
				)}
			>
				{imagesData?.length ? (
					<div className="h-[360px] shrink-0 @2xl:h-[400px] @8xl:h-[auto] @8xl:w-[40cqw] @8xl:max-w-[395px] @9xl:w-[46cqw] @9xl:max-w-[556px]">
						<CardCarouselContainer images={imagesData} />
					</div>
				) : null}

				<div
					className={cn(
						"flex h-full w-full flex-col gap-6 rounded-b-[7px] border border-t-0 border-gray-nav p-6 @8xl:h-auto @8xl:min-h-full @8xl:rounded-none @8xl:border-none @8xl:p-0 @9xl:gap-4",
						"transition duration-[var(--share-toast-duration)] ease-in-out group-[&.card-highlight]:border-peppermint",
						{ "@8xl:py-5": Boolean(imagesData?.length) },
					)}
				>
					{name ? <div className="text-size-6">{name}</div> : null}
					<HxBoundary>
						<Hx className="text-size-4 m-0">{headline}</Hx>
					</HxBoundary>
					{labels?.length ? (
						<div className="flex flex-row flex-wrap items-center gap-2">
							{labels.map((label) => (
								<Label
									key={label.name}
									isActive={label.isActive}
								>
									{label.name}
								</Label>
							))}
						</div>
					) : null}
					{description ? (
						<div className="text-black [&_div]:m-0 [&_p]:text-base [&_p]:font-light [&_p]:leading-larger">
							<Markdown source={description} />
						</div>
					) : null}
					{action || websiteUrl || canShare ? (
						<div className="mt-auto flex flex-row flex-wrap items-center justify-between gap-x-1 gap-y-3">
							{action ? (
								<Link
									to={action.url}
									className={buttonVariants({
										variant: "secondary",
										isLink: true,
									})}
								>
									{action.label}
								</Link>
							) : (
								<div />
							)}
							<div className="flex flex-row items-center gap-1">
								{websiteUrl ? (
									<Link
										to={websiteUrl}
										className={buttonVariants({
											variant: "ghost",
											isLink: true,
										})}
									>
										<LinkIcon /> {t("website")}
									</Link>
								) : null}
								{canShare ? (
									<ShareButton
										slug={slug}
										onClick={triggerHighlight}
									/>
								) : null}
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default Card;
