import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions } from "../../../styles/variables";

const StyledImage = styled.div`
	height: ${dimensions.callToActionSection.imageSize.small}px;
	width: ${dimensions.callToActionSection.imageSize.small}px;
	margin-left: ${(dimensions.callToActionSection.imageSize.small /
		dimensions.callToActionSection.imageCropFactor) *
	-1}px;

	${mediaQuery.md} {
		height: ${dimensions.callToActionSection.imageSize.medium}px;
		width: ${dimensions.callToActionSection.imageSize.medium}px;
		margin-left: ${(dimensions.callToActionSection.imageSize.medium /
			dimensions.callToActionSection.imageCropFactor) *
		-1}px;
	}

	${mediaQuery.lg} {
		height: ${dimensions.callToActionSection.imageSize.regular}px;
		width: ${dimensions.callToActionSection.imageSize.regular}px;
		margin-left: ${(dimensions.callToActionSection.imageSize.regular /
			dimensions.callToActionSection.imageCropFactor) *
		-1}px;
	}

	${mediaQuery.xl} {
		height: ${dimensions.callToActionSection.imageSize.large}px;
		width: ${dimensions.callToActionSection.imageSize.large}px;
		margin-left: ${(dimensions.callToActionSection.imageSize.large /
			dimensions.callToActionSection.imageCropFactor) *
		-1}px;
	}

	img {
		border-radius: 50%;
		aspect-ratio: 1;
		object-fit: cover;
	}
`;

export default StyledImage;
