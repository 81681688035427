import { SiteLocale } from "src/__generated__/graphql";
import LanguageSwitch from "src/components/LanguageSwitch/LanguageSwitch";
import HomeLink from "src/components/Navigation/HomeLink";

type DesktopNavigationWrapperProps = {
	mainNav: React.ReactNode;
	secondaryNav?: React.ReactNode;
	disableLanguageSwitch?: boolean;
	onChangeLocale: (locale: SiteLocale) => void;
};

const DesktopNavigationWrapper: React.FC<DesktopNavigationWrapperProps> = ({
	mainNav,
	secondaryNav,
	disableLanguageSwitch,
	onChangeLocale,
}) => {
	return (
		<>
			<div className="flex h-[35px] flex-row items-center justify-between gap-1">
				<div className="flex-shrink-0">
					<HomeLink />
				</div>
				<nav className="absolute left-0 right-0 flex translate-y-[150%] flex-row justify-center transition-transform duration-500 lg:static lg:translate-y-0">
					{mainNav}
				</nav>
				<div className="flex flex-shrink-0 flex-row items-center gap-1">
					{secondaryNav}
					<LanguageSwitch
						disableSwitch={disableLanguageSwitch}
						onChangeLocale={onChangeLocale}
					/>
				</div>
			</div>
			<div className="grid h-[52px] grid-rows-[1fr] transition-all duration-300 lg:h-0 lg:grid-rows-[0fr]">
				{/* empty div for tablet/mobile nav second row */}
			</div>
		</>
	);
};

export default DesktopNavigationWrapper;
