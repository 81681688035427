import { z } from "zod";

type CmsImageInput = {
	__typename: "FileField";
};

type R<T> = T extends null ? null : CmsImageData;

const cmsImageSchema = z
	.object({
		__typename: z.literal("FileField"),
		url: z.string(),
		alt: z.string().nullable(),
		mimeType: z.string(),
		width: z.number().nullable(),
		height: z.number().nullable(),
		responsiveImage: z
			.object({
				__typename: z.literal("ResponsiveImage"),
				src: z.string(),
				srcSet: z.string(),
				width: z.number(),
				height: z.number(),
				alt: z.string().nullable(),
				title: z.string().nullable(),
				base64: z.string().nullish(),
				bgColor: z.string().nullish(),
				sizes: z.string().nullable(),
			})
			.nullable(),
	})
	.passthrough();
export type CmsImageData = z.infer<typeof cmsImageSchema>;

export const readCmsImageData = <T extends CmsImageInput | null>(
	input: T,
): R<T> => {
	if (input === null) return null as R<T>;

	try {
		return cmsImageSchema.parse(input) as R<T>;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error("Error parsing CmsImageData", error, input);
		return null as R<T>;
	}
};
