const map = {
	tWmh1k8SmCa0NCcyXqmw: "1739957060-peerigon-symbol-flat-minze.png",
	"6dYVD2bpRGKgvJtR7Cwv": "1739885492-bmwi_go-digital.pdf",
	Cp7cqfjS9yeWD7kn8NXg: "1739885740-pressefotos.zip",
	rcuqkfyLTz2HUgX94HU0: "1739885182-workshops.jpg",
	UVUTZ5mHQye8GyaDxzIs: "1739885891-vue.svg",
	EzoQ6SzyTdiWppTWZngY:
		"1739885164-corporate-social-responsibility-definition-of-fairness.jpg",
	jb1YxF7IQYG8kLXOb7In:
		"1739884944-58307782-088bf300-7e00-11e9-8a49-43e60f1c7172.jpeg",
	v8zYuEwdSgGLHz8BXOL7: "1739885067-button-header-john-lester-cc-by-2-0.jpg",
	ZzQS2y3iSZSnmh2o8LBp: "1739885949-avatar-add-2.svg",
	STChhjrScumkWbmwsni2: "1739884977-typescript.png",
	"2PKFFeHyTq6Z2pdn4Fwa": "1739885541-ezgif-6-c10c75724990_2.gif",
	"5Q3NNXASXyjrn4nxc5Jg": "1739885690-image-gallery-broad-workshop2.png",
	"7z6xkzDSSl2ymosaJl91": "1739885003-yaskawa.svg",
	ZEL3loZTqyNOPhO2SIh6: "1739885768-contact-judith-leo.png",
	Y8gLyTY9RpudkFJvED5d:
		"1739885242-remote-office-same-lamp-in-two-homes.jpeg",
	lWBQYtgrTV6eZk5eKOfd: "1739885258-i-m-a-button-with-yellow-outline.gif",
	lsoxEARgRXGzFWw4XMYi: "1739885158-javascript-conference-community.jpg",
	AluBMkSvSyuzHYTErjEq: "1739885687-image-gallery-narrow-desktop.png",
	zuucr59SO2j6KMIihQwQ: "1739885323-workshop_infoblock2.jpg",
	sYIdAexTTWp324Z9BwFW: "1739886010-yaskawa-200.svg",
	"6QmP5BK9RF6XlXe3CM86": "1739885570-sustainability_donations-min.png",
	tj07GVqdQ2GoRggNGz5q: "1739885972-factsheet-typescript-workshop_en.pdf",
	DkiDwzZYTpSWvreR2oBQ:
		"1739885516-streamline-icon-human-ai-teamwork-3-280x280.svg",
	aQim5WAgQC6VEnDIkxYL: "1739885770-huddle-peerigon-imagecloud-1.png",
	gzWApUsUSNKzXdFcQ1Gr: "1739885574-autoscout24.svg",
	dD1yUMOPSISmHQ8JjGW3: "1739885835-khrystyna_s_color.png",
	GMYpi3MvT6GKttHoqMML: "1739885455-csm_ene_crt_b_p_1000px_5f98a6d31a.jpg",
	MnzDjhQgQjSfqi34jB0F: "1739885443-node.png",
	"6uL1wSjHSbqNGR7hD2MA": "1739885004-sueddeutsche.svg",
	"8sgogaEGS2WIcYqW456I": "1739885960-react-workshop.png",
	"5NBj8zSZQC5jhuyolIuZ": "1739885470-peerigon-logo-structured-data.png",
	POXw3uHUQFWHdQpqjKW4: "1739885880-javascript-grafik.png",
	kdgB1ccrRXCxMSueV6hJ: "1739885109-montagelaufzettel-case-study.jpg",
	rSE8mfNATDC66vAxEhut: "1739885771-linkedin-logo.svg",
	tXi8si5SXih0QTdYdxTA: "1739885130-megafone-and-media.svg",
	JxZ2Z3xShSlGOdX6Nk5U: "1739885531-icon-knowledge.svg",
	"4ltuCCxQzCDcChKwci3r": "1739885527-icon-knowledge.svg",
	MdmrfLVSTJy0OAV7osac: "1739885937-robot.svg",
	"0BNEQktCR86w0tDmLNBu": "1739885218-jsk-quer.png",
	nGBZM4grQAOxeYAxDP0A: "1739885406-mvimg_20180705_200614_1.jpg",
	VdI7BRVCSchQfnTSmcpO: "1739885166-kontakt-irena.jpg",
	gvlMKMDcRCK02yPtZ44L: "1739885265-netscape-now-button.gif",
	njDWcdUReGd4EdzKjrCQ: "1739885526-icon-support.svg",
	YCXALRRhRp66W0Bd5oiV: "1739885643-girls-day-2019.jpg",
	T4q4t2X4ROZCVA7YYSM3: "1739885160-corporate-social-responsibility.jpg",
	"1AQ4PTh1Rd2BWtbzG18U": "1739885180-kontakt-johannes-michael.jpg",
	qHv8B6BzRoe1vIx5Hrx8: "1739885811-04-snapshot-updated.png",
	ZbGGU9PXQwOs8VIYPiT4: "1739884945-working-from-home.png",
	sfBmTMrMQzc6GuNS4NJA:
		"1739885124-javascript-new-company-cartoon-by-judith-urban.jpg",
	YpA2UMbSQZaMc6kG9y4V: "1739884953-irena-jpg.png",
	bATuO5LIQBiQLonM4FZU: "1739884952-1-naxhtuvmcl4a0tignwzazg.jpeg",
	UMbi4gPQTmuOQGKGZuvK: "1739885370-max.jpg",
	frhjZdG1SJuIyNu3JzOV: "1739885491-icon-go-digital.png",
	FMEcepCTTMSVwhfe0YlM: "1739885259-swimmer-with-waterbottle.png",
	GN1ZWb3rRo6XfpNrFxtf: "1739885830-uxui.png",
	"215rLHqwTxaPjiw7QOAQ": "1739884987-orderfox.svg",
	MClB3jCOS2vlWeRaOzxT:
		"1739885176-nodeschool-augsburg-free-programming-workshop.jpg",
	e9Bu84aOTF6QsOoLCPXn: "1739885791-pinguin.png",
	"9RtMvaQCSgOxFdETAlOU":
		"1739885517-streamline-icon-coffee-stovetop-pot-280x280.svg",
	ljFCC3ESS3C8yZqZumdB: "1739885586-uni-augsburg.svg",
	eHvJXns1QFqugda0IPP4: "1739885665-image-gallery-broad-selfie.png",
	IISuZ2j5SiT08ZpdXQww: "1739885407-peerigon_2020_ma-132.jpg",
	bIRVg4ilTIaG8q3zQmr9: "1739885832-smashing-conference.png",
	cEraG3mS5WbI21tDomko: "1739885394-peerigon_2020_ma-047.jpg",
	YICILYoTSayjMGU4HPRw: "1739885900-businessman-peak-5-streamline-ux.svg",
	"9m7yH9BeR1C01B36AIIq": "1739885562-jobrad.svg",
	qrGlrTsJRg6b8GUKbMJ2: "1739885867-icon-case-studies.png",
	"585gvD0NQxCEcqU2qUwy": "1739885280-writing-an-interpreter-compiler.gif",
	rSQpSd6tSTisiRtYPZ4M: "1739885346-kontakt-lisa-stephan.jpg",
	EmIiteBTQWCIMv2VjHX0: "1739885168-kontakt-lisa-stephan.jpg",
	c46k6g7TZiPXcJgeUiMU: "1739885856-image-gallery-narrow-uiux.png",
	jlAAbUffSIaa5pa7tM1s: "1739885237-paul.jpg",
	nzlwFpH4SyS5DVbMETAE: "1739886005-prosieben-200.svg",
	WRf93CoSAyOCCRhXng22: "1739885594-adesso.svg",
	ZRVxq67TDaGrwPWLSKTs: "1739885276-vs-code-breakpoints.png",
	Qwm6xfUUTu62uG5pdpUQ: "1739885819-personalmanagerin.png",
	cjj9lylBRWGDonoPeKNA: "1739885935-app-working.svg",
	TxVku1UQSKujYEMSdTf3: "1739885137-22vt96gq_400x400-jpg.png",
	px6ujpWiRjeCtKi8w84R: "1739884979-produkt.png",
	sTBC0ziSx6hM3lkXoKBr: "1739885118-screenshot-2020-09-18-at-15-46-23.jpg",
	OznN3XnnQ3OhyPT4Adwt: "1739885019-timekeeping.png",
	jY2HEn9oRV6XOWXdtOxG: "1739885210-peerigon_logo_neg_rgb.svg",
	gJtLjmj0TsmogUtbyP3M:
		"1739885085-javascript-keyword-this-illustration-by-judith-urban.jpg",
	"2X2d1ulQPGEW7tlRPtuZ": "1739885896-workspace-outdoor-1-streamline-ux.svg",
	MQBJ4MKSvSLucYUGAiPz:
		"1739885976-grafik-top-5-reasons-why-startups-fail.png",
	EJcXdSUDSaaX2LvY1CBu: "1739885220-charta-der-vielfalt.jpg",
	BYYU3QnVQDqvGT5uGjAg: "1739885840-headless-cms-negroni.jpeg",
	Cnb12WWATFSwldHEYrlE: "1739885535-icon-time.svg",
	GVFiNHdJTGGz0JhCV5RQ: "1739885028-insightful.svg",
	sAYcu6fIQ4qeIeLrJFaQ: "1739885630-cypress-testing_2.jpg",
	rYPG5wyARnOvwnB3qj2r: "1739885437-javascript.png",
	hf5C1LPQsuW6MPS8QwSv: "1739885353-basti.jpg",
	rY31SNl8Q9iWB6coe31o: "1739885822-peerigon_hintergrundillu_cleaned.svg",
	BWxXW5HdSjSI0auG7SAA: "1739885172-kontakt-lisa-michael-paul.jpg",
	YelSyd1vQeECWINQyfEb: "1739885422-peerigon_office.jpg",
	joKgPKz0TbajyeUayr6b: "1739885062-docker-whale.png",
	BHOITcjYQyOQzOF62yKf: "1739885366-simon.jpg",
	s6xawHywQSy6r18DbtrJ: "1739885929-vue-js-gitlab.png",
	sXNgofuRLGrRHZs1Kb8q: "1739885967-linh-ha-kn8w0q8h3gi-unsplash.jpg",
	"9me7l9sQpukGUTYrd8aS": "1739885599-compatibility.svg",
	mtgDegF9Rj6udkZOSagJ: "1739885351-peerigon_2020_ma-283.jpg",
	"0EvkQfA1ROeHSnAaTUEj": "1739885798-icon-travels.svg",
	Hz9wH4TOQduzPC8eP4E9: "1739885103-sevencooks-01.jpg",
	TFkxvP1REq1kCq8NwU6p:
		"1739885504-streamline-icon-test-tube-rack-2-280x280.svg",
	jIKX1He3QI2eQoHmA97w: "1739885854-image-gallery-broad-worldcafe.png",
	"2UFo9EFHTiKSy8nzylBX": "1739885857-image-gallery-broad-worldcafe.png",
	"40Map0wcQgKXtE8SttsL": "1739885590-hsa.svg",
	ScA97sfqSmSOvQCmaEW6:
		"1739885282-screencast-sevencooks-website-recipes.gif",
	IyJGF0ptQYOqXI9WYaYa: "1739885993-blueberry-muffins-on-rack.png",
	vHdG2vuSISXpSAIhGldA: "1739885579-kuka.svg",
	xeC9OR7ATuqVFSZ02Axl: "1739885706-cyclist-peerigon-team.svg",
	"26yJbGVJTFanDU2IfxBb": "1739885375-workshop1.jpg",
	uumgB4R3Rv6ZdWNFznvh: "1739885260-red-and-green-button-protanopia.png",
	viG03uibToSB2tqdV5hb: "1739885384-founders.jpg",
	iaqIvY5HQCrnzAGCHQeq: "1739885154-tanner-lisa-matthias.png",
	gbghv5cUQKSqEXp5eCFt: "1739885262-red-and-green-ghost-button.png",
	otp327RHSUji0xEjO1pA: "1739885632-performance-optimization_3.jpg",
	"3wBHl50KSxaO7O5YWln3": "1739885162-digitale-nachhaltigkeit.jpg",
	vfn3eghSCeXAyr0dRtX2: "1739885921-webpack-icon.svg",
	dnmQou1KT2PKoQURdiHp: "1739885013-stock-image.png",
	w3wp5I7DTO6731MvgQL9: "1739885412-mp.jpg",
	UxoWxF2nSZikuzCIXiWM: "1739885367-irena.jpg",
	YOzfIbqvSVWYM60o14zn: "1739885917-blog-post-form-1.png",
	XTQtu1g0TtuHCYl7r0dn: "1739885953-philipp.png",
	S0qPLhDSfykZP4OICV64: "1739885985-ricarda-blog.png",
	PgAHYb8dSw2APPhmfbKO:
		"1739885788-209100579-6e9403db-116b-49b1-94e1-b09f91b67fc7.png",
	XxWQROwTTeQWu9YueAwj:
		"1739885033-streamline-icon-maze-strategy-140x140-1.png",
	ba87eSpjTGmNFb9rNgji: "1739885684-csstricks.png",
	SOWDjIAToKdqfE8p5AQr: "1739885177-peerigon-founders-treppe.jpg",
	uFNhh0EZQT6jAQqtMCLe: "1739885668-image-gallery-broad-homeoffice.png",
	zCFadUjZTMiaJ62TWzEd: "1739885043-graphcms.jpg",
	ZvQzMzEoT4S1X4crhF2N: "1739885640-sustainability-report-2.png",
	HJBgdfmT5OPk6zyucX3e: "1739885765-1280px-ben_and_jerry_logo_sw.png",
	FA21jq9GR5yWVxHPUs0r: "1739885603-js-vs-ts.png",
	rR3WYwGFR3ua3NO9FqyJ:
		"1739885188-softwareentwicklung-vorlesung-michael-jaser.jpg",
	J8mtY7iLQtKgNmHKcfO3: "1739885710-part3l.png",
	ZWYsA41fRHyx0ZrffcDj: "1739885206-peerigon_logo_rgb.svg",
	NvkOrZeQQgKTGbo292uB:
		"1739885792-clockodo-time-tracking-development-peerigon-2.png",
	iGtKE97RVOSdrEKiiUhC: "1739885061-mobility-app-header.jpg",
	Yvyt2h1bQgih1uHQamEG: "1739885196-icon-monitor-sync.svg",
	xvY0khAyQdqPsQiUHhnR: "1739885613-starfinanz.svg",
	Lg8E1cjQQQiPDBxsL4sf: "1739885764-coursera-logo-full-sw.png",
	cQEOa4xkQFWA6eL41By1: "1739885674-smashing.png",
	zUwZSfmKQZyBRV8kpiXa: "1739885852-ux-ui-design.svg",
	whyIqjw8TSyb1PcBqQO4: "1739885409-03ethicalguidelines.jpg",
	JwVH8Gi0RxqpW8P7VrK6: "1739885669-image-gallery-narrow-window.png",
	"3DjAZxLQSOWu5RtWlyYL": "1739885270-cartoon-request-sync-javascript.jpeg",
	uXa8JrbgQbW7SmJTGKdJ: "1739885308-cartoon-javascript-keyword-this-8.png",
	U7jcwYR4R1anrFE37zDa: "1739885185-web-projektunterstuetzung.jpg",
	h4IBoi0cRoGrbkUDAM9H: "1739885877-francesca.png",
	HWEr8zTNQIyVrslzvmr4: "1739885125-voting-app-header.jpg",
	V3Lp6JndTkKNki0dGlrw: "1739885274-screencast-sevencooks-website-login.gif",
	rHvbpLcqQiaO9JtftOsA: "1739885020-br-mediathek-case-study.jpg",
	widjdta4R6uWEsUU1yii: "1739885354-jannik.jpg",
	yUzIqgaTHmbL81pLnlPy: "1739885030-produktidee.svg",
	"83tJgqP0R1i2JEJnQlQC": "1739885279-ray-tracer.jpeg",
	n8GMhAFiSMqLzasxsDRI: "1739885837-icon-performance.svg",
	NiRUalMSf2hiIQbjl8yv: "1739885268-scriptlint-logo.png",
	TdQ1cljtRGWTGxp1HlY6: "1739885737-b-corp-peerigon-team1.png",
	hYte00ktTFytGZSRtAXN: "1739885626-accessibility.jpg",
	c0wyiRkcSWuuzG5CblKi: "1739885941-yaskawa-2.jpg",
	YCq1KD74Qmy3sdNlfjve: "1739885254-comparison-of-different-buttons.png",
	hcLZWP1ORJWPbddVzcfN: "1739885400-training.jpg",
	ax3YLfESL6vKXkaafET6: "1739886006-sono-motors-200.svg",
	bg1lUiTpKMTNtGpeWVzA: "1739885095-maze.svg",
	EKEta54ZRxuxG9aXLwZ6: "1739885621-teamphoto-nyvonne.jpg",
	jh9bbokaR9S6wqQOyC19: "1739885894-javascript.svg",
	QGIN2gDT6eGqthWYS9wH: "1739885253-music-production-suite-interface.jpeg",
	xNBCCY2LTfGTvB3WqAi8: "1739885325-workshop_infoblock.jpg",
	f3mEgmm4QAaZotsYzsxt: "1739885658-img_8070_tiny.png",
	boid5vtOT9KRuRRHNOed: "1739885889-webpack.svg",
	dYuYYm0QPHuzmsslaog9: "1739885645-group.svg",
	INevVYkXTx2A2jnOzrUi: "1739885089-johannes.png",
	X9ethVo5ROWfof0shqdq: "1739885966-sevencooks-02.jpg",
	qrcTJop5SCOyhUH4hl0f: "1739885288-cartoon-joy-of-javascript-4.png",
	VCGRStgRSqGNaKxXpDzv: "1739885291-cartoon-joy-of-javascript-5.png",
	"8HH0fibfRp6b4uaeKvQm": "1739885636-peerigon-climatepartner-en.svg",
	W3lX5iJgTeCIdhTPH8Yg: "1739885281-cellular-automata.gif",
	WytkovE8RKS6hpXOOWvP: "1739885554-suad-kamardeen-mykazlzw6nw-unsplash.jpg",
	lRAJ7JppTCeQip7KL3cA: "1739885883-javascript-use-cases.png",
	T7HIysX9QGif7Lk5QXZ4: "1739885337-workshops.jpg",
	XLu5JfShTBeuvDdUywwt: "1739885994-legacy-code.webp",
	FZvvAj9zRYyAn3pLVxCL: "1739884967-team.png",
	xWqikqN5Qju9dYIR17nR: "1739885314-workshop3.jpg",
	RojbPVnnQUqRpMNg7Pc4: "1739885331-workshop1.jpg",
	TJqqXRBmQYSTnCdtoIBq: "1739885467-circular-logo-webpack.png",
	syeqslvHT1i8o5jAbKoL: "1739885449-peerigon-founders-treppe.jpg",
	WYJHoCkSHCMtovTS2pIw: "1739885729-image-gallery-narrow-conf.png",
	"5yZHuJqStKZbcmTYr2Eg": "1739885930-header-vuejs-2.png",
	"3zaOnv6zTZSZ00t84t0S": "1739885135-mobile-tablet-pc.svg",
	OX6BDi5TzGvUqUEKbweA:
		"1739885739-coffee-circle-logo-website-background.jpg",
	"4Ohy82M2Tqmcu9NxQRAu": "1739884965-javascript.png",
	dyEZYcPeRS2iICKcFcQp: "1739885390-peerigon_2020_ma-178.jpg",
	fIpP6N0jTai5Pzfd3dzC: "1739885748-nuernberg-digital-festival.png",
	NwUzFnUQc0sLym1EJtaA: "1739885850-sally.png",
	I9pAQR5sSoKzeEtsajhJ: "1739885316-workshop14.jpg",
	ebbqLoPT5Sn3KvVuRaHZ: "1739885058-questionanswerbubbles.svg",
	"6PMRLWrQe2rXIu40YVyg": "1739885700-facebook.svg",
	WMC9lWUS3ebsY7nqoLJe: "1739884991-orderfox.svg",
	"6yiOs4woR32GyHqIeg5t": "1739885034-flexibility-value.svg",
	R4VuIsRAToy3RnkyYFvA: "1739885151-lisa-batti-meaku.png",
	tUcLxfddSduwlEKsNpfY: "1739885525-icon-slides.svg",
	bC5ePQOTTSoNKJPus8VQ: "1739885408-peerigon_2020_ma-090.jpg",
	Rq7dYRBiTPiH5XgJIEtU: "1739885039-browser-eye.svg",
	hjsYNZXySgiExdcFMuoQ: "1739885888-bnw_logo_weiss_2.svg",
	ZXljhbGSQaQ423u1xdsg: "1739885053-03ethicalguidelines.jpg",
	VwMHgAvySUeThJtXqUTw: "1739885502-codeboost-header.jpg",
	a3oubQSbQrSr6FeqRoli: "1739885266-tweet-tim-myers.png",
	boxZ2SKT9KqgTxRm966m: "1739885831-collage_stephan-judith.png",
	JgsUj4TP2zBtxVx9adxg: "1739885225-redi-school-logo.png",
	"008J0o1CSzeMzGD6p8p0": "1739885497-css-angles.png",
	kcrG29bETnuNwDVwXIyD: "1739885565-sustainability_travelling-min.png",
	xgAOHNUtRIuYxttKQrr3: "1739885057-dsc02242.jpg",
	u09a64vTeCVeIZYN9dlQ: "1739885692-quote-image-small-1.png",
	U5BMrWcZQ12gOYU6sI6P: "1739885725-triangle-enl.png",
	GfB6cSNlSR2hhYCHZSUf: "1739885141-439587-jpg.png",
	NHAacBVmSpSUfyyaAjr3: "1739885902-portalstack.jpg",
	yYgHkHSQo5zrmSXpXheQ: "1739885804-iwd2023-header.png",
	ud6lq6crRo7LGag6bmg5: "1739885540-icon-party.svg",
	FlGoaJGoQzSK0Z9bLK3M: "1739885193-icon-costs.svg",
	FFiK0jSSysG73CxUiMRw:
		"1739885133-streamline-icon-hitchhiking-1-140x140-1.svg",
	NYxo1YTkRmaMO8vr83d7: "1739885222-icon-search.svg",
	vFir4kEiT4CGJCnR41bF: "1739885810-unit-testing-licenses.jpg",
	YMuKY8DLQbgaHpOyqR3D:
		"1739885514-streamline-icon-coding-workstation-1-280x280.svg",
	HLEsbqIhQ3GrepXvTE1Q: "1739885909-react-icon.svg",
	Z2fNW27pSQ6OqQUZAlKA: "1739885397-peerigon_2020_ma-164.jpg",
	bgM2ZMmMRBKABAfWGinQ: "1739885219-hsa.png",
	Sjk723emQ9i7YM1htU7a: "1739884964-jannik-jpg.png",
	cMI4IRYUQvWzMZJAFKaG: "1739885382-consulting.jpg",
	YVqJwELqScaSOAQEd618:
		"1739885777-quesap-web-app-einsatzplaner-zeitmanagement4.png",
	Q9jjXk3QGy0dG9bEgYyF: "1739885752-rundj_logo_twitter.jpg",
	"4yoWjFR0Ro6CXVQOVL3U": "1739885363-martin.jpg",
	JrV265dvTLqjHjLx7xm2: "1739885277-logo-baobab-family.jpeg",
	"66htZwu0QvuVA5dhj0wO":
		"1739885505-streamline-icon-maid-robot-2-280x280.svg",
	EItB6pOSFqWiltsyiOKs: "1739884974-angular.png",
	FnMgAwz3QK2S8hJonibe: "1739884942-robot.png",
	zvdbmTHRcOuKZCI4WJpd: "1739885296-cartoon-javascript-keyword-this-3.png",
	u4MzWZv1QE2oiBjWHlPY: "1739884949-mo-jpg.png",
	SdZjwyJqShWtwct4utYC: "1739885575-fh-salzburg.svg",
	jGrvYiC9T1hSNiapvWYQ: "1739884973-vue.png",
	kJH0VySdQyCUGC7B2oZC: "1739885290-cartoon-joy-of-javascript-6.png",
	hCPJU2tSMm1zRZKYLb8Q: "1739885320-workshop6.jpg",
	w9w0rHYgQ5w7hmy5l2zg: "1739885063-learning-rust-header.jpg",
	"401ySDTURkWllmpYYByZ": "1739885587-xitaso.svg",
	qMzvR3nuS08CFOfXF1tN: "1739885971-pdf-icon.svg",
	dMJBwbETba1JU7fLFMda: "1739884972-story.png",
	kz6Jbue4ShyFWHRhjUv1: "1739885441-vue.png",
	RO1oCp7FSAq78v2KnUvY: "1739885868-ux-designer.svg",
	fZVqbpETQDWop0grQsGa: "1739885009-bestpractice.svg",
	"8d7RM7RRrqodeFTPk8Q9": "1739885183-peerigon-foundersteam.jpg",
	"4CqptVXmTQuSTz5WhUzB": "1739885099-communication.svg",
	X6beVXj0Tzi0u3mmmJ9O: "1739885662-top_company_2022.png",
	fUfZTenfQr2Lg7KJqcLv: "1739885224-kontakt-michael-paul.jpg",
	"0pFUKqsTiCMIKA5d9puy": "1739885717-phases-enl.png",
	UcYb3LKmT7CMOLM5mxrp:
		"1739885401-22051090_1467924313283386_2642269300717035354_o.jpg",
	lSLGoZyGQkGzTKmxDlKA: "1739885248-ok-and-cancel-button-windows-xp.png",
	lvUo84ywRvq2OtfPzpiN: "1739885783-klara-blog.png",
	UrL5ChRaSijLTC4IngHl: "1739885757-mia_ben_logo_sw.png",
	fQ25UREHQJShYXr21DHA: "1739885861-ui-design.svg",
	vC2Jvg7YQxWasvC3T6TB: "1739885503-infoblock_bg.jpg",
	"3k2rW7rXQjalfY9XsLFd": "1739885656-training-share.png",
	iaDBHHD3SzCgf0EVl4mp: "1739885419-peerigon_2020_ma-152.jpg",
	Sr0OOgn8Q6mqg8ooaOQO:
		"1739885638-140051893-a6d4dc5b-5022-40b4-9d52-016d91d8e4bf.jpg",
	H5C5ImrSPC3SYg9JnTlQ: "1739885774-microsoftteams-image.png",
	LouigEr1RHy5F8SoY6So: "1739885550-positional.png",
	Y5dL8HuS0aSKuvw4szo3: "1739885925-react-frontend-frameworks.png",
	"8MqURlwhSsehDNdDD8BD": "1739884992-ebay-kleinanzeigen.svg",
	yaPn4yCRTrSoNjvGh65n: "1739885675-syntax.png",
	nOJSL2f4RRWYHCDrqKmW: "1739885667-image-gallery-narrow-programming.png",
	IY9Wo4bSeNhzIVFyQj5A: "1739885974-philipp.png",
	"7j830mUSyy1VOpIwkLVI": "1739885361-mo.jpg",
	FGWiaZegTGijk6Y3tTHa: "1739885833-sketch-dynamic-headless-website.jpg",
	"4r2ljjzpSC6F41jmDEvN": "1739885999-br-200.svg",
	CuG1AwRgybLnG2zcCcQ2: "1739885946-ad-target-phone-streamline-ux.svg",
	"7hIo4q1T1KY71ZJN37uF": "1739885427-peerigon_2020_ma-070.jpg",
	clpvyCuETuWBw0LuKKMf:
		"1739885121-javascript-keyword-this-illustration-by-judith-urban.jpg",
	Xd6d1Z8NSQaRte4xIgZB: "1739885886-bnw_logo_weiss.svg",
	"8ztwjH9xQSCVGES7NC6G": "1739884993-whisply.svg",
	qajLyOQiRO21XSUYq3iI:
		"1739885075-web-components-header-photo-by-kelly-sikkema-on-unsplash.jpg",
	DrCkM9p3Ss6hTaeCiTdp: "1739885301-cartoon-javascript-keyword-this-4.png",
	"6waAPuDvRmKIuNoZeF03": "1739885010-expert-consulting.svg",
	TY09j9EoRCiH1aMvbC7B: "1739885782-klara.png",
	QS80pQ1WR2i1ANmYMd6k: "1739885767-bcorp-team-peerigon.png",
	Z7rWmKVET2KRE1KeOm8F: "1739885161-digitale-produktentwicklung-peerigon.jpg",
	E9CPnwkyTaefSpRGEOTn: "1739885108-kununu-case-study.jpg",
	xJ37PT6uSqGQ7SC7VSxH: "1739885793-icon-target.svg",
	j420exSQTNO4BQJ4JUsP: "1739885663-lea_s-2.png",
	d6YKcbrwQNeAubzKh5IY: "1739885285-sketches-jetzteinde.jpeg",
	dnAG9unaSBSxaObp7D8H: "1739885676-rss.png",
	ur6eLBFVQouKZsy1s1AR: "1739885201-icon-language.svg",
	"8B9L85QKShCLyMwGxsoO": "1739885807-peerigon_logo_strich_neg_rgb.svg",
	C8WEnptTRKQZyQCfd8h2:
		"1739885652-streamline-icon-worm-evolution-400x400.svg",
	VtVLXvRV6zqqm0jxWRXg: "1739885970-vorlage-icon.svg",
	"8GHmEFiR0alN9SFHImO7": "1739885693-page-header-photo-1.png",
	bMuY7F5pSS6RYoA3DNdz: "1739885685-dailydev.png",
	USnRyk9SxClnOJwROUUb: "1739885878-heading.png",
	zBugZ2ruRR2437k9jNZ7: "1739885234-antony.jpg",
	bnBghciS9C34R5KKtmbE:
		"1739885068-peerigon-donation-projects-summer-2019-illustration-by-judith-urban.jpg",
	j6UDOdWHTjyFlRe0qIbX: "1739885915-castle.png",
	hOKBq1RRCDna1DfRtXhA: "1739885393-peerigon_2020_ma-164.jpg",
	"23l9itoS5JqQ4JHmao2A": "1739885918-umit-yildirim-ycmnk67bz68-unsplash.jpg",
	LwAuXWN8SyOpjgfGc85j: "1739885493-icon-beratung-digitalisierung.svg",
	"5Zmvy1zlRxmoNjLGDlgr": "1739885480-pixels.jpg",
	uxYBU5h2Qg2wmbSfUSF3: "1739885580-personalkollen.svg",
	cEKsJXpjTVmZ8wlDjfJ8: "1739884963-logotype.svg",
	iFED38zYQqdvc6E5XC3i: "1739885913-icons8-nodejs.svg",
	"2X5keW4yTrCSEiH3FQhK": "1739885585-shopware.svg",
	"2Wku4v4TQwCkqIc1czBf": "1739885355-antony.jpg",
	gTWnV2V0TQmYF9CB6QWG: "1739885181-peerigon-passion-group-meeting.jpg",
	iUtIH9IuRXi5iv423dYM: "1739885695-contact-judith.png",
	"8ij7WtkARFiyjd3iS388": "1739885475-96x96px_lg_4k.jpg",
	IHHkSGFoSZmrHwHEMzWV: "1739884996-sevencooks.svg",
	LJEjwfiT4q65BuSLj4Tg:
		"1739885252-reverse-button-in-mojave-s-texteditapp.png",
	yr45M6KmTym0njTd3gLD: "1739884947-button_header.png",
	J0lsJVrpRHC3RAfIxFUL: "1739885956-header-react-native.png",
	X46xEJSHQKmyeaXatZnA: "1739885100-sz-01.jpg",
	"7JIcES4GTfSN3srEfp1r": "1739885759-contact-judith.png",
	sdryAGHTIaFeIpk1FhdA: "1739885391-peerigon_2020_ma-018.jpg",
	OQjtg2cORZ6HXSy4MHhQ: "1739885263-red-and-green-ghost-button-and-icon.png",
	"5bggMvxRH2Fs2hTQ9Nuz": "1739885605-typecript_error_m.mp4",
	rew0MUJQTVG9HlnbP4jc: "1739885947-work-calendar-streamline-ux.svg",
	x8vBMEq6TqmITY4b6pxn: "1739885546-vote.jpg",
	iRQ0Sex7RaWc56aGOV3s: "1739885110-orderfox.jpg",
	jZhEDoGaRoyeapLTJ5gs: "1739885342-open-source-definition.jpg",
	yLTB5OBNSGCc26cAGz6s: "1739885924-header-react.png",
	R1tLppvnR0aZBFB4ShOW: "1739885881-header-javascript-landing-page.jpg",
	yKZNjk1fQgCCYYVejnMw: "1739885388-workshop2.jpg",
	OAfgmVHTd6rI2rgsITJ7:
		"1739885506-streamline-icon-team-hierarchy-3-1-280x280.svg",
	OYtJJf6BTdmlz1yCOkFX: "1739885581-poster_xxl.svg",
	"5Mm7ekcRJW9XUlHPmFf5": "1739885761-berlin-organics-logo-rz-600_klein.jpg",
	H015nLZbRmSjry9UhmRA: "1739885893-nodejs.svg",
	LqWLd2MwSHGmjld5wDM9:
		"1739885138-screenshot-2020-10-09-at-18-57-50-jpg.png",
	dsx9DOOERu2VBTO5DD4j: "1739885975-header-top-ten-mistakes-to-avoid.png",
	"51CG5oLtQVGRNpxLJuCx":
		"1739885078-new-to-it-photo-by-jjeshoots-com-on-unsplash.jpg",
	aLuEPaYXTtGvGNnyNdAf: "1739885369-judith.jpg",
	mw2IailtR398w94CyzIL: "1739885601-loved-dreaded-wiederhergestellt.jpg",
	Ro38CfR6Wju4G6PlEvMA: "1739885879-peerigon-logo-socials.png",
	SiZp75y1SW6rvPmhrUZK: "1739885903-success-story-netflix.png",
	uDggTmmMSRiiz8hlSSho: "1739885939-header-photo-industry.png",
	KKSifwTMRG2IbqZopcZt: "1739885955-grafik-react-native-2.png",
	TfAmquqRYqmKZlIhDubk: "1739884997-br.svg",
	RUw5QauGRQkZTxNRPD2A: "1739885733-peeri-bot-airing.jpeg",
	uNeIj8hDQZuURpERFjbK: "1739885231-nodeschool-at-peerigon-office.jpg",
	GF5Fsf4Qq6RIN0xGgE3Q: "1739885679-http203.png",
	"23S6OBjTJGCAVKhpjXbg": "1739884998-hsa.svg",
	aAG9I6Q6WpB8hGEiek4Q: "1739885942-security.svg",
	lpSLOCDUQvynWtaNkHZV: "1739885936-growth-rate-ai.svg",
	yL1kPsp5QzCZdNUzz6Kg: "1739885749-patagonia_unternehmen_logo.png",
	a909nmUISXieJ4eMmvZt: "1739884980-consulting.svg",
	"7tWEn3x5RWCZTVgaOSXd": "1739885233-np_logo_1z_1920x198_white.png",
	bvazzywVRw6Bc50t4QhQ: "1739885790-new-work-excellence-peerigon.svg",
	IVHuo41RAGh59ek2xRzg: "1739885844-03_typescript.png",
	fMH6UGdnRGeeLsIPUxn3: "1739885175-kontakt-michael-paul-lisa.jpg",
	dxdyuYPQJWTU3clxyKWz: "1739885119-michael-stock.jpg",
	oOqlYpUBRgClE6iwv3qD:
		"1739885066-working-from-home-peerigon-adrian-ludwig.jpg",
	Cq0Mj6v7RxiMASf54RaI:
		"1739885080-async-adventures-header-photo-by-everton-vila-on-unsplash.jpg",
	fkMRSEXjRReBBok4Dfv7: "1739885745-ecosia_logo_bw.png",
	F2NIEdphRKaATKlOS3YX: "1739885398-img_7999_2.jpg",
	Bf61SumZT5WQi3yzFdpY: "1739885463-circular-logo-react.png",
	N5uHnrsSPso5bvZk3uww: "1739885178-open-source-definition.jpg",
	EuVY5vJQQ1yfV5Z5chE7:
		"1739885803-gruppenbild-teamevent-peerigon-winter2022.png",
	GKvze2KbTsG47TXJKMzW: "1739885092-michael.png",
	ZD5crzEWTQqFYuYEVClx: "1739885496-css-units-header-pt4.jpg",
	UF1RTnjQRMGvod0y8i1B: "1739885447-link-zu-success-stories.jpg",
	pXz0Z75ISgutZhSPO0Ov: "1739885016-think-tank.png",
	geMWUjtIRT2rzyEWpKPo: "1739885969-schulungskonzept_typescript_2024.pdf",
	deOIhBarR4aubMpeY2HF:
		"1739885705-streamlinehq-hanging-plant-pot-nature-400.svg",
	mVW2l55kQwGQORLu1XH1: "1739885373-irena-nodeschool-workshop.jpg",
	i0gt1maVRIOPswhlUEzW:
		"1739885508-streamline-icon-cleaning-robot-1-280x280.svg",
	zhGLgpbT4anbs2L66vg6: "1739885476-96x96px_appleretina.jpg",
	JiZ0MnETS6kc9T55io7g: "1739885087-unit-test-integration-test-header.jpg",
	SRIupKyhSWm0RYajEa1G: "1739885724-workshop-situation.png",
	hxkJ2jbtQjCLwp65RLJY: "1739885374-coding.jpg",
	"80rETibaQk54G8ygoecs": "1739885378-coding2.jpg",
	lmlWq4JaRoeF1x4YDurB: "1739885027-teaching-html5-js-and-more.svg",
	"18UBuXFXSlKCERY9sFPs": "1739885500-ad.jpg",
	Uj3uwrsTQw2dnmDnMaSa:
		"1739885071-asynchronous-callbacks-in-javascript-header-illustration-by-judith-urban.jpg",
	mdXefsgPQECgnFbx8HGV:
		"1739885081-function-scope-context-header-photo-by-pankaj-patel-on-unsplash.jpg",
	g4DXWekWS0iqYMb5RW8W: "1739885021-yaskawa-4.jpg",
	r2JlM0OQSfSMuCliyB2U: "1739885330-workshop10-narrow.jpg",
	ZpL5bkMSOmqW8jWAI32A:
		"1739885520-streamline-icon-module-network-280x280.svg",
	BcRTp2oQACtLXkvsHkUO: "1739885617-octopusenegry.jpg",
	sJTLy54DQ7eHq5akaNnQ:
		"1739885167-group-session-agentur-fuer-softwareentwicklung.jpg",
	P8xbab5TiuD8zXuImzGv:
		"1739885697-streamline-icon-ux-ui-example-400x400-1-ohne.svg",
	jKZldyNzSbaokNUtF71G: "1739885142-2018-peerigon-report-en.pdf",
	lHHa8bRlTEeRBArdfgpR: "1739884954-leo-jpg.png",
	jAtCzHKbRpaJYl1m9HJ8: "1739885895-react.svg",
	HmfBge4cSqGgkK3YZJUW: "1739885838-icon-flexibility.svg",
	cNk10M9iRCywWdS4Kctf: "1739885153-lisa-batti-meaku.png",
	SpIkJdMStC2waLu1UsoP: "1739885143-batti_irena.png",
	kObqMpJESOy8pja0NJig: "1739885008-contact-form-image.png",
	pGLJIqyT6WLA1i8DPqjr: "1739885606-typecript_error_xs.gif",
	I0ABexEQjWdzNbApbpQr: "1739885483-px-em-rem-02.png",
	HpYXM5u6RoykX5yu1lWk: "1739885297-cartoon-javascript-keyword-this-5.png",
	evUKg0HUQZOShxYGjKlh: "1739885032-modernize.svg",
	"5OpDWlibRgSRCNdhEAQK": "1739885721-projektm-enl.png",
	p0N8ao2fQD2bHdxRATWe: "1739885359-tanner.jpg",
	ukdwGZ63TIefhKv5X4tK: "1739885849-julia-gerstberger.png",
	F292HpLtTigeWgjICeWF: "1739885815-01-check-md.png",
	C95POc2Qi8JS3ytDWPmw: "1739885988-a11y_bowl.png",
	KiWpu9ARAyid2UMN6Umi: "1739885490-css-units-header-pt3.jpg",
	U9YFcKmFQMuFA21SbiZY: "1739885235-stephan.jpg",
	mnE52dQd2ByxvaoT0MaQ: "1739884989-pro-sieben.png",
	wAVJpMQiSbejRGmjBDto: "1739885984-francesca-blog.png",
	broUQ3KFRZCHeko99gaw: "1739885809-peerigon_logo_strich_rgb_l.png",
	dJ9HguHkQLOiYWCvE03t: "1739885573-contact-sustainability-report.png",
	CfKoyHnBQsCA281eNdNl: "1739885866-design-tool-pen-2.png",
	klRWLy5Qo2TLOTBBkk49: "1739885691-quote_image.png",
	p3MwBuURHK87oM4QDRqb: "1739885612-octopus-energy.svg",
	QiOnZumTJi7o7bt2e3qs: "1739885720-projekts-enl.png",
	rwdogfkTRRORHaW54FJu: "1739885481-icon-programming.svg",
	qTf8rzUQLuIUtI22vK8V: "1739885616-innovationshub.svg",
	syPphKnfT5ubMxhwgHRB: "1739885510-logo-onwhite.svg",
	qBBJN4bQ2Sgc6KJOq6jQ:
		"1739885070-diversity-header-illustration-by-judith-urban.jpg",
	WbAvTmuES9iMo6uQqqwD: "1739885610-all3dp.svg",
	wgM6tOSRpy26blhyrkqG: "1739885430-hoodies2.jpg",
	"4gqe3ZiBTVKuA6YTdFoc": "1739885865-img_0339.jpg",
	tWq6RGUSV6QyJn0fwI9Q: "1739885112-pro-sieben-glomex-case-study.jpg",
	"8IcStlhPRIaoj8qP46AD": "1739884961-1-vb_9gjllafsnaavda5kvjw.jpeg",
	xwrxzV8ZRgqkaIZ8ToYP: "1739885561-page-header-photo-sustainability-min.png",
	iOdMQu5RWdBFJ1cdo5Cg: "1739885341-sponsoring.jpg",
	RKVXYBqlSNKCxd6Nr7Jw: "1739885853-ui-design-by-peerigon.png",
	olQ6bwcTg2Vt2wVcBuYv: "1739886004-kununu-200.svg",
	sxnVPNdRf2AgVV6zpKQw: "1739885364-henning.jpg",
	ApWTG4GfRFWuciACKi5I: "1739885914-flatmap-brat-cat.png",
	j6dqZ3rxSiyupI4VRxS9: "1739885336-digitale-produktentwicklung.jpg",
	"8oyQ5T4OQRuk24u1t55l": "1739885396-peerigon_2020_ma-018.jpg",
	A7bOze3kROCA1tj9ym6Z: "1739885780-quesap_preview-calendar2.jpg",
	kG3F0odUSie3ac2XDtru: "1739885927-header-vuejs.png",
	"3sPHWk55R0e1QZuakaGp": "1739885163-it-consulting.jpg",
	"2NdLOnh7TUiEfs8Wfisd": "1739885926-react-website-examples.png",
	qPxNYuteTNKHPLrfAzND: "1739884984-lisa.jpg",
	OurenibTQiI981yMMEut: "1739885602-typescript-compiler.png",
	il2oNmRTXWuTOXLa5LDp: "1739885376-workshop5.jpg",
	Ki08WtLQTrGv5YcmxXlB: "1739886002-jetzt-200.svg",
	qeWH2HtvQ6qyyCiuJJYr:
		"1739885512-streamline-icon-seo-browser-1-280x280.svg",
	tUYdmRtWRZyY0jm8ErzV: "1739885379-workshop7.jpg",
	"2gQnk2gISkiiiETNfNSb":
		"1739885769-home-image-text-softare-development.png",
	dCzZys8SQD2mqRMqbacI: "1739885987-a11y_cover.png",
	ifVbyk2PQE2MznX5gkQn: "1739884959-0-0yirmvv0lsganity.png",
	BMWdTnwTauBXeMdjoOsg: "1739885823-2023-company-report-peerigon.png",
	"3EbdezsQ2eD6q4PwKay7": "1739885728-features-enl.png",
	WdqoifN9SOCOHLEaXryQ:
		"1739885345-stephan-corporate-social-responsibility.jpg",
	xJxuLzLkS5aOftXFL8Cp: "1739885438-react.png",
	i8oZhmqRQQWSiTPD5Dax: "1739885048-charta-der-vielfalt.png",
	Oh03IlwrQAiqB3uyEcRH:
		"1739885456-43460755-dd1b267c-94d1-11e8-8cef-4a059047804a.jpg",
	PWPVmJuORau6Op9wBWey: "1739885128-test-illu.svg",
	wTSUseSCSCUnNVExDHDg: "1739885198-icon-lego.svg",
	"6WJsr8bOQC2JoFevnxJw": "1739885547-rankedchoicetop.png",
	PFJ0IxjRLaEH6UzgCbmw: "1739885897-earth-talk-2-streamline-ux.svg",
	FiJ8PZ8TKqCw0uMPRd9G: "1739885432-peerigon_hintergrundillu-01.svg",
	UhuFTHeRTDOf0VNDAQ6Q: "1739885313-voting-app-home-page.jpeg",
	BdwUTAP8THejnBxNKrOE: "1739885284-mobile-phone-screen-with-jetzteinde.png",
	ZNF7ZcxITU6gUAx3wCIf:
		"1739885494-icon-digitalisierte-geschaeftsprozesse.svg",
	oySCllkVSOua6h4tgO6u: "1739885796-icon-info.svg",
	FssjqQZUQvSdGuoNv2OM: "1739885596-page-header-photo.png",
	J1BHS2tRSietrD7LIVcS: "1739885670-niklas.png",
	Petwr7GcT8W82rsIYNqD:
		"1739885901-international-network-7-streamline-ux-1.svg",
	QV4CnPKQSu25DIJBVkGA: "1739885362-leo.jpg",
	YS18se5S9SaWnEQW3ACK: "1739885318-kontakt-johannes.jpg",
	SX8ntArWTqyOZCEmEdTq: "1739885173-kontakt-johannes.jpg",
	srHkze46QxaZHFRGb0Rg: "1739885992-blueberry-muffins.jpg",
	WI2u86ihTQCdUg55BvuG: "1739885954-grafik-react-native-1.png",
	h5IHzU6LTm7v1G4GF21z: "1739885864-collage_kathi-judith.png",
	j5HjyH5gTu69YeXDaGKQ: "1739885991-blueberry-muffins.jpg",
	Z2h7iTLaSvSzBFbu3rE8: "1739885102-eventn-case-study.jpg",
	sa8RTODQSzeJ1DwMcZpd: "1739885950-layout-design.svg",
	"1b4MbnhEQFWWzuCEKdof": "1739885436-instagram-link-collection.png",
	r4CXRPPiR2STDIG37c9A: "1739885107-eventn-case-study.jpg",
	fvpzKMusQsC1GztspFD1: "1739885651-peerigon-logos-press-kit.zip",
	sowB4divTACLOcIOcsgB: "1739885545-ezgif-6-0cb742e61965_2.gif",
	"8H02j11LQzSfSim4FFto": "1739885869-mvp-workshop.jpg",
	"2aNJe7hStyRs0wogtaa2": "1739885132-datenschutz.svg",
	iKtctVTSyu24DSExyZpL: "1739885908-javascript-icon-svg.svg",
	Ofrp3Vf2SM2sLm62rkjb: "1739884983-webpack.png",
	XPDT4HqZRQbIQ5xtjTj3: "1739885257-i-m-a-button.gif",
	J5tFdMXSbKM4kJshWirx: "1739885306-cartoon-javascript-keyword-this-9.png",
	"8uh1XrOhSpe3ywhTgW2R": "1739885380-agentur-fuer-softwareentwicklung.jpg",
	PLq18KroRwuUq2WRg0z0: "1739885732-peerigon-mascot-lueften-min.png",
	SIXgSTeASbiq4GO5Ofef: "1739885817-kathi.png",
	y7GApi1PQWGH7PTE7u94: "1739885802-pxl_20221208_110234155.jpg",
	x7J1BSaMQ0eRflGeUFRN: "1739885847-funk-01.jpg",
	lAd0byILRiW9uFU87SxT: "1739885977-agile-model.png",
	"4K863VYQGNF1uzws2QTT": "1739885037-developers.svg",
	gc2ofteFR0W8FQHNCSDa: "1739885227-msf.jpg",
	whmi9dpRW6acxsBBFT64: "1739885223-icon-no-data.svg",
	T8xV2ZSUOL46ZPAItDzA: "1739885213-wwcode_logo_official.png",
	tArZXwkSTgGW1imprWIF: "1739885528-icon-practice.svg",
	c1RismnQfCLuregbi5vs: "1739885094-streamline.svg",
	RFIf4NBRwibaxd8omCm7: "1739885498-line-height.png",
	JGPR4LR5aawErEn6mj7Q: "1739885701-instagram-logo.svg",
	"0lsp0ADqRMqQl1cXi1eT": "1739885216-100fh-org-wide-logo.jpg",
	wF81kV7tT9W9zzNAWuhb: "1739885147-matthias.png",
	pF5RAuRMQKi9Eqffjijy: "1739885246-ok-and-cancel-button-mac-os-x-12-2.png",
	dpVgQj2ERHeTzYziyEnd: "1739885781-julia.png",
	aQQvk3S8TKiHdmNnNNxG: "1739885127-compact-illustration.svg",
	"45QGhnlYQXmGcoHQqZyz": "1739885022-all3dp-case-study.jpg",
	RJ9EdCgxQsC20zdqUWPb: "1739885136-location-sketch-en.jpg",
	r9326yTrQhGI9WXq578t: "1739884950-judith-jpg.png",
	jU81CXIaR7i67bQF3AfM: "1739885255-mirroring-dialog-in-fireworks.png",
	"1jwF7DkRcuGjW9zKuUZw": "1739885042-js-craft-camp.png",
	j3joLvNbSnOjONkdx6FG: "1739884960-simon-jpg.png",
	UEVlxsUNTpjHHAImInBA: "1739885479-96x96.png",
	UF1lrum0RiSvAMuwPZvL: "1739885148-tanner_working.png",
	EAkHmqoORnqYbzxRoFgH: "1739885093-kadir-celep-pbezsnnlca4-unsplash.jpg",
	DU2DR0YS7GQMkMMSNMAL: "1739885114-sz-2.jpg",
	GnasptpRRm25vPWo8Umg: "1739885979-grafik-feature-usage.png",
	"9hlbWsahTWfj8SyPz1W2": "1739885933-solver-robot-2.svg",
	rlsxapOKSaeYsoxNH291: "1739885205-peerigon_logo_rgb_no_padding.svg",
	"655hMZmkSQfdkchYz3LP": "1739885077-theresa-jpg.png",
	pC3XGsw2RoqdaD2HA1zV: "1739885519-streamline-icon-hammer-nail-280x280.svg",
	V07Tph31QHu8SLnOoJ6w: "1739885763-fairphone_logo_sw_rgb.png",
	b6orsQBFRJeLTx3td9zV: "1739885943-octopus-krakenfield.png",
	"7CqkhQaLRladpLhWETwz": "1739885055-nodeschool.jpg",
	SBImnOB9R86hSkZ7908Z: "1739886001-hochschule-augsburg-200.svg",
	GPaVNW5uRL6OC0pmEHWM: "1739885990-konsens_accessibility-statement.png",
	S2AOP9aNQz69GV5P75bt: "1739885615-polyscale.svg",
	zOAqIgGVRhqYqQlgCAdU:
		"1739885156-javascript-conference-speaker-johannes-ewald.jpg",
	cn5ULYy2TvGm45j8yOlr: "1739885310-integration-test-or-unit-test.png",
	"65bRIvSQSmChzsDdxYbc": "1739885722-corona-app.png",
	BLHFBiHS9iJ5Lylvngzl: "1739885952-laptop-idea-1.svg",
	IxFb44BdTqqVf1mVSs3N: "1739885578-jambit.svg",
	"2ypZElT2R9KyPUOCvQun": "1739885775-quesap-ausbildungsplanerin2.jpg",
	gBMPSnPOTzyYzcj6ikK1: "1739885414-peerigon_2020_ma-068.jpg",
	YKxgDuM0QleQNCpV7Yjp: "1739885190-kontakt-lisa-stephan-jpg.png",
	HYnNnVAERAaxlQXSwmu0:
		"1739885521-streamline-icon-graphic-designer-4-280x280.svg",
	rN0LlAL0SFWShZSzQJHW: "1739885435-testimonial_male.png",
	"7VycA5EhRcmR0WmqTgN2": "1739885860-ux-designer-hologram.svg",
	kVEHt4mRSz5wecspQa8Q: "1739885413-strategie2020.jpg",
	A4ytIPHHSEC8i5OGZw3E: "1739885649-v2018_en_c_15290-2012-1001.png",
	"4ycckWjuRWSnwzmzTa1M": "1739885964-bosch-legends-lab-2.jpg",
	MXrkUt0ESlG7hTgG38GX: "1739885358-celestine.jpg",
	YuQbvt9BTl2iLxdcM8Yt: "1739885230-diversity-in-augsburg-flyer.png",
	E4v90euFRL2U14jhq11l: "1739885874-stephan-blog.jpg",
	ni2NErNZS4qBOj7aSik2: "1739885904-istock-1349456090.png",
	tgcDKHpTQGOjKPDnaejq: "1739885871-climatepartner-label-2023-de.png",
	YiiiKyhMRq0hlnZZ0ojg: "1739885872-climatepartner-label-2023-en.png",
	Nq6O0UiLSQ6098pCL2mC: "1739885962-nextjs-icon-light-background.png",
	"9qsOI7LBQXqsq3pNzWWv": "1739885997-kuka-200.svg",
	"2slvLAvbRiOztbJnelrZ": "1739885681-twitter.png",
	HSEd5NEQESVjVlNkUoWw: "1739885090-lisa.png",
	i9diLcplSh6CMImfBy0V: "1739885779-quesap-resonsive-mobile-design.jpg",
	iEvLrJtbSxeVOjOBBLb3: "1739884955-celestine-jpg.png",
	NUImsp4vQgy9i2uUrxWq: "1739885821-microsoftteams-image-2.png",
	Nz57VYJTaqtibP6u6ofx: "1739885785-plu_header.png",
	jL15LIxKSCectAW9PSsw: "1739885938-solver-robot.svg",
	G6AShurLRE6lGXSXfoX3: "1739885105-data-fovea-case-study.jpg",
	rUHOk9JT36LR16U6ILqA: "1739885549-simpleplurality.png",
	LV9mf4EfR4OLRbTGt6QL: "1739885912-vue-icon.svg",
	xRr5xTtbSFW8bx4geUWj: "1739885372-mo.jpg",
	qoHrQGalSzeCzy7qvq3H: "1739885007-boxcryptor.svg",
	v6mqyvcCRd2E9CreJuJQ: "1739885468-circular-logo-vue.png",
	"3RtwlJQfud6schr3iYQQ": "1739885511-reversed_2x1.jpg",
	R1s2Yng2S4uvlUoBDl5Q: "1739885801-hannes.png",
	IrysQAPQTWyXfJVfVY3A: "1739885959-konsens-header.png",
	qCg7gYdsRnumy07TFdwH: "1739885744-b-corp-logo.svg",
	"1SqBrnnpSJyAKjDT8jLK": "1739885743-b-corp-logo.svg",
	"0WbDODDLSzar8GYpdQLq": "1739885680-josh.png",
	bZKDbHlNQtyxxzyN613u: "1739885461-fahrrad-ausflug.jpg",
	d6UTB4MqRgWpPLSak5Qc: "1739885098-hands.svg",
	MZLaw6kSzO0DGjlEvZyg: "1739885568-sustainability_inventary-min.png",
	XiAYmrm9ScGgt4tHusEX: "1739885715-hourlyrate-enl.png",
	VpKsCiRR9G6zk8DyM9x7: "1739884999-kununu.svg",
	iSgrRJNzTxaPpPybOlHg: "1739885202-icon-sketchbook.svg",
	"0S1Isg6RSCGrjr78k1LA": "1739885474-macintosh.png",
	yj55MkmQ7eQpCtUU319k: "1739886009-sueddeutsche-zeitung-200.svg",
	"8SouIN0hROGWu2Y5jZ9c": "1739885014-lego.png",
	"6eIfcbsRGqeivxmFXU5W": "1739885509-ad-2x.jpg",
	fXqF5C5XT7CABn3ZWNmc: "1739885808-peerigon_logo_strich_neg_rgb_l.png",
	"6eg2WdeNRfS5maA0LeRf": "1739884957-1-panf_oaf7zuwbhclegfnug.jpeg",
	HSh0jnZKQuW0VGftNCAc: "1739885911-typescript-icon.svg",
	MJEQDiyxQAylV82NriGi:
		"1739885073-the-joy-of-javascript-header-illustration-by-judith-urban.jpg",
	"6LUn8rebT5qnUAlSXEcq": "1739885477-iso-216.png",
	KoHTy2UTrG04kb9JGcdQ: "1739885433-labyrinth.png",
	RhlkUedQJqH8FQeeAcnV: "1739885309-cartoon-javascript-keyword-this-6.png",
	n3UoyUR2WhMEsYz521Qz: "1739885982-factsheet-react-workshop-de.pdf",
	n7dQ7IAVTH2iK4xykEow: "1739885228-peerigon-report-de.pdf",
	m73BGgzZT7iYVNXiaQq9: "1739885424-peerigon_office_md.jpg",
	"24sWSZ55QjyB56TGdkbL": "1739885302-cartoon-javascript-keyword-this-1.png",
	PdOaDee1RaKJrlHWnu1h: "1739885425-kontakt.png",
	HAJKIvOcSp2K9KuTrodZ: "1739885203-peerigon_logo_bobbel_rgb.svg",
	QofeIUhTMejQrCdWlUZl: "1739885848-polyscale-case-study.jpg",
	Yo9m7YEQZGjULq30SZ0W: "1739884948-lisa-jpg.png",
	"6uB9F50ToC9kNlglZA0w": "1739884982-team-training.png",
	DFvQjZSh6HNuVZBDKoTQ: "1739885553-omits.png",
	sfc5E0uVRNO1JeQIIxIU: "1739885251-ok-and-cancel-button-windows-vista.png",
	TVs1bOcITcWXCLQbTHfo: "1739885333-it-consulting.jpg",
	C0oyKdvTQPSaV9elnG0i: "1739885025-sz-matterhorn-case-study.jpg",
	DDTV3NQwRfKn8VLoozrs: "1739885322-workshop6-narrow.jpg",
	oZlB2RSGSCGxS19aZzGW: "1739885906-advantages-node-js.png",
	pesPzUvRKbLN5FhPVJAC: "1739885572-sustainability_energy-min.png",
	SBD5VYjdStCNDKOLwKDe: "1739885442-webpack.png",
	pjY22vaySJeylvriDBKv: "1739885859-wireframes.png",
	NFSRHmYBRrGaY9qa5FyH: "1739885002-pro-sieben.svg",
	yuZEXVuLSNeD7iRvcjSs:
		"1739885431-streamline-icon-contact-book-1-140x140-1.svg",
	yJMFlX3zR02NTNrz91VE: "1739885758-vergleich-siegel.png",
	P37U3D5nSaqz5fOi5kUM: "1739885538-icon-health.svg",
	VHMqxT3aTJeW6kJyjNVQ: "1739885387-workshop4_vue.jpg",
	nz5ozQ2WRSCPygSCM6tf: "1739885980-grafik-kpis.png",
	lbMmYRMCTTKWDI8sbnxz: "1739885041-hackerkiste.jpg",
	yZf9i5ilSleLc2nhIBBa: "1739885293-cartoon-joy-of-javascript-2.png",
	P3leCDSRoWdi1dWTSbax: "1739885795-icon-process.svg",
	FCVCQVjFSuSugINDn2YE: "1739885079-jessica-jpg.png",
	bQgFgNJ7TMKCRYUvkqEy: "1739885523-home-image-text-softwareentwicklung.png",
	PH2USPelSoiP9ciQ0EAI: "1739885836-khrystyna_s.png",
	ZtQe4F6FQtGSpY8Wdf3v: "1739885060-lightbulb.svg",
	DR0DrQALRuOwEl98g0V4: "1739885981-factsheet-react-workshop-en.pdf",
	z14D4K2UQHuuWwynFUTy: "1739885522-home-image-text-softwareentwicklung.png",
	SJL3bnCMT3GiTMvwk3wD: "1739885146-michael_talking.png",
	PsOZszeJR4GHZXT3TE4X: "1739885965-funk-03.jpg",
	RZFLyU9tQTGE8xmBXvsS: "1739885689-image-gallery-narrow-conf.png",
	kei0a4etR2uXilDwdkyo: "1739885592-ics.svg",
	SxE6iREQK26PB3DIEkcF: "1739885564-verpackung.svg",
	ri2w27Qqm4lzAZ6LyQ6g: "1739885482-css-units-header-pt2.jpg",
	T1Ak5jCUQqKo57ktCgLz: "1739885544-ezgif-6-175e08c67f37_2.gif",
	zCcHTIBmTymkA1UjkGeA: "1739885664-top-company.png",
	"8kfLR9RrTMeXZh0VHxHT": "1739885031-pwa.svg",
	D4Y2SfuvRX6La19PdtQG: "1739885683-bytesscreen.png",
	k4sGoDHASdy3X91cLYeo: "1739885157-diversitaet-im-unternehmen.jpg",
	OdfpbCqQlWqfRcVe0E0y: "1739885458-micro-frontends_2.jpg",
	"1VUZR5rSNCZ6pHoavcMZ": "1739885542-ezgif-6-2a1acf25b968_2.gif",
	A8TNWusRawinVKM4YggN: "1739885459-micro-frontends_2.jpg",
	AZrML1HDS9Cpybe0JknQ: "1739885038-road-milestones.svg",
	p2DeDy0Qqem56veiyzk1: "1739885753-tbd_logo_rgb_black_1.jpg",
	fjPkuWmTQCrkAaH9pPRS: "1739885208-peerigon_logo_neg_rgb_no_padding.svg",
	A60uXNzFQqanyYvbs2pU:
		"1739885986-suzanne-d-williams-vmkbfr6r_jg-unsplash.jpg",
	U3zNmxo5TcqraNGrjWMJ: "1739885420-peerigon_2020_ma-236.jpg",
	tzR2mW2RsCMrW5UBWwSQ: "1739885454-blauer-engel.png",
	tko0NsWSpmcSBsQtDwRQ: "1739885349-testimonial_female.png",
	DCXfarfSFq2WKIH0DElr: "1739885448-testimonial_female.png",
	fhsPLOYQT6Sfzf6xekZ6: "1739885709-part2l.png",
	FK1WwpiQ6aKtouXtgvAN: "1739885699-photo-1530062845289-9109b2c9c868.jpg",
	YMGTv2RnRTSUFA28dZJW: "1739885686-image-gallery-broad-worldcafe.png",
	uFZmmNhQS3q66doFMVH2: "1739885907-cloud-and-edge-icon-svg.svg",
	RAxBVphRTXOhJ38EThzz: "1739885074-tanner-jpg.png",
	XJZtrSqQTIKWwx6nOD0o: "1739885598-eslint.svg",
	zzQmRiqLQIO3Vw1Jx1pq: "1739885931-vuejs-use-cases.png",
	MlJLjXHGQgqKTeMKX1go: "1739885304-cartoon-javascript-keyword-this-10.png",
	ShdCIZEMTgaNEKQpottv: "1739885229-mozilla-inclusion-conversations.jpg",
	sxeIwZOTQOGFEqjx3KNr: "1739885716-projektl-enl.png",
	ETiAhHJgSoO6M5kAutGQ: "1739885368-benedikt.jpg",
	oaDicR9JRu24N7vfGbZC: "1739885428-sommerfest_05.jpg",
	Dv8Q7bgTOUVMFVaFo2og: "1739885536-icon-sustainable.svg",
	k3yvBvhQ0iT6B1XjyzU4: "1739885389-peerigon_2020_ma-047.jpg",
	rGqlgSDTEigU8eZoTaFA: "1739885239-johannes.jpg",
	pirF0qQRL6Wd4iN73x0G: "1739885395-peerigon_2020_ma-178.jpg",
	bBWIORf3QQKdGqutocxN: "1739885241-remote-office-pizza-lunch-break.jpeg",
	dQUrprSKKchkTCZhFFRw: "1739885115-boxcryptor.jpg",
	VZU0lKyAS8GxO6Q6d0wQ:
		"1739885273-cartoon-asynchronous-callbacks-promises-javascript.jpeg",
	MsluPcIVR1K1cPSlCZIP: "1739885597-reward.svg",
	CENlY1yiQpm4K4CzzbhA: "1739885305-cartoon-javascript-keyword-this-7.png",
	t47etFDIQCHgt7CbvgBZ:
		"1739885083-unnecessary-repaints-header-photo-by-kadir-celep-on-unsplash.jpg",
	"4oRSvwX6Rd60P1IW6fMW": "1739885862-portrait-martinshooting-isoliert.png",
	cdPzJqvSouQvARJfvs49: "1739885339-web-projektunterstuetzung.jpg",
	pT6ZEj2TSSGTir2Y9hct: "1739885264-cranking-monkey.gif",
	U2HEGzqnTBGDcNEQfLuP: "1739885551-rankedchoicebottom.png",
	me9DSxegSWikyBRXWlzv: "1739885104-cioplenu-case-study.jpg",
	"2UKC97XpT5SydpX2lSCB": "1739885855-workshop-ux.png",
	Jl8FPXjSSB2aypD0BMyM: "1739885005-jetzt-de.svg",
	YAVJwRHGRr26Zeb37HYM: "1739885045-nodeschool.png",
	wQxzE15TCazhvVn3u9PI:
		"1739885654-streamline-icon-collaborated-effort-2-400x400-1.svg",
	LfNyWj0cSfL2OfkHwxFQ: "1739884978-webassembly.png",
	"5puPFAZcQeC88F2uYs0M": "1739885814-03-added-dep.png",
	vwzmFpFdRcGYZ99xR81Q: "1739885582-ray-sono.svg",
	EFg2luKQGem2aNmSna3h: "1739885678-onepage.png",
	eLT1amjQTNS9DfweODjZ: "1739885469-circular-logo-javascript.png",
	"3NmacAEvR6yH02D8vJ8s": "1739885703-kununu_topcompanybadge_2023_blue.svg",
	"6jIqPZkoSK6O32sOkpl2": "1739885403-peerigon_2020_ma-029.jpg",
	Q72qmtT6TmmlCnQ5oiTJ: "1739885319-workshop6_vue.jpg",
	"2pMagdQ8QuW2qJEixSmL": "1739885017-icon-time-stopwatch.svg",
	DUKCCDxVTwuL2GuKdXTq: "1739884966-jobs.png",
	zFUHwtzySMKpoAkni5ht: "1739885786-purplelightup_banner.png",
	Gk4YiFMjRSuXm8knzSCt: "1739885015-notepad.png",
	mntM8jwNSpaaRGidDCpE: "1739885776-quesap-web-app.jpg",
	j9WAgEbDSwqWsJ0zyx6R: "1739885171-passion-group-manifest.jpg",
	QqY1cxEUTnab3whNrs9n: "1739885292-cartoon-joy-of-javascript-3.png",
	IWY92EqzQ3Som8CeEpRj:
		"1739885825-rainbow-robert-katzki-jbtfm0xberc-unsplash.jpg",
	"2g3nGswJTheYlDh29fKJ": "1739885023-sonomotors-case-study.jpg",
	NRrSK0r7RrMaUmsRroBZ: "1739885416-peerigon_2020_ma-258.jpg",
	c4bMOeu7R5mvvGaOQEFK: "1739885660-key-topic-section-image.png",
	HijbM9w6TA2FeJj3UV9r:
		"1739885271-cartoon-callback-run-script-javascript.jpeg",
	czJuzucjTPCaYnoAfupt: "1739885884-javascript-de.png",
	Q5g0gggRreMzP5LNOfsg: "1739885065-npm-scripts-header.jpg",
	pktkGXFZQeWvkVEGEHOh: "1739885126-test-image.png",
	C12c5gTQqGa7di04bGeg: "1739885113-sz-3.jpg",
	Rzo6mqZkQL8lRvnh516f: "1739885120-michael-stock-mobile.jpg",
	vyVsKKA7T7qtjjYLT2bw: "1739885327-workshop2.jpg",
	OkDxiH4cQxuew1xFakm5: "1739885875-lokker-01.jpg",
	rZKeW3xzRpyxprB4J5w3: "1739885117-yaskawa-3.jpg",
	A9mj0mq1T1SaZDo9Ly7g: "1739885215-vca_logo.jpg",
	MxVKMkSfQ2fnpJUrDQWy: "1739885619-teamphoto-jonas1.jpg",
	onIg2DQOQxEFzNd9pgOI: "1739885608-contact-person.png",
	RBsfatIvRn2cBwy4RDN8: "1739885841-01_cool.png",
	"7tPccVYOTh2yi4j8f6EM": "1739885236-michael.jpg",
	IKKa04FPT86QtJkWSp6u: "1739885923-bosch-legends-lab-01.jpg",
	jMhAZUJ7T7KB1jHODrEg: "1739885329-workshop10.jpg",
	hUBwHwQSPOcptK7sJq1Q: "1739885383-consulting2.jpg",
	Sca0yNURQyRdCY2e9VlD: "1739885920-softwaredevelopment-process.jpg",
	mqv0q8g2Qs6DPLhBlHWs: "1739885332-workshop1-narrow.jpg",
	JmcLvcE7SNeg0PQHvHdO: "1739885515-streamline-icon-blueprint-2-280x280.svg",
	A0Fj4irAQNCDx7OonHLI: "1739885340-peerigon-founders-treppe.jpg",
	ylcaur7iR3uhvs2I1eAs: "1739885269-cartoon-third-function-javascript.jpeg",
	WW0lnw7FRWHSNt8Gle6O: "1739885036-rocket-browser.svg",
	gGIzaefDT4SHy5nCVH6x: "1739885671-microsoftteams-image_small.png",
	UTT1I4EnQescFUjKgJDx: "1739885731-csr-report-lueften-min.png",
	RGORxc0HQ2KQK7tswtWT: "1739885465-circular-logo-angular.png",
	"6dgsN7c1TUe0svWt9haZ": "1739885357-jessi.jpg",
	AJZc1wbKQdG73dQMplwa: "1739885534-icon-remote.svg",
	WeTJHiAWQqqUDwicfbAs: "1739885298-cartoon-javascript-keyword-this-2.png",
	GQISEcgRFW8ql2GvKnew: "1739885751-startnext_201x_logo.png",
	teIErygRRmTIuNTVtkF9: "1739885488-px-em-rem-01.png",
	YbuWvnRaicOCorMxoowu: "1739885411-2019_09_17_irena.jpg",
	W4yoWwRkQXGPaqDU2wgP: "1739885813-02-snapshot-written.png",
	ElD7MWzZThKcSDeBysvc: "1739885485-ex-ch.png",
	NUJgrtCnS4SkYHOScr8H: "1739885088-paul.png",
	"750thOFR3KGpI8tIslm5": "1739885557-marketing_bg.svg",
	KURIe0XUQtKPhQksZKrt: "1739885787-disabilities.png",
	LYBsoxa7Q9ei5oEJnkay: "1739885846-04_salute.png",
	N5eAEcxXQX23NO0sHWpV: "1739885552-votedata.png",
	zfab4akDSwOU6SQHuUPJ: "1739886013-bluesky.svg",
	NhNoxdorQOKCPHASGtRv: "1739885698-page-header-photo-2.png",
	YOQIAxpgRcmZ98oaVfM8: "1739884958-martin-jpg.png",
	eIkBTPawS8ymVASo0BUY: "1739885711-teurefeaturesl.png",
	wGNCrXyFRXyLJhpeTwUJ: "1739885047-girls-day-logo.jpg",
	Iqc1PH7STEOTHpZBPhXI: "1739885056-hoodies2.jpg",
	T6epjw6aQuiSK7Fudq8w:
		"1739885123-javascript-multitasking-async-callbacks.jpg",
	r3HJaqq5RoyHxRh7yl9U: "1739885275-pixels-to-screen-pipeline.png",
	ZnxwsebfS8zc5eYiRbTQ: "1739885385-officep.jpg",
	eyTwGvYqSiee9gkVIPZZ: "1739885890-typescript.svg",
	E8YThRBKTwK6Zu9oulGl: "1739885487-font-setting-firefox.png",
	xRQ4RHe8QhSALucam80l: "1739885958-header-photo-industry-security.png",
	Yj1mvz51SL68ugppB6Xu: "1739886011-sevencooks-200.svg",
	wmaxc1QySZqf1HsIrbhx: "1739885473-css-units-header-pt1.jpg",
	tmEIVTuHQ5qDRrVX10wh: "1739885439-ts.png",
	QTMnSPYCSPyWzpgSExfS: "1739884943-peerigon_logo_bobbel_rgb_l.png",
	"8XYfq1v8TfaLqg3AKWIH": "1739885885-collage-ricarda-francesca.png",
	ywiH2GnKQfqhfC9GGnkI: "1739885996-spiderman.jpg",
	R4tQz1lpTyyk1RADHquA: "1739885421-peerigon_2020_ma-236_md.jpg",
	P3JWtGqGSlqp75nooqLY: "1739885287-cartoon-joy-of-javascript-1.png",
	VKE6uqvWQee9hckFp4KJ: "1739885755-tomorrow-finance.png",
	jLJ2PT3dREyuPwTnilPb: "1739885623-teamphoto-nyvonne.jpg",
	k6gPEnEHRbyF5gfEJt5H: "1739885799-icon-c02.svg",
	mEvIjmc0SJKg0ssqqK49: "1739885249-ok-and-cancel-button-mac-os-9.png",
	"273AwxKTDqS5zz8whqzv": "1739885556-marketing.svg",
	JXTkfPOnTC6thhDYDWpn: "1739885948-contact-book-2-streamline-ux.svg",
	j7fMOYAQri3xVdSAf7H9: "1739885052-fh-salzburg-logo.png",
	jnqSI4wcRoy0hd1lQ2pf: "1739885311-voting-app-result-page.jpeg",
	JvZuNyD5QpeYTA6Vr3Q5: "1739885191-kontakt-johannes-jpg.png",
	axlZCAjTAmKiM6oPT9GL: "1739885402-mzet0137-20191031.jpg",
	"0u191ZRDTOeBGtrUKSYg": "1739885170-nachhaltigkeit-unternehmenskultur.jpg",
	o7vjrXbMQyW9RfCAxj7t: "1739885827-statista_sw.svg",
	"0qm06QgXRFyNWxquV62A": "1739885820-magnify-recruiting.svg",
	rTyRKU86RvitLC1Qsn1W: "1739885842-02_stack.png",
	D0ntA6w5RwW0jZlzma7D: "1739885445-react.png",
	"9rDZyv9eS4icZIftynvR": "1739885919-konsens-teaser.jpg",
	vjoSpxp8Rje1aAOlvg7J: "1739885873-ricarda.png",
	E6DoClrtQ7GwE6otjIdt: "1739885650-v2018_de_c_15290-2012-1001.png",
	Oh7628rQnqYxXq82VW2g: "1739885567-sustainability_food-min.png",
	hdmU1HRjyPpZfeEY3ig6: "1739885532-icon-location.svg",
	ZF3eSp1YT7yTckOvmolf: "1739885797-icon-interaction.svg",
	MBMBcuX5QXuS98cyWCMH: "1739885240-lisa.jpg",
	"5Z6pO3nQVq482fzVk7fx": "1739885829-siemens_healthineers_logo_sw.svg",
	fN1kt5nSDCZUuLHv0i1N: "1739885343-contact-community-michael-johannes.jpg",
	C7KFCIgYRr2PmWCqHSE8: "1739885295-cartoon-joy-of-javascript-7.png",
	SvJoJlpASEi6scLoXgqp: "1739885998-whisply-200.svg",
	JswaYFYaS9GAlIFXnUqi: "1739884970-sustainabililty.png",
	"7YwN76ETGWFxBvLQJ9Gk": "1739885762-the_guardian_2018-svg.png",
	BCYBEIPURaePZ0lH0OUR: "1739885530-icon-content.svg",
	e58Kq1IjTK6OflBhGKy0: "1739885932-ai-circuit.svg",
	V9pKOhXYQMvEBvswtD3E: "1739885011-labyrinth.svg",
	ZgwLDGVbTn2MQlESBoK7: "1739885559-teamassistance.svg",
	EQ7cv9sUStiUOnbJ1YsR: "1739885735-b-corp-icon-header.svg",
	PgN8XzieS8ur2sYgtT0T: "1739885499-test.svg",
	oPMORZgT0SFigDd2GfjM: "1739885637-developer.svg",
	"6klcD918S5a8IxF3QlqX": "1739885754-logo-klean-kanteen-1.png",
	ZGKNP4m5RUKHUd7flxsX: "1739885286-swa-hackathon-group-picture.jpeg",
	"2Eg6Qu01TzWSzde5WZhA": "1739885247-ok-and-cancel-button-windows-95.png",
	M0DsuMYKQrGBj1T4waCI: "1739885452-peerigon_2020_ma-173.jpg",
	"91KWEARXOTPktxyMYUAC": "1739885417-istock-1028869800.jpg",
	"4hNxrK1jRCOEgWlSyH4o": "1739885673-blogimg.png",
	TgxjfZp4T0W4I0x1ArY7:
		"1739885084-css-modules-header-photo-by-kobu-agency-on-unsplash.jpg",
	"27L6k6GUSl2hMYvC157k": "1739885944-nextjs.png",
	LhPQJUMfTruRAPhVvsu4: "1739885647-donations_peerigon_2021_2.png",
	QbXDTdGZSIihP69vEuOj: "1739885471-peerigon-logo-icon-only.png",
	w4wEuPFOS3eZrNHcN2ih: "1739884975-node.png",
	VcXwP9HSRWyPk9nmXWVF: "1739885245-work-in-progress-button.gif",
	cQaaw5tQ0GBzSYyHx7Gq: "1739885348-mj.jpg",
	ujfLeJhBS1quiGPC9s59: "1739885805-peerigon_logo_strich_rgb.svg",
	eLj4oPoqR3uuhYqLnfyK: "1739884990-illustration.svg",
	MuzroBOHQdGpgQE6In2X: "1739885816-05-rimraf.png",
	QNaEfyHmT3iAsRWRTZ8f: "1739885963-next-js-logo.svg",
	BtyEKmIrTFuL1m9EN0pc: "1739885426-peerigon_2020_ma-135.jpg",
	zQgPr9LPQBGJtF7yNvjZ: "1739885707-costs-of-an-app-1.png",
	QQyJ7wVkTcyFRBAkPaZS: "1739885576-gothaer.svg",
	HKuWg28QTvKIpYmPT1Rq: "1739884969-community.png",
	zQDBGS76SZSJANcBZEMp: "1739885589-zooplus.svg",
	FWA10mroQmqVV5tKDO1d: "1739885444-angular.png",
	yiC7gW5ISle8aK4vhMOI: "1739885539-icon-techproject.svg",
	bCCn8zGsRHiWPmsXReHN: "1739885405-sommerfest_04.jpg",
	GRoshz8HQR2mTwhWabGq:
		"1739885186-peerigon-michael-jaser-stephan-batteiger.jpg",
	wFVOioZSTejPeUTM8DUL: "1739885464-circular-logo-typescript.png",
	Uly41rYHRACHE6PNbMnc: "1739885317-workshop14-md.jpg",
	Nynz2AWrQP220mCO9bwT:
		"1739885747-coffee-circle-logo-website-background-1.png",
	makFYmQOuyXfpw5v62AP: "1739885563-leitungswasser.svg",
	ZEHy5mG7RQ2MvmilUceV: "1739885738-bcorp-round.png",
	BlG0anRtTJGhgDYWnU6u: "1739885243-mojo-button.gif",
	T3uJNGEVQ2q4bAPEKGEs: "1739885450-peerigon_2020_ma-189.jpg",
	IsLxkzAUSjeR4TXRcAPl: "1739885462-circular-logo-nodejs.png",
	zHzNSg80TQVE4fwFMrnr:
		"1739885453-csm_eu_organic_logo_colour_outerline_54x36mm_kopie_2a207c157f.jpg",
	gVOqKvS9CXmvGImo8Ow3: "1739885352-matthias.jpg",
	"6z2GxDclS3KU040k9gDW":
		"1739885655-streamline-icon-coworker-2-1-400x400.svg",
	tJ4fAT4gT4SDdoIXT9C8: "1739885558-teamassistance_bg.svg",
	U4QEDHHRSqqe4owsyRyB: "1739885324-vue.png",
	fieNiR2vQt6t0UM1Duj1: "1739885826-dlr_logo.svg",
	J1MwDBOfQQCt91B9pnQO: "1739885131-web-construction-site.svg",
	mIMXf6n6S0iaziyIkrxo: "1739884985-br.svg",
	Xvv8aDj5RPCl6eKiaHIE: "1739885026-monitor-and-books.svg",
	sN29NdbCT4CrnibfBQ6q: "1739885195-icon-info.svg",
	TRVo19YxRbKfRv7ASFBK:
		"1739885704-streamlinehq-employee-decision-5-work-400.svg",
	dxfUm1X1Rmtja8MOr0nK: "1739885773-twitter.svg",
	H6ZrmPZ0RTG0k6tMMUPh: "1739885486-px-em-rem-03.png",
	GPXE173RQKWr45KQMJlQ: "1739885044-js-kongress.png",
	Pc9981kRCC67ArbNaTbg: "1739884995-sonomotors.svg",
	hvUkQnw0TbywhBJlA3nf: "1739885214-logo-aitiraum-2c-mit-subline.png",
	TkxdJKoQp2VGJE2e1Yc5: "1739885097-rocket.svg",
	V1KojZGxSayoHToTzn6v: "1739885712-enfeatures-enl.png",
	AtZqYbvaRWW1AVAabNtS: "1739885718-phases-enl.png",
	kkkjXMDsTtaDN6NSqnQ5: "1739885347-consulting.png",
	afD55zFGT8251BfIsrPe: "1739885335-labyrinth2.png",
};

export const getUrlFromHandle = (handle: string) => {
	const baseUrl = "https://www.datocms-assets.com/148875/";
	if (handle in map) {
		return baseUrl + map[handle as keyof typeof map];
	}

	throw new Error(
		`Handle ${handle} is not a valid hygraph->DatoCMS rewrite, use the original URL instead or find another asset.`,
	);
};
