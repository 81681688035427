import { useFormikContext } from "formik";
import { useTranslations } from "next-intl";
import React, { ReactNode } from "react";
import { Checkbox } from "./Checkbox";
import { Input } from "./Input";
import { Notice } from "./Notice";
import { Textarea } from "./Textarea";
import { ContactFormData, ContactFormDataErrors } from "./helpers";

type Props = {
	name: keyof ContactFormData;
	label?: ReactNode;
	ariaLabel?: string;
	type?: "text" | "textarea" | "checkbox";
	isRequired?: boolean;
};

export const Field: React.FC<Props> = ({
	label,
	ariaLabel,
	name,
	type = "text",
	isRequired = false,
}) => {
	const {
		values,
		errors,
		touched: allTouched,
		handleChange,
		handleBlur,
	} = useFormikContext();
	const t = useTranslations();

	const error = (errors as ContactFormDataErrors)[name];
	const value = (values as Partial<ContactFormData>)[name]?.toString();
	const touched = Boolean(
		(allTouched as Record<keyof Partial<ContactFormData>, boolean>)[name],
	);

	const otherType = !["textarea", "checkbox"].includes(type);
	const isInvalid = Boolean(touched && error);
	const fieldIsRequiredError = error?.includes("required");
	const errorMessage =
		touched && error
			? fieldIsRequiredError
				? t("fieldIsRequired")
				: error
			: undefined;

	const fieldProps = {
		name,
		type,
		value,
		isRequired,
		isInvalid,
		"aria-label": ariaLabel,
		id: name,
		placeholder: typeof label === "string" ? label : "",
		onChange: handleChange,
		onBlur: handleBlur,
	};

	return (
		<div>
			{type === "textarea" && (
				<div className="relative">
					<Notice isOverlay={true} errorMessage={errorMessage} />
					<Textarea {...fieldProps} />
				</div>
			)}

			{type === "checkbox" && (
				<>
					<Checkbox {...fieldProps}>{label}</Checkbox>
					<Notice errorMessage={errorMessage} />
				</>
			)}

			{otherType && (
				<>
					<Notice errorMessage={errorMessage} />
					<Input {...fieldProps} />
				</>
			)}
		</div>
	);
};
