"use client";

import { cn } from "@peerigon/pupper/tailwind";
import { useTranslations } from "next-intl";
import React from "react";
import { useBoolean, useMedia } from "react-use";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { ContactFormSectionItemFragment } from "src/__generated__/graphql";
import Link from "src/components/Link/Link";
import { ContentContainerTw } from "src/components/styled/ContentContainer";

import { readAsSpacing } from "src/enums";
import { breakpoints } from "src/styles/variables";
import ContactFormContainer from "./ContactFormContainer";
import Anchor from "../../Anchor/Anchor";
import { CmsImage } from "../../Image/CmsImage";
import { readCmsImageData } from "../../Image/utils";
import Title from "../../Title/Title";
import BaseSection from "../BaseSection";
import { Markdown } from "../MarkdownSection/MarkdownSection";

export const ContactFormSectionFragment = graphql(`
	fragment ContactFormSectionItem on ContactFormSectionRecord {
		id
		anchor
		emailAddress
		phoneNumber
		messagePlaceholder
		description
		image {
			# 🥃 Here's how you should use this kind of query:
			# Leave this as is
			...ImageFields
			# Find the optimal image settings, e.g. "at worst the image dimensions in browser
			# pixels will be 666px wide, all other responsive steps are smaller"
			# You can also use a height and crop etc.: https://www.datocms.com/docs/content-delivery-api/images-and-videos#responsive-images
			responsiveImage(imgixParams: { w: 666 }) {
				# If you want a blurhash for a placeholder, you can use base64
				base64
				# …alternatively you could use bgColor
				# bgColor
				...ResponsiveImage
			}
		}
		disableForm
		hideCompanyField
		isWorkshopForm
		title
		spacingTop
		spacingBottom
	}
`);

export type ContactFormCustomizationProps = {
	hideCompanyField?: ContactFormSectionItemFragment["hideCompanyField"];
	hidePhoneField?: boolean;
	hideMessageField?: boolean;
	hideNewsletterField?: boolean;
	isWorkshopForm?: ContactFormSectionItemFragment["isWorkshopForm"];
	messagePlaceholder?: ContactFormSectionItemFragment["messagePlaceholder"];
};

export const ContactFormSection: React.FC<{
	section: FragmentType<typeof ContactFormSectionFragment>;
}> = ({ section }) => {
	const isMinMd = useMedia(`(min-width: ${breakpoints.md}px)`, false);
	const [showSuccess, toggleShowSuccess] = useBoolean(false);
	const {
		spacingTop,
		spacingBottom,
		title,
		description,
		emailAddress,
		phoneNumber,
		image,
		disableForm,
		hideCompanyField,
		isWorkshopForm,
		messagePlaceholder,
		anchor,
	} = getFragmentData(ContactFormSectionFragment, section);
	const t = useTranslations();
	const showForm = !disableForm;
	const formName = `Contact Form (${emailAddress})`;
	const imageData = readCmsImageData(image);

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
			hideOverflow
		>
			<Anchor id={anchor} />
			<div
				className={cn(
					"mx-vw-5 max-h-[90vw] overflow-hidden",
					"md:absolute md:right-[55vw] md:top-px-80 md:z-default md:m-0 md:h-auto md:w-[40vw]",
					"lg:top-px-60 lg:max-w-[500px]",
				)}
			>
				<CmsImage image={imageData} />
			</div>
			<div className="md:m-[40px_0_60px_0]">
				<ContentContainerTw
					className="grid grid-cols-1 md:grid-cols-2 md:gap-px-80 lg:gap-px-110"
					style={
						isMinMd ? { gridTemplateAreas: "'. text'" } : undefined
					}
				>
					<div
						className="mt-px-40 md:mb-px-110 md:mt-px-160"
						style={{ gridArea: "text" }}
					>
						<Title headingLevel="h2">{title}</Title>
						<div className="my-px-30 mt-px-40">
							<Markdown source={description} />
						</div>
						<div className="col-start-2 col-end-auto grid grid-cols-[auto_1fr] gap-x-[5rem] gap-y-[15px]">
							<span className="text-size-5 text-px-18 lg:text-px-20">
								{t("contactFormSectionEmail")}
							</span>
							<div className="flex leading-none">
								<Link
									to={`mailto:${emailAddress}`}
									className="text-px-18 font-stronger lg:text-px-20"
								>
									{emailAddress}
								</Link>
							</div>
							<span className="text-size-5 text-px-18 lg:text-px-20">
								{t("contactFormSectionTelephone")}
							</span>
							<div className="flex leading-none">
								<Link
									to={`tel:${phoneNumber}`}
									className="text-px-18 font-stronger lg:text-px-20"
								>
									{phoneNumber}
								</Link>
							</div>
						</div>
						{showForm && showSuccess && (
							<div className="mt-px-40 bg-sea px-[1.4rem] py-[2.1rem]">
								<p className="text-size-7 mb-0 text-px-18">
									{t("contactFormSuccessMessage")}
									<br />
									<strong>
										{t.rich("contactFormSuccessCTAText", {
											button: (chunks) => (
												<button
													type="button"
													onClick={(ev) => {
														ev.preventDefault();
														toggleShowSuccess(
															false,
														);
													}}
												>
													{chunks}
												</button>
											),
										})}
									</strong>
								</p>
							</div>
						)}

						{showForm && !showSuccess && (
							<ContactFormContainer
								formName={formName}
								onSuccess={() => toggleShowSuccess(true)}
								hideCompanyField={hideCompanyField}
								isWorkshopForm={isWorkshopForm}
								messagePlaceholder={messagePlaceholder}
							/>
						)}
					</div>
				</ContentContainerTw>
			</div>
		</BaseSection>
	);
};
