"use client";

import { Box, Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { readCmsImageData } from "src/components/Image/utils";
import Background from "./Background";
import StyledIllu from "./StyledIllu";
import { mediaQuery } from "../../../styles/mixins";
import { colors, heights, dimensions } from "../../../styles/variables";
import Title from "../../Title/Title";
import ContentContainer from "../../styled/ContentContainer";
import HeadingAddOn from "../../styled/HeadingAddOn";
import { Markdown } from "../MarkdownSection/MarkdownSection";

export const PageHeaderIlluSectionFragment = graphql(`
	fragment PageHeaderIlluSectionItem on PageHeaderIlluSectionRecord {
		id
		introText
		headingAddOn
		heading
		illu {
			...ImageFields
			responsiveImage(imgixParams: { w: 1200 }) {
				...ResponsiveImage
			}
		}
	}
`);

const styleIntroText = css`
	text-align: center;
	margin-bottom: 50px;
	${mediaQuery.lg} {
		width: 70%;
		margin: auto;
	}
`;

export const PageHeaderIlluSection: React.FC<{
	section: FragmentType<typeof PageHeaderIlluSectionFragment>;
}> = ({ section }) => {
	const data = getFragmentData(PageHeaderIlluSectionFragment, section);
	const { headingAddOn, heading, introText, illu } = data;
	const illuData = readCmsImageData(illu);
	const { mobileInPx, desktopInPx, contentDesktop } =
		heights.pageIlluHeaderSection;

	return (
		<Box as="section">
			<Background />
			<ContentContainer>
				<Flex
					minHeight={[mobileInPx, mobileInPx, contentDesktop]}
					maxHeight={[
						mobileInPx,
						mobileInPx,
						mobileInPx,
						desktopInPx,
					]}
					align={["flex-end", "flex-end", "flex-end", "center"]}
					justify={["flex-end", "flex-end", "flex-end", "center"]}
					direction={["column", "column", "column", "column", "row"]}
					marginTop={dimensions.spacing.px25}
					marginBottom={[
						dimensions.spacing.px50,
						dimensions.spacing.px50,
						dimensions.spacing.px80,
						dimensions.spacing.px100,
					]}
				>
					<Title
						headingLevel="h1"
						color={colors.almostBlack}
						margin="auto auto auto 0"
						breakWord
					>
						{headingAddOn && (
							<HeadingAddOn
								addOn={headingAddOn}
								color={colors.almostBlack}
							/>
						)}
						{heading}
					</Title>
					{illuData && (
						<StyledIllu
							src={illuData.url}
							height={
								illuData.responsiveImage?.height ?? undefined
							}
							width={illuData.responsiveImage?.width ?? undefined}
							alt={illuData.responsiveImage?.alt ?? ""}
							role={
								illuData.responsiveImage?.alt
									? undefined
									: "none"
							}
						/>
					)}
				</Flex>
				{introText && (
					<Markdown source={introText} css={styleIntroText} />
				)}
			</ContentContainer>
		</Box>
	);
};
