import { useEffect, useRef, useState } from "react";

export const useImageGalleryScroll = () => {
	const [multiplier, setMultiplier] = useState(1);
	const [marqueeScrollWidth, setMarqueeScrollWidth] = useState(0);
	const marqueeRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const calculateWidth = () => {
			const scrollWidth = marqueeRef.current?.scrollWidth ?? 0;
			const innerWidth = window.innerWidth;
			const multiplier =
				scrollWidth === 0 ? 1 : Math.ceil(innerWidth / scrollWidth);
			setMultiplier(multiplier);
			setMarqueeScrollWidth(scrollWidth);
		};

		calculateWidth();
		marqueeRef.current?.querySelectorAll("img").forEach((img) => {
			img.addEventListener("load", () => {
				calculateWidth();
			});
		});
		const resizeObserver = new ResizeObserver(() => calculateWidth());
		if (marqueeRef.current) {
			resizeObserver.observe(marqueeRef.current);
		}
		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	return {
		marqueeRef,
		multiplier,
		marqueeScrollWidth,
	};
};
