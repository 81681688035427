"use client";

import { SimpleGrid } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useTranslations } from "next-intl";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import NavRightIcon from "src/assets/navRight.svg";
import Button from "src/components/ButtonNew/Button";
import { readCmsImageData } from "src/components/Image/utils";
import IntroBlock from "src/components/IntroBlock/IntroBlock";

import { readAsSpacing } from "src/enums";
import Icon from "./Icon";
import WorkshopTile from "./WorkshopTile";
import { mediaQuery, addZoomHoverEffect } from "../../../styles/mixins";
import { getColorTheme, shadows } from "../../../styles/variables";
import Link from "../../Link/Link";
import Title from "../../Title/Title";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";
import { CrossLinkElementFieldsFragment } from "../CrossLinkSection/CrossLink";
import { Markdown } from "../MarkdownSection/MarkdownSection";

export const WorkshopsSectionFragment = graphql(`
	fragment WorkshopsSectionItem on WorkshopsSectionRecord {
		__typename
		id
		spacingTop
		spacingBottom
		headline
		introText: descriptionMd
		crossLinkElements {
			...CrossLinkElementFields
			image {
				...ImageFields
				responsiveImage(imgixParams: { w: 768 }) {
					# 🥃 TODO: find correct image dimensions
					...ResponsiveImage
				}
			}
		}
		workshopsColorTheme: colorTheme
	}
`);

const linkStyle = css`
	display: flex;
	position: relative;
	box-shadow: ${shadows.card};
	color: black;
	border-radius: 12px;

	:hover {
		box-shadow: ${shadows.light};
	}

	${mediaQuery.lg} {
		${addZoomHoverEffect(1.07)}
	}
`;

export const WorkshopsSection: React.FC<{
	section: FragmentType<typeof WorkshopsSectionFragment>;
}> = ({ section }) => {
	const {
		headline,
		spacingTop,
		spacingBottom,
		introText,
		crossLinkElements,
		workshopsColorTheme,
	} = getFragmentData(WorkshopsSectionFragment, section);

	const t = useTranslations();

	const more = t("more");

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
			style={{
				"--color-theme-fg": workshopsColorTheme
					? getColorTheme(workshopsColorTheme).fg
					: "inherit",
				"--color-theme-bg": workshopsColorTheme
					? getColorTheme(workshopsColorTheme).bg
					: "inherit",
			}}
			className="bg-[var(--color-theme-bg)] text-[var(--color-theme-fg)]"
		>
			<ContentContainer>
				<div className="flex flex-col gap-10 lg:gap-20">
					<IntroBlock
						heading={headline}
						description={introText ?? undefined}
					/>

					<SimpleGrid
						columns={{ xs: 1, sm: 2, lg: 3 }}
						spacingX={["10px", "30px"]}
						spacingY={["20px", "40px"]}
					>
						{crossLinkElements.map((element) => {
							const elementData = getFragmentData(
								CrossLinkElementFieldsFragment,
								element,
							);
							const elementImage = readCmsImageData(
								element.image,
							);
							if (!elementData.linkToPage) {
								return (
									<div
										key={elementData.description}
										className="relative flex rounded-xl text-black shadow-card lg:zoom-hover-effect-1.07"
									>
										<WorkshopTile>
											{elementImage && (
												<Icon data={elementImage} />
											)}
											<div className="flex flex-col gap-3">
												<Title
													headingLevel="h3"
													textAlign="center"
													margin="0"
												>
													{
														elementData.destinationText
													}
												</Title>
												<div className="text-center [&_div]:m-0">
													<Markdown
														source={
															elementData.description
														}
													/>
												</div>
											</div>
										</WorkshopTile>
									</div>
								);
							}

							return (
								<Link
									key={elementData.description}
									to={elementData.linkToPage.routeName}
									css={linkStyle}
									className="group"
								>
									<WorkshopTile>
										{elementImage && (
											<Icon data={elementImage} />
										)}
										<div className="flex flex-col gap-3">
											<Title
												headingLevel="h3"
												textAlign="center"
												margin="0"
											>
												{elementData.destinationText}
											</Title>
											<div className="text-center [&_div]:m-0">
												<Markdown
													source={
														elementData.description
													}
												/>
											</div>
										</div>

										<div className="mx-auto mt-auto text-center">
											<Button variant="secondary">
												<NavRightIcon />
												{more}
											</Button>
										</div>
									</WorkshopTile>
								</Link>
							);
						})}
					</SimpleGrid>
				</div>
			</ContentContainer>
		</BaseSection>
	);
};
