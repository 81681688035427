import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import Link from "src/components/Link/Link";
import { CmsImage } from "../Image/CmsImage";
import { readCmsImageData } from "../Image/utils";

const FooterSocialMediaLinksFieldsFragment = graphql(`
	fragment FooterSocialMediaLinksFields on FooterRecord {
		socialMediaLinks {
			id
			image {
				...ImageFields
				responsiveImage(imgixParams: { w: 768 }) {
					# 🥃 TODO: find correct image dimensions
					...ResponsiveImage
				}
			}
			externalLink
		}
	}
`);

const Social: React.FC<{
	footer: FragmentType<typeof FooterSocialMediaLinksFieldsFragment>;
}> = ({ footer }) => {
	const { socialMediaLinks } = getFragmentData(
		FooterSocialMediaLinksFieldsFragment,
		footer,
	);

	return (
		<div className="ml-auto flex flex-row items-center gap-5">
			{socialMediaLinks.map((social) => {
				if (social.externalLink == null || social.image == null)
					return null;
				const socialImageData = readCmsImageData(social.image);

				return (
					<Link
						key={social.id}
						to={social.externalLink}
						aria-label="Social Media Link"
						target="_blank"
						className="flex h-6 w-6 shrink-0 items-center justify-center disable-anchor-shadow-styles hover:text-mint focus:text-mint"
					>
						<CmsImage image={socialImageData} />
					</Link>
				);
			})}
		</div>
	);
};

export default Social;
