"use client";

import { css } from "@emotion/react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { readAsSpacing } from "src/enums";
import { AnimatedIllustrationBackground } from "./AnimatedIllustrationBackground";
import AnimatedIllustrationContainer from "./AnimatedIllustrationContainer";
import FloatingCircle from "./FloatingCircle";
import { StaticImage } from "./StaticImage";
import TextContainer from "./TextContainer";
import { mediaQuery } from "../../../styles/mixins";
import {
	checkColorTheme,
	colors,
	getColorTheme,
} from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import { CmsImage } from "../../Image/CmsImage";
import { readCmsImageData } from "../../Image/utils";
import Title from "../../Title/Title";
import ContentContainer from "../../styled/ContentContainer";
import HeadingAddOn from "../../styled/HeadingAddOn";
import BaseSection from "../BaseSection";
import { Markdown } from "../MarkdownSection/MarkdownSection";

const styleContentContainer = css`
	margin-bottom: 45px;
	${mediaQuery.md} {
		margin-bottom: 0;
	}
`;

export const KeyTopicsSectionFragment = graphql(`
	fragment KeyTopicsSectionItem on KeyTopicsSectionRecord {
		__typename
		id
		anchor
		spacingTop
		spacingBottom
		listItems {
			headlineAddOn
			headline
			illustration: icon {
				...ImageFields
				responsiveImage(
					imgixParams: { w: 600, fit: crop, auto: [format, compress] }
				) {
					...ResponsiveImage
				}
			}
			description: text
		}
		keyTopicsColorTheme: colorTheme
	}
`);

export const KeyTopicsSection: React.FC<{
	section: FragmentType<typeof KeyTopicsSectionFragment>;
}> = ({ section }) => {
	const {
		anchor,
		listItems,
		spacingTop,
		spacingBottom,
		keyTopicsColorTheme,
	} = getFragmentData(KeyTopicsSectionFragment, section);

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
			style={{
				"--color-theme-fg": checkColorTheme(keyTopicsColorTheme)
					? getColorTheme(keyTopicsColorTheme).fg
					: "inherit",
				"--color-theme-bg": checkColorTheme(keyTopicsColorTheme)
					? getColorTheme(keyTopicsColorTheme).bg
					: "inherit",
			}}
			className="bg-[var(--color-theme-bg)] text-[var(--color-theme-fg)]"
		>
			<Anchor id={anchor} />
			{listItems.map(
				(
					{ headlineAddOn, headline, description, illustration },
					index,
				) => {
					const resolvedIllustration = readCmsImageData(illustration);
					const isSvg =
						resolvedIllustration?.mimeType.includes("svg") ?? true;

					const contentContainerProps = {
						css: styleContentContainer,
						[isSvg ? "flexDirectionMd" : "flexDirectionLg"]:
							index % 2 ? "row-reverse" : "row",
					};

					const textContainerProps = {
						[isSvg ? "widthMd" : "widthLg"]: isSvg ? "66%" : "50%",
						[isSvg ? "paddingMd" : "paddingLg"]: "50px",
					};

					return (
						<ContentContainer
							key={description}
							{...contentContainerProps}
						>
							{resolvedIllustration &&
								(isSvg ? (
									<AnimatedIllustrationBackground>
										<AnimatedIllustrationContainer>
											<FloatingCircle />
											<CmsImage
												image={resolvedIllustration}
												objectFit="contain"
												role="presentation"
											/>
										</AnimatedIllustrationContainer>
									</AnimatedIllustrationBackground>
								) : (
									<StaticImage>
										<CmsImage
											image={resolvedIllustration}
										/>
									</StaticImage>
								))}
							<TextContainer {...textContainerProps}>
								<Title
									headingLevel="h2"
									margin="0 0 20px 0"
									marginLg="0 0 32px 0"
									color={
										checkColorTheme(keyTopicsColorTheme)
											? getColorTheme(keyTopicsColorTheme)
													.fg
											: undefined
									}
								>
									{headlineAddOn && (
										<HeadingAddOn
											addOn={headlineAddOn}
											color={colors.blueberry}
										/>
									)}
									{headline}
								</Title>

								<Markdown source={description} />
							</TextContainer>
						</ContentContainer>
					);
				},
			)}
		</BaseSection>
	);
};
