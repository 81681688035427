"use client";

import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { readAsSpacing } from "src/enums";
import FigureCard from "./FigureCard";
import TitleDescription from "../../TitleDescription/TitleDescription";
import { useOneOffWhenVisible } from "../../helpers";
import { ContentContainerTw } from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

export const FactsAndFiguresSectionFragment = graphql(`
	fragment FactsAndFiguresSectionItem on FactsAndFiguresSectionRecord {
		id
		sectionTitle: title
		descriptionMd
		spacingTop
		spacingBottom
		descriptionMd
		figureCards: cards {
			...FigureFields
		}
	}
`);

const FigureFragment = graphql(`
	fragment FigureFields on FigureCardRecord {
		id
		description
		figure
		figureSuffix
		figurePrefix
		fontSize
		fontStyle
		bigBlock
		colorThemeEnum
		animationRate
	}
`);

export const FactsAndFiguresSection: React.FC<{
	section: FragmentType<typeof FactsAndFiguresSectionFragment>;
}> = ({ section }) => {
	const {
		spacingTop,
		spacingBottom,
		sectionTitle,
		descriptionMd,
		figureCards: cards,
	} = getFragmentData(FactsAndFiguresSectionFragment, section);

	const [ref, showCard] = useOneOffWhenVisible();

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
		>
			<ContentContainerTw ref={ref}>
				<TitleDescription
					title={sectionTitle}
					description={descriptionMd}
				/>
				<div className="grid grid-cols-1 sm:grid-cols-3">
					{cards.map((card) => {
						const data = getFragmentData(FigureFragment, card);

						return (
							<FigureCard
								visible={showCard}
								key={data.id}
								card={data}
							/>
						);
					})}
				</div>
			</ContentContainerTw>
		</BaseSection>
	);
};
