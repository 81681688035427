import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { CmsImageData } from "src/components/Image/utils";
import ChallengeTitleSection from "./ChallengeTitleSection";
import ResponsiveArrow from "./ResponsiveArrow";
import { mediaQuery } from "../../../styles/mixins";
import { CmsImage } from "../../Image/CmsImage";
import Title from "../../Title/Title";

const ArrowWrapper = styled.div`
	flex: 1;
	overflow: hidden;
	display: none;
	position: relative;
	margin-left: 32px;

	> svg {
		position: absolute;
		width: 100%;
	}

	${mediaQuery.lg} {
		display: flex;
		align-items: center;
	}
`;

const Challenge: React.FC<{
	icon: CmsImageData | null | undefined;
	title: string | null | undefined;
	text: string | null | undefined;
}> = ({ icon, title, text }) => {
	const arrowWrapper = useRef<HTMLDivElement>(null);
	const [width, setWidth] = useState(175);

	useEffect(() => {
		if (arrowWrapper.current) {
			setWidth(arrowWrapper.current.clientWidth);
		}
	}, []);

	const handleResize = useCallback(() => {
		if (arrowWrapper.current) {
			const newWidth = arrowWrapper.current.clientWidth;

			setWidth(newWidth);
		}
	}, [arrowWrapper]);

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [handleResize]);

	return (
		<>
			<ChallengeTitleSection>
				<Flex align="center">
					{icon && <CmsImage className="size-6" image={icon} />}
					<Title headingLevel="h5" margin="0 0 0 8px">
						{title}
					</Title>
				</Flex>
				<ArrowWrapper ref={arrowWrapper}>
					<ResponsiveArrow width={width} />
				</ArrowWrapper>
			</ChallengeTitleSection>

			<Title headingLevel="h4" margin="2px 0 0 33px">
				{text}
			</Title>
		</>
	);
};

export default memo(Challenge);
