"use client";
import {
	Accordion,
	AccordionItem,
	AccordionPanel,
	Flex,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import React, { FC, useMemo } from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { readAsSpacing } from "src/enums";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions } from "../../../styles/variables";
import { CmsImage } from "../../Image/CmsImage";
import { readCmsImageData } from "../../Image/utils";
import StyledAccordionButton from "../../StyledAccordion/StyledAccordionButton";
import TitleDescription from "../../TitleDescription/TitleDescription";
import { getDefaultExpandedList } from "../../helpers";
import AnimatedEnlargeIcon from "../../styled/AnimatedEnlargeIcon";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";
import { Markdown } from "../MarkdownSection/MarkdownSection";

export const FaqSectionFragment = graphql(`
	fragment FaqSectionItem on FaqSectionRecord {
		id
		title
		spacingTop
		spacingBottom
		faqDescription: descriptionMd
		listItems {
			id
			expandByDefault
			text
			headline
			icon {
				...ImageFields
				responsiveImage(imgixParams: { w: 768 }) {
					# 🥃 TODO: find correct image dimensions
					...ResponsiveImage
				}
			}
			# 🥃 why was this queried?
			# faqSections {
			# 	id
			# }
		}
	}
`);

const ICON_SIZE_PX = 25;
const ICON_SIZE_MD_PX = 70;

const ICON_MARGIN_PX = 10;
const ICON_MARGIN_MD_PX = 20;

// Icons must have a fixed width to avoid svg images being to wide.
const styleIcon = css`
	display: inline-block;
	height: ${ICON_SIZE_PX}px;
	width: ${ICON_SIZE_PX}px;
	margin-right: ${ICON_MARGIN_PX}px;
	border-radius: 50%;

	${mediaQuery.md} {
		height: ${ICON_SIZE_MD_PX}px;
		width: ${ICON_SIZE_MD_PX}px;
		margin-right: ${ICON_MARGIN_MD_PX}px;
	}
`;

export const FaqSection: FC<{
	section: FragmentType<typeof FaqSectionFragment>;
}> = ({ section }) => {
	const { title, spacingTop, spacingBottom, faqDescription, listItems } =
		getFragmentData(FaqSectionFragment, section);

	const defaultIndexList = useMemo(
		() => getDefaultExpandedList(listItems, "expandByDefault"),
		[listItems],
	);

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
		>
			<ContentContainer>
				<TitleDescription title={title} description={faqDescription} />
				<Accordion defaultIndex={defaultIndexList} allowMultiple>
					{listItems.map(({ id, icon, headline, text }) => (
						<AccordionItem
							key={id}
							borderTop="none"
							borderBottom={dimensions.border.divider}
						>
							{({ isExpanded }) => (
								<>
									<StyledAccordionButton>
										<Flex alignItems="center">
											{icon && (
												<CmsImage
													image={readCmsImageData(
														icon,
													)}
													css={styleIcon}
												/>
											)}
											<h3 className="text-size-3 mr-large text-left">
												{headline}
											</h3>
										</Flex>

										<AnimatedEnlargeIcon
											active={isExpanded}
										/>
									</StyledAccordionButton>
									<AccordionPanel
										padding={["0", "0", "0", null]}
										margin={[
											"0 0 20px 0",
											"0 0 20px 0",
											"0 0 20px 0",
											"0 auto 40px auto",
										]}
										// On larger devices the width is restricted to the upper limit of 95 chars (about 900px)
										// ch-units weren't used, since they do not match this font-shape properly and are therefore less useful.
										// The text is aligned with the icon and in the middle of the div
										width={[
											"100%",
											"100%",
											"100%",
											`min(calc(100% - ${(ICON_SIZE_MD_PX + ICON_MARGIN_MD_PX) * 2}px), 900px)`,
										]}
									>
										<Markdown source={text} />
									</AccordionPanel>
								</>
							)}
						</AccordionItem>
					))}
				</Accordion>
			</ContentContainer>
		</BaseSection>
	);
};
