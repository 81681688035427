import { Flex, Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { SmallText, Author as AuthorText } from "../../../styles/FontFaces";
import { mediaQuery } from "../../../styles/mixins";
import { colors } from "../../../styles/variables";
import { CmsImage } from "../../Image/CmsImage";
import { CmsImageData, readCmsImageData } from "../../Image/utils";

const AuthorName = styled(AuthorText)`
	text-align: center;
	padding-bottom: 1rem;
`;

const ImageWrapper = styled.div`
	width: 100%;
	max-width: 110px;
	margin: 0 auto;
	margin-bottom: 1rem;

	img {
		border-radius: 50%;
	}

	${mediaQuery.md} {
		max-width: 150px;
	}
`;

const Author: React.FC<{
	author: {
		name: string;
		jobTitle?: string | null;
		image: CmsImageData;
		avatarImage?: CmsImageData | null;
	};
}> = ({ author }) => {
	const { name, jobTitle, image, avatarImage } = author;
	const imageData = readCmsImageData(image);
	const avatarImageData = avatarImage
		? readCmsImageData(avatarImage)
		: avatarImage;

	return (
		<Box pos="relative" gridArea="author">
			<Flex
				as="figure"
				direction="column"
				justify="center"
				align="center"
				textAlign="center"
			>
				<ImageWrapper>
					<CmsImage
						image={avatarImageData ? avatarImageData : imageData}
					/>
				</ImageWrapper>
				<AuthorName>{name}</AuthorName>
				<SmallText color={colors.gray.text}>{jobTitle}</SmallText>
			</Flex>
		</Box>
	);
};

export default Author;
