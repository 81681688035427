/**
 * DatoCMS ids of menu items used for navigation & structured data features.
 * Update when replacing header menu item content for this item.
 */
export const SUCCESS_STORIES_HEADER_ITEM_ID = "cgasbDadTpOfmqMzBLtV9w";
export const BLOG_HEADER_ITEM_ID = "W2zXOCYtTWiwnRG3jDaltQ";
export const SERVICES_HEADER_ITEM_ID = "PrtLpjNHRGiWLysYMvLRbQ";
export const WORKSHOPS_HEADER_ITEM_ID = "O2Sl2INHTvK-xHgtROiZxw";

// 🥃 imho we should refactor this check to use the dato_cms ID (defined in src/lib/cms-constants). `routeName`s can be changed!
export const FULL_STACK_TRAINING_MENU_ITEM_ROUTE_NAME = "full-stack-training";
// TODO: currently unused 👇 needed once we refactor the check above
// export const FULL_STACK_TRAINING_HEADER_ITEM_ID = "O2Sl2INHTvK-xHgtROiZxw";
