"use client";

import React from "react";
import { Hx } from "uberschrift";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { buttonVariants } from "src/components/ButtonNew/Button";
import Link from "src/components/Link/Link";
import { getColorTheme } from "../../../styles/variables";
import { CmsImage } from "../../Image/CmsImage";
import { readCmsImageData } from "../../Image/utils";
import { ContentContainerTw } from "../../styled/ContentContainer";
import { Markdown } from "../MarkdownSection/MarkdownSection";

export const PageHeaderComboSectionFragment = graphql(`
	fragment PageHeaderComboSectionItem on PageHeaderComboSectionRecord {
		id
		heading
		headingAddOn
		photo {
			...ImageFields
			responsiveImage(
				imgixParams: {
					w: 1200
					h: 600
					fit: crop
					auto: [format, compress]
				}
			) {
				...ResponsiveImage
			}
		}
		introText
		colorThemeEnum
		action {
			... on CardActionRecord {
				id
				label
				url
			}
		}
	}
`);

export const PageHeaderComboSection: React.FC<{
	section: FragmentType<typeof PageHeaderComboSectionFragment>;
}> = ({ section }) => {
	const { headingAddOn, heading, introText, photo, colorThemeEnum, action } =
		getFragmentData(PageHeaderComboSectionFragment, section);

	const photoData = readCmsImageData(photo);

	return (
		<section
			style={{
				"--color-theme-fg": colorThemeEnum
					? getColorTheme(colorThemeEnum).fg
					: "inherit",
				"--color-theme-bg": colorThemeEnum
					? getColorTheme(colorThemeEnum).bg
					: "inherit",
			}}
			className="bg-[var(--color-theme-bg)] text-[var(--color-theme-fg)]"
		>
			<ContentContainerTw>
				<div className="mb-20 mt-20 flex flex-col gap-10 md:mt-px-120 md:gap-20">
					<div className="flex flex-col gap-10 md:gap-20">
						<div className="flex flex-col items-center gap-4 md:gap-6">
							{headingAddOn ? (
								<div className="text-size-6 text-center">
									{headingAddOn}
								</div>
							) : null}

							<Hx className="text-size-1 m-0 text-center text-[clamp(4rem,3vw_+_1rem,6.8rem)] text-[inherit]">
								{heading}
							</Hx>

							{action ? (
								<Link
									to={action.url}
									className={buttonVariants({
										isLink: true,
										variant: "tertiary",
									})}
								>
									{action.label}
								</Link>
							) : null}
						</div>

						{photoData ? (
							<CmsImage
								image={photoData}
								priority={true}
								className="h-full min-h-[400px] w-full rounded-lg bg-gray-100 object-cover md:min-h-[600px] md:rounded-xl xl:rounded-[2rem]"
							/>
						) : null}
					</div>

					{introText ? (
						<div className="mx-auto max-w-[80rem] [&_p]:text-size-7 [&_div]:last-of-type:m-0 [&_p]:text-center">
							<Markdown source={introText} />
						</div>
					) : null}
				</div>
			</ContentContainerTw>
		</section>
	);
};
