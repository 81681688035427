import styled from "@emotion/styled";
import React from "react";
import { colors, fonts } from "../../../styles/variables";

const InlineCode = styled.span`
	font-family: ${fonts.mono};
	font-size: 0.85em;
	color: ${colors.gray.text};
	box-shadow: inset 0px -25px 0px 0px ${colors.ice};
	display: inline;
	padding: 2px 5px 1px;
`;

const InlineCodeRenderer = (props: { children?: React.ReactNode }) => {
	return <InlineCode>{props.children}</InlineCode>;
};

export default InlineCodeRenderer;
