import { Box, Heading } from "@chakra-ui/react";
import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Link from "next/link";
import { useFormatter, useLocale, useTranslations } from "next-intl";
import React, { useMemo } from "react";
import {
	SiteLocale,
	TeaseredBlogPostItemFragment,
} from "src/__generated__/graphql";

import { stringListToArray } from "src/hygraph-to-dato";
import { getBlogPath } from "src/lib/paths";
import { GoToBlogArticleWrapper } from "./GoToBlogArticleWrapper";
import { ImageContainer } from "./ImageContainer";
import { TinyHeadline } from "../../styles/FontFaces";
import {
	addZoomHoverEffect,
	mediaQuery,
	addZoomElementHoverEffect,
} from "../../styles/mixins";
import { colors, dimensions, fonts, shadows } from "../../styles/variables";
import { buttonTextClassName } from "../Button/Button";
import { CmsImage } from "../Image/CmsImage";
import { readCmsImageData } from "../Image/utils";
import Tags from "../Tags/Tags";
import TextAndAnimatedArrow from "../TextAndAnimatedArrow/TextAndAnimatedArrow";

type BlogPostTileProps = TeaseredBlogPostItemFragment & {
	isFullTeaser?: boolean;
	isTeaserSection?: boolean | undefined;
};

const colorSchemes = [
	{
		color: "black",
		backgroundColor: colors.white,
		secondaryColor: colors.gray.dark,
	},
	{
		color: "black",
		backgroundColor: colors.mint,
		secondaryColor: colors.nightSky,
	},
	{
		color: "white",
		backgroundColor: colors.blueberry,
		secondaryColor: colors.moonRaker,
	},
	{
		color: "black",
		backgroundColor: colors.ice,
		secondaryColor: colors.gray.dark,
	},
	{
		color: "white",
		backgroundColor: colors.nightSky,
		secondaryColor: colors.moonRaker,
	},
];

const cheapHash = (input: string) =>
	input
		.split("")
		.map((char) => char.charCodeAt(0))
		.reduce((sum, value) => sum + value, input.length) * input.length;

const styleTitle = css`
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

const styleTinyHeadline = css`
	width: 80%;
	line-height: 0.9;

	${mediaQuery.lg} {
		width: 100%;
	}
`;

const styleImage = (
	isFullTeaser: boolean | undefined,
	isTeaserSection: boolean | undefined,
) => css`
	${isFullTeaser || isTeaserSection
		? "height: 100%; width: 100%; object-fit: cover;"
		: `height: ${dimensions.masonry.mobile.image}; width: ${dimensions.masonry.mobile.image};`}

	${mediaQuery.md} {
		${isFullTeaser
			? "height: 100%; width: 100%;"
			: `height: ${dimensions.masonry.mobile.image}; width: ${dimensions.masonry.mobile.image};`}
	}

	${mediaQuery.lg} {
		position: relative;
		height: ${isFullTeaser ? "100%" : `${dimensions.masonry.imageTile}`};
		width: 100%;
		object-fit: cover;
		transition: transform 0.4s ease;
	}
`;

const StyledLink = styled(Link, { shouldForwardProp: isPropValid })<{
	isFullTeaser: boolean | undefined;
	isTeaserSection: boolean | undefined;
	headerImage: boolean;
}>`
	position: relative;
	box-shadow: ${shadows.light};
	&:hover {
		/* the outlines of white tiles should be distinguishable from the background on hover  */
		box-shadow: ${shadows.light};
	}
	text-decoration: none;
	${({ isFullTeaser, isTeaserSection }) =>
		(isFullTeaser || isTeaserSection) && "grid-row: span 3;"}

	${mediaQuery.md} {
		display: flex;
		height: 100%;
		${({ isFullTeaser }) =>
			isFullTeaser
				? css`
						grid-column: 1 / 3;
						flex-direction: row;
						grid-row: span 3;
					`
				: css`
						flex-direction: column;
						grid-row: span 2;
					`}
	}

	${mediaQuery.lg} {
		grid-row: span ${({ headerImage }) => (headerImage ? "3" : "2")};
		${({ isFullTeaser }) => isFullTeaser && "grid-column: 1 / 4;"}

		${({ isFullTeaser }) => addZoomHoverEffect(isFullTeaser ? 1.09 : 1.05)}
    ${addZoomElementHoverEffect()}
	}
`;

const BlogPostTile: React.FC<BlogPostTileProps> = ({
	routeName,
	slug,
	headerImage,
	publishingDate,
	title,
	tags,
	authors,
	isFullTeaser,
	isTeaserSection = false,
}) => {
	const locale = useLocale() as SiteLocale;
	const format = useFormatter();
	const routeNameHash = useMemo(() => cheapHash(routeName), [routeName]);
	const colorScheme = colorSchemes[routeNameHash % colorSchemes.length];
	const { margin } = dimensions.blog;
	const { masonry } = dimensions;
	const { px24, px32, px44 } = fonts.fontSize;

	const t = useTranslations();
	const date = format.dateTime(new Date(publishingDate), {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	});

	// if the blog post is not translated we link to the locale that is passed as default
	const localizedBlogRoute = getBlogPath(locale, routeName);

	const headerImageData = readCmsImageData(headerImage);

	if (!colorScheme) {
		return null;
	}

	return (
		<StyledLink
			href={localizedBlogRoute}
			key={slug}
			isFullTeaser={isFullTeaser}
			isTeaserSection={isTeaserSection}
			headerImage={Boolean(headerImageData)}
			className="group"
		>
			{headerImageData && (
				<ImageContainer
					fullTeaser={isFullTeaser}
					isTeaserSection={isTeaserSection}
					className={
						isFullTeaser ? "fullHeightImage" : "fullHeightLg"
					}
				>
					<CmsImage
						image={headerImageData}
						style={{ height: "100%" }}
						css={styleImage(isFullTeaser, isTeaserSection)}
					/>
				</ImageContainer>
			)}
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				width="100%"
				backgroundColor={colorScheme.backgroundColor}
				height={{
					xs: isFullTeaser || isTeaserSection ? "auto" : "100%",
					md: isFullTeaser ? "auto" : "100%",
				}}
				minHeight={{
					lg: headerImage
						? masonry.imageTile
						: masonry.basicTileInPX + masonry.marginInPX,
				}}
			>
				<Box
					width={{
						xs:
							headerImage && !isFullTeaser && !isTeaserSection
								? "65%"
								: "unset",
						md: headerImage && !isFullTeaser ? "65%" : "unset",
						lg: "auto",
					}}
					margin={{ xs: margin.mobile, lg: margin.default }}
					height={{ lg: "100%" }}
				>
					<TinyHeadline
						color={colorScheme.secondaryColor}
						css={styleTinyHeadline}
					>
						{authors.map((author) => `${author.name}, `)}
						{date}
					</TinyHeadline>
					<Heading
						as="h2"
						fontFamily={fonts.adelphi}
						color={colorScheme.color}
						margin="0"
						marginTop="5px"
						fontSize={[
							px24,
							px24,
							px24,
							isFullTeaser ? px44 : px32,
						]}
						fontWeight={fonts.fontWeight.bold}
						lineHeight={fonts.lineHeight.regular1}
						css={!isFullTeaser && styleTitle}
					>
						{title}
					</Heading>
					<Tags
						tags={stringListToArray(tags)}
						color={colorScheme.secondaryColor}
						margin="20px 0 0 0"
					/>
				</Box>
				<GoToBlogArticleWrapper color={colorScheme.color}>
					<TextAndAnimatedArrow
						text={t("goToBlogArticle")}
						buttonTextClassName={buttonTextClassName}
					/>
				</GoToBlogArticleWrapper>
			</Box>
		</StyledLink>
	);
};

export default BlogPostTile;
