import { cn } from "@peerigon/pupper/tailwind";
import type { ComponentProps } from "react";

type LoadMoreButtonProps = ComponentProps<"button">;

const LoadMoreButton: React.FC<LoadMoreButtonProps> = (props) => {
	const { className, ...restProps } = props;
	return (
		<button
			type="button"
			{...restProps}
			className={cn(
				"inline-block h-[4.8rem] w-1/2 border-none bg-blueberry/40 text-lg font-bold uppercase leading-regular-3 tracking-[0.2em] text-blueberry xl:h-[3.2rem]",
				restProps.disabled
					? "cursor-default opacity-50"
					: "cursor-pointer opacity-100",
				className,
			)}
		/>
	);
};

export default LoadMoreButton;
