import styled from "@emotion/styled";
import React, { memo } from "react";
import { CmsImageData } from "src/components/Image/utils";
import { CmsImage } from "../../Image/CmsImage";
import Title from "../../Title/Title";
import { Markdown } from "../MarkdownSection/MarkdownSection";

const TextWrapper = styled.div`
	margin-left: 33px;
`;

const SolutionTitleSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	> div {
		display: flex;
		align-items: center;
	}
`;

const Solution: React.FC<{
	icon: CmsImageData | null | undefined;
	title: string | null | undefined;
	text: string | null | undefined;
}> = ({ icon, title, text }) => {
	return (
		<>
			<SolutionTitleSection>
				<div>
					{icon && <CmsImage className="size-6" image={icon} />}
					<Title headingLevel="h5" margin="0 0 0 8px">
						{title}
					</Title>
				</div>
			</SolutionTitleSection>
			{text && (
				<TextWrapper>
					<Markdown source={text} />
				</TextWrapper>
			)}
		</>
	);
};

export default memo(Solution);
