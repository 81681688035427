// This file is autogenerated, please beware.
import { readFromEnum, isOfEnum, THROW } from "@peerigon/pupper/enums";
import { z } from "zod";

export const colorSchema = z.enum(["yellow","blue","green"]);

export const Color = colorSchema.Enum;

export const readAsColor = readFromEnum(colorSchema);

export const throwIfNotColor = readFromEnum(colorSchema, THROW);

export const isColor = isOfEnum(colorSchema);

export type ColorType = z.infer<typeof colorSchema>;

export const layoutSchema = z.enum(["col_1","col_1_narrow","col_2","col_2_narrow","col_2_sidebar","col_3"]);

export const Layout = layoutSchema.Enum;

export const readAsLayout = readFromEnum(layoutSchema);

export const throwIfNotLayout = readFromEnum(layoutSchema, THROW);

export const isLayout = isOfEnum(layoutSchema);

export type LayoutType = z.infer<typeof layoutSchema>;

export const navColorSchema = z.enum(["none","mint","blueberry","mintBlueberry"]);

export const NavColor = navColorSchema.Enum;

export const readAsNavColor = readFromEnum(navColorSchema);

export const throwIfNotNavColor = readFromEnum(navColorSchema, THROW);

export const isNavColor = isOfEnum(navColorSchema);

export type NavColorType = z.infer<typeof navColorSchema>;

export const spacingSchema = z.enum(["large","medium","small","xLarge","xSmall","xxLarge","xxSmall","xxxLarge","xxxSmall"]);

export const Spacing = spacingSchema.Enum;

export const readAsSpacing = readFromEnum(spacingSchema);

export const throwIfNotSpacing = readFromEnum(spacingSchema, THROW);

export const isSpacing = isOfEnum(spacingSchema);

export type SpacingType = z.infer<typeof spacingSchema>;

export const blogPostCategoriesSchema = z.enum(["case_studies","corporate_culture","tutorials","web_development","ui_ux","industry_insights"]);

export const BlogPostCategories = blogPostCategoriesSchema.Enum;

export const readAsBlogPostCategories = readFromEnum(blogPostCategoriesSchema);

export const throwIfNotBlogPostCategories = readFromEnum(blogPostCategoriesSchema, THROW);

export const isBlogPostCategories = isOfEnum(blogPostCategoriesSchema);

export type BlogPostCategoriesType = z.infer<typeof blogPostCategoriesSchema>;

export const colorThemeSchema = z.enum(["almostBlack","beige","black","blueberry","mint","moonraker","white","yellow","bluish","sea","peppermint","foam","ice"]);

export const ColorTheme = colorThemeSchema.Enum;

export const readAsColorTheme = readFromEnum(colorThemeSchema);

export const throwIfNotColorTheme = readFromEnum(colorThemeSchema, THROW);

export const isColorTheme = isOfEnum(colorThemeSchema);

export type ColorThemeType = z.infer<typeof colorThemeSchema>;

export const fontStyleSchema = z.enum(["bold","black","black_italic","thin","thin_italic"]);

export const FontStyle = fontStyleSchema.Enum;

export const readAsFontStyle = readFromEnum(fontStyleSchema);

export const throwIfNotFontStyle = readFromEnum(fontStyleSchema, THROW);

export const isFontStyle = isOfEnum(fontStyleSchema);

export type FontStyleType = z.infer<typeof fontStyleSchema>;

export const fontSizeSchema = z.enum(["lg","md","sm","xl"]);

export const FontSize = fontSizeSchema.Enum;

export const readAsFontSize = readFromEnum(fontSizeSchema);

export const throwIfNotFontSize = readFromEnum(fontSizeSchema, THROW);

export const isFontSize = isOfEnum(fontSizeSchema);

export type FontSizeType = z.infer<typeof fontSizeSchema>;

export const textAlignmentSchema = z.enum(["center","left","right"]);

export const TextAlignment = textAlignmentSchema.Enum;

export const readAsTextAlignment = readFromEnum(textAlignmentSchema);

export const throwIfNotTextAlignment = readFromEnum(textAlignmentSchema, THROW);

export const isTextAlignment = isOfEnum(textAlignmentSchema);

export type TextAlignmentType = z.infer<typeof textAlignmentSchema>;

export const gridSizingSchema = z.enum(["autoFill","autoFit"]);

export const GridSizing = gridSizingSchema.Enum;

export const readAsGridSizing = readFromEnum(gridSizingSchema);

export const throwIfNotGridSizing = readFromEnum(gridSizingSchema, THROW);

export const isGridSizing = isOfEnum(gridSizingSchema);

export type GridSizingType = z.infer<typeof gridSizingSchema>;

export const positionSchema = z.enum(["bottomCenter","bottomRight"]);

export const Position = positionSchema.Enum;

export const readAsPosition = readFromEnum(positionSchema);

export const throwIfNotPosition = readFromEnum(positionSchema, THROW);

export const isPosition = isOfEnum(positionSchema);

export type PositionType = z.infer<typeof positionSchema>;

export const bgColorSchema = z.enum(["yellow","blue","green"]);

export const BgColor = bgColorSchema.Enum;

export const readAsBgColor = readFromEnum(bgColorSchema);

export const throwIfNotBgColor = readFromEnum(bgColorSchema, THROW);

export const isBgColor = isOfEnum(bgColorSchema);

export type BgColorType = z.infer<typeof bgColorSchema>;

export const pageTypeSchema = z.enum(["about","general","legal","misc","service","workshop","technology","detailedCaseStudy","paidLandingPage"]);

export const PageType = pageTypeSchema.Enum;

export const readAsPageType = readFromEnum(pageTypeSchema);

export const throwIfNotPageType = readFromEnum(pageTypeSchema, THROW);

export const isPageType = isOfEnum(pageTypeSchema);

export type PageTypeType = z.infer<typeof pageTypeSchema>;

export const imageAnimationSchema = z.enum(["ALL_FadeIn","SVG_BuildUpStrokes"]);

export const ImageAnimation = imageAnimationSchema.Enum;

export const readAsImageAnimation = readFromEnum(imageAnimationSchema);

export const throwIfNotImageAnimation = readFromEnum(imageAnimationSchema, THROW);

export const isImageAnimation = isOfEnum(imageAnimationSchema);

export type ImageAnimationType = z.infer<typeof imageAnimationSchema>;