"use client";

import MobileNavigation from "src/components/Navigation/MobileNavigation";
import { useChangeLocale } from "src/components/Navigation/Navigation.hooks";
import NavigationWrapper from "src/components/Navigation/NavigationWrapper";
import { PageTypeType } from "src/enums";
import DesktopNavigation from "./DesktopNavigation";
import { getFragmentData, graphql } from "../../__generated__";
import type { FragmentType } from "../../__generated__";

export type NavLink = { id: string; routeName: string; label: string };
type SortedNavLinks = {
	mainNavLinks: Array<NavLink>;
	secondaryNavLinks: Array<NavLink>;
};

type NavigationProps = {
	data: FragmentType<typeof HeaderFragment>;
	initialMobileMenuVisible?: boolean;
	disableLanguageSwitch?: boolean;
	localizedPathname: Record<string, Record<string, string>>;
	currentPageType?: PageTypeType;
};

export const HeaderFragment = graphql(`
	fragment HeaderFields on HeaderRecord {
		items {
			... on HeaderItemRecord {
				id
				title
				isSecondary
				page {
					title
					shortTitle
					routeName
					icon {
						...ImageFields
						responsiveImage(imgixParams: { w: 30 }) {
							...ResponsiveImage
						}
					}
				}
				menuColumns {
					id
					title
					layout
					items {
						title
						description
						color
						icon {
							...ImageFields
							responsiveImage(imgixParams: { w: 30 }) {
								...ResponsiveImage
							}
						}
						page {
							title
							shortTitle
							routeName
							icon {
								...ImageFields
								responsiveImage(imgixParams: { w: 30 }) {
									...ResponsiveImage
								}
							}
						}
					}
				}
			}
		}
	}
`);

const Navigation: React.FC<NavigationProps> = ({
	data,
	initialMobileMenuVisible = false,
	disableLanguageSwitch,
	localizedPathname,
	currentPageType,
}) => {
	const dataFromFragment = getFragmentData(HeaderFragment, data);

	const { pathname, onChangeLocale } = useChangeLocale(localizedPathname);

	const { items } = dataFromFragment;

	const { mainNavLinks, secondaryNavLinks } = items.reduce<SortedNavLinks>(
		(acc, item) => {
			if (item.isSecondary) {
				acc.secondaryNavLinks.push({
					id: item.id,
					routeName: item.page?.routeName ?? "",
					label: item.title,
				});
			} else {
				acc.mainNavLinks.push({
					id: item.id,
					routeName: item.page?.routeName ?? "",
					label: item.title,
				});
			}
			return acc;
		},
		{ mainNavLinks: [], secondaryNavLinks: [] },
	);

	return (
		<NavigationWrapper>
			<DesktopNavigation
				mainNavLinks={mainNavLinks}
				secondaryNavLinks={secondaryNavLinks}
				items={items}
				pathname={pathname}
				disableLanguageSwitch={disableLanguageSwitch}
				currentPageType={currentPageType}
				onChangeLocale={onChangeLocale}
			/>

			<MobileNavigation
				mainNavLinks={mainNavLinks}
				secondaryNavLinks={secondaryNavLinks}
				items={items}
				pathname={pathname}
				initialMobileMenuVisible={initialMobileMenuVisible}
				disableLanguageSwitch={disableLanguageSwitch}
				currentPageType={currentPageType}
				onChangeLocale={onChangeLocale}
			/>
		</NavigationWrapper>
	);
};

export default Navigation;
