"use client";

import { cn } from "@peerigon/pupper/tailwind";
import React, { useEffect, useRef, useState } from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { buttonVariants } from "src/components/ButtonNew/Button";
import { CmsImage } from "src/components/Image/CmsImage";
import { readCmsImageData } from "src/components/Image/utils";
import IntroBlock from "src/components/IntroBlock/IntroBlock";
import Link from "src/components/Link/Link";

import { readAsSpacing } from "src/enums";
import { getColorTheme } from "../../../styles/variables";
import { ContentContainerTw } from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";
import type { LogoWallSectionItemFragment } from "src/__generated__/graphql";

const LogoWallSectionFieldsFragment = graphql(`
	fragment LogoWallSectionItem on LogoWallSectionRecord {
		__typename
		id
		logoWallTitle: title
		descriptionMd
		spacingTop
		spacingBottom
		colorTheme
		crossLinks {
			linkToPage {
				... on PageRecord {
					id
					routeName
				}
				... on BlogPostPageRecord {
					id
					routeName
				}
			}
			externalLink
			image {
				...ImageFields
				responsiveImage(
					imgixParams: { h: 200, fit: crop, auto: [format, compress] }
				) {
					...ResponsiveImage
				}
			}
		}
		action {
			... on CardActionRecord {
				id
				label
				url
			}
		}
	}
`);

export const LogoWallSection: React.FC<{
	section: FragmentType<typeof LogoWallSectionFieldsFragment>;
}> = ({ section }) => {
	const {
		logoWallTitle,
		descriptionMd,
		colorTheme,
		crossLinks,
		action,
		spacingTop,
		spacingBottom,
	} = getFragmentData(LogoWallSectionFieldsFragment, section);

	const [multiplier, setMultiplier] = useState(1);
	const [marqueeScrollWidth, setMarqueeScrollWidth] = useState(0);
	const marqueeRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const calculateWidth = () => {
			const scrollWidth = marqueeRef.current?.scrollWidth ?? 0;
			const innerWidth = window.innerWidth;
			const multiplier =
				scrollWidth === 0 ? 1 : Math.ceil(innerWidth / scrollWidth);
			setMultiplier(multiplier);
			setMarqueeScrollWidth(scrollWidth);
		};

		calculateWidth();
		marqueeRef.current?.querySelectorAll("img").forEach((img) => {
			img.addEventListener("load", () => {
				calculateWidth();
			});
		});
		const resizeObserver = new ResizeObserver(() => calculateWidth());
		if (marqueeRef.current) {
			resizeObserver.observe(marqueeRef.current);
		}
		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
			style={{
				"--color-theme-fg": colorTheme
					? getColorTheme(colorTheme).fg
					: "inherit",
				"--color-theme-bg": colorTheme
					? getColorTheme(colorTheme).bg
					: "inherit",
			}}
			className="bg-[var(--color-theme-bg)] text-[var(--color-theme-fg)]"
		>
			<div className="flex flex-col gap-10 lg:gap-20">
				<ContentContainerTw>
					<IntroBlock
						heading={logoWallTitle ?? ""}
						description={descriptionMd ?? undefined}
					>
						{action ? (
							<Link
								to={action.url}
								className={buttonVariants({ isLink: true })}
							>
								{action.label}
							</Link>
						) : null}
					</IntroBlock>
				</ContentContainerTw>

				<div
					className="group relative flex overflow-hidden py-2"
					style={{
						"--marquee-width": `-${marqueeScrollWidth}px`,
						"--marquee-multiplier-width": `${marqueeScrollWidth}px`,
						"--duration": `${marqueeScrollWidth / 100}s`,
					}}
				>
					<div
						ref={marqueeRef}
						className="flex h-px-200 animate-marquee gap-5 whitespace-nowrap px-2.5 lg:gap-20 lg:px-10 [&_.cms-image-wrapper]:contents"
					>
						<MarqueeImages crossLinks={crossLinks} />
					</div>
					<div
						className="absolute flex h-px-200 animate-marquee-multiplier gap-5 whitespace-nowrap px-2.5 lg:gap-20 lg:px-10 [&_.cms-image-wrapper]:contents"
						aria-hidden="true"
					>
						{Array.from({ length: multiplier }, (_, i) => (
							<MarqueeImages
								key={i}
								crossLinks={crossLinks}
								isMultiplier
							/>
						))}
					</div>
				</div>
			</div>
		</BaseSection>
	);
};

const MarqueeImages: React.FC<{
	crossLinks: LogoWallSectionItemFragment["crossLinks"];
	isMultiplier?: boolean;
}> = ({ crossLinks, isMultiplier = false }) => {
	return crossLinks
		.map(({ image, externalLink, linkToPage }) => {
			if (!image) return null;
			const usedLink = linkToPage?.routeName
				? linkToPage.routeName
				: externalLink;
			return {
				resolvedImage: readCmsImageData(image),
				usedLink,
			};
		})
		.filter(Boolean)
		.map(({ resolvedImage, usedLink }) => {
			if (usedLink) {
				return (
					<Link
						key={resolvedImage.url}
						title={resolvedImage.responsiveImage?.alt ?? ""}
						to={usedLink}
						className={cn(
							"h-full w-max disable-anchor-shadow-styles",
							"focus-visible:!outline-2 focus-visible:!outline-offset-4 focus-visible:!outline-black",
							"focus:outline-2 focus:outline-offset-4 focus:outline-black",
						)}
						tabIndex={isMultiplier ? -1 : 0}
					>
						<CmsImage
							image={resolvedImage}
							priority={true}
							className="h-full w-px-200 min-w-px-200 object-contain"
						/>
					</Link>
				);
			}
			return (
				<CmsImage
					key={resolvedImage.url}
					image={resolvedImage}
					priority={true}
					className="h-full w-px-200 min-w-px-200 object-contain"
				/>
			);
		});
};
