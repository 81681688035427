"use client";

import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { readAsSpacing } from "src/enums";
import PublicationList from "./PublicationList";
import Anchor from "../../Anchor/Anchor";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

export const PublicationListSectionFragment = graphql(`
	fragment PublicationListSectionItem on PublicationListSectionRecord {
		id
		anchor
		spacingTop
		spacingBottom
		publicationListElements {
			# TODO: order DESC 🥃
			id
			publisher
			publicationTitleAsLink
			publicationDate
		}
	}
`);

export const PublicationListSection: React.FC<{
	section: FragmentType<typeof PublicationListSectionFragment>;
}> = ({ section }) => {
	const { anchor, spacingTop, spacingBottom, publicationListElements } =
		getFragmentData(PublicationListSectionFragment, section);

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
		>
			<Anchor id={anchor} />
			<ContentContainer>
				<PublicationList publicationList={publicationListElements} />
			</ContentContainer>
		</BaseSection>
	);
};
