import { css } from "@emotion/react";
import { Field as FormikField, useFormikContext } from "formik";
import { useTranslations } from "next-intl";
import React from "react";
import ContactFormRow from "src/components/sections/ContactFormSection/ContactFormRow";
import { ContactFormCustomizationProps } from "./ContactFormSection";
import { Field } from "./Field";
import Button from "../../Button/Button";
import ButtonContainer from "../../Button/ButtonContainer";

type Props = {
	formName: string;
} & ContactFormCustomizationProps;

export const ContactFormUI: React.FC<Props> = ({
	formName,
	hideCompanyField,
	hidePhoneField,
	hideMessageField,
	hideNewsletterField,
	messagePlaceholder,
}) => {
	const t = useTranslations();
	const formik = useFormikContext();

	return (
		<form
			className="mt-px-40"
			name={formName}
			onSubmit={formik.handleSubmit}
		>
			<input type="hidden" name="form-name" value={formName} />

			<FormikField type="hidden" name="subject" />
			<FormikField type="hidden" name="page" />
			<FormikField type="hidden" name="referrerUrl" />

			<ContactFormRow>
				<Field
					label={t("contactFormLabelName")}
					name="name"
					isRequired={true}
				/>
			</ContactFormRow>
			<ContactFormRow>
				<Field
					label={t("contactFormLabelEmail")}
					name="email"
					isRequired={true}
				/>
				{hidePhoneField ? null : (
					<Field label={t("contactFormLabelPhone")} name="phone" />
				)}
			</ContactFormRow>
			{!hideCompanyField && (
				<ContactFormRow>
					<Field
						label={t("contactFormLabelCompany")}
						name="company"
					/>
				</ContactFormRow>
			)}
			{hideMessageField ? null : (
				<ContactFormRow className="mt-px-20">
					<Field
						type="textarea"
						ariaLabel={t("contactFormLabelMessage")}
						label={
							messagePlaceholder ??
							t("contactFormMessagePlaceholder")
						}
						name="message"
						isRequired={true}
					/>
				</ContactFormRow>
			)}
			<ContactFormRow className="mt-px-20">
				<Field
					type="checkbox"
					label={t.rich("contactFormLabelDataPrivacy", {
						link: () => (
							<a
								href={t("contactFormLabelDataPrivacyUrl")}
								target="_blank"
								rel="noreferrer"
							>
								{t("contactFormLabelDataPrivacyLabel")}
							</a>
						),
					})}
					name="dataPrivacy"
					isRequired={true}
				/>
			</ContactFormRow>
			{hideNewsletterField ? null : (
				<ContactFormRow>
					<Field
						type="checkbox"
						label={t("contactFormNewsletterLabel")}
						name="newsletter"
					/>
				</ContactFormRow>
			)}
			<div className="hidden">
				<Field label={t("contactFormLabelBot")} name="url" />
				<Field type="checkbox" name="isWorkshopForm" />
			</div>
			<ButtonContainer
				align="center"
				alignSm={
					hidePhoneField && hideMessageField ? "center" : "right"
				}
				css={css`
					grid-column: 1 / -1;
				`}
			>
				<Button
					type="submit"
					isLoading={formik.isSubmitting}
					isDisabled={!formik.isValid}
				>
					{t("contactFormSubmit")}
				</Button>
			</ButtonContainer>
		</form>
	);
};
