import { cn } from "@peerigon/pupper/tailwind";
import { Markdown } from "src/components/sections/MarkdownSection/MarkdownSection";

type AgendaEntryProps = {
	label?: string;
	description: string;
	isInactive?: boolean;
};

const AgendaEntry: React.FC<AgendaEntryProps> = ({
	label,
	description,
	isInactive,
}) => {
	return (
		<div
			className={cn(
				"grid grid-cols-1 items-center gap-3 rounded-lg border border-moonRaker bg-ice px-6 py-5",
				{
					"opacity-20": isInactive,
					"md:grid-cols-[minmax(20rem,_30%)_1fr]": Boolean(label),
				},
			)}
		>
			{label ? (
				<div className="text-xl font-medium leading-larger">
					{label}
				</div>
			) : null}

			<div className="[&_div]:last-of-type:m-0 [&_p]:text-base [&_p]:font-regular [&_p]:leading-larger">
				<Markdown source={description} />
			</div>
		</div>
	);
};

export default AgendaEntry;
