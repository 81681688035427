"use client";

import { css } from "@emotion/react";
import dynamic from "next/dynamic";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { readCmsImageData } from "src/components/Image/utils";

import { readAsSpacing } from "src/enums";
import AnimatedImagesContainer from "./AnimatedImagesContainer";
import TitleDescription from "../../TitleDescription/TitleDescription";
import BaseSection from "../BaseSection";

const AnimatedImage = dynamic(async () => import("./AnimatedImage"), {
	ssr: false,
});

const styleBaseSectionFlex = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const ImageCloudSectionFieldsFragment = graphql(`
	fragment ImageCloudSectionItem on ImageCloudSectionRecord {
		__typename
		id
		spacingTop
		spacingBottom
		title
		cloudDescription: descriptionMd
		images {
			...ImageFields
			responsiveImage(imgixParams: { w: 768 }) {
				# 🥃 TODO: find correct image dimensions
				...ResponsiveImage
			}
		}
	}
`);

export const ImageCloudSection: React.FC<{
	section: FragmentType<typeof ImageCloudSectionFieldsFragment>;
}> = ({ section }) => {
	const { title, images, spacingTop, spacingBottom, cloudDescription } =
		getFragmentData(ImageCloudSectionFieldsFragment, section);

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
			css={styleBaseSectionFlex}
		>
			<TitleDescription title={title} description={cloudDescription} />
			<AnimatedImagesContainer>
				{images.map((image, index) => {
					const resolvedImage = readCmsImageData(image);
					return (
						<AnimatedImage
							image={resolvedImage}
							index={index}
							key={resolvedImage.url}
						/>
					);
				})}
			</AnimatedImagesContainer>
		</BaseSection>
	);
};
