import { getFragmentData, graphql } from "src/__generated__";
import { CmsImage } from "src/components/Image/CmsImage";
import { readCmsImageData } from "src/components/Image/utils";
import Link from "src/components/Link/Link";
import { dimensions } from "src/styles/variables";
import type { FragmentType } from "src/__generated__";

const FooterLabelFieldsFragment = graphql(`
	fragment FooterLabelFields on FooterRecord {
		labels {
			image {
				...ImageFields
				responsiveImage(imgixParams: { w: 768 }) {
					# 🥃 TODO: find correct image dimensions
					...ResponsiveImage
				}
			}
			externalLink
			destinationText
			linkToPage {
				... on PageRecord {
					routeName
				}
				... on BlogPostPageRecord {
					routeName
				}
			}
		}
	}
`);

const Labels: React.FC<{
	footer: FragmentType<typeof FooterLabelFieldsFragment>;
}> = ({ footer }) => {
	const { labels } = getFragmentData(FooterLabelFieldsFragment, footer);
	return (
		<div className="flex flex-row items-center justify-between gap-2 md:justify-end md:gap-10">
			{labels.map(
				({
					image: imageFragment,
					linkToPage,
					externalLink,
					destinationText,
				}) => {
					const image = readCmsImageData(imageFragment);
					if (!image) return null;

					const link =
						externalLink != null || linkToPage != null
							? (linkToPage?.routeName ?? externalLink)
							: undefined;

					return link ? (
						<Link
							key={image.url}
							to={link}
							className="disable-anchor-shadow-styles"
						>
							<CmsImage
								image={image}
								objectFit="contain"
								style={{
									maxWidth: dimensions.spacing.px80,
									height: dimensions.spacing.px60,
								}}
							/>
							{destinationText && (
								<span className="sr-only">
									{destinationText}
								</span>
							)}
						</Link>
					) : (
						<CmsImage
							key={image.url}
							image={image}
							objectFit="contain"
							style={{
								maxWidth: dimensions.spacing.px80,
								height: dimensions.spacing.px60,
							}}
						/>
					);
				},
			)}
		</div>
	);
};

export default Labels;
