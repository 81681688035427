"use client";

import { SimpleGrid } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { readAsSpacing } from "src/enums";
import ServicesCard from "./ServicesCard";
import { dimensions } from "../../../styles/variables";
import TitleDescription from "../../TitleDescription/TitleDescription";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";
import { CrossLinkElementFieldsFragment } from "../CrossLinkSection/CrossLink";

const styleBaseSectionFlex = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const ServicesSectionFragment = graphql(`
	fragment ServicesSectionItem on ServicesSectionRecord {
		__typename
		id
		introText
		spacingTop
		spacingBottom
		sectionTitle: title
		cards: crossLinks {
			...CrossLinkElementFields
			image {
				...ImageFields
				responsiveImage(imgixParams: { w: 768 }) {
					# 🥃 TODO: find correct image dimensions
					...ResponsiveImage
				}
			}
		}
	}
`);

export const ServicesSection: React.FC<{
	section: FragmentType<typeof ServicesSectionFragment>;
}> = ({ section }) => {
	const { spacingTop, spacingBottom, sectionTitle, introText, cards } =
		getFragmentData(ServicesSectionFragment, section);

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
		>
			<div css={styleBaseSectionFlex}>
				<ContentContainer>
					<TitleDescription
						title={sectionTitle}
						description={introText}
					/>
					<SimpleGrid
						columns={[1, 1, 2]}
						gridRowGap={0}
						paddingTop={[
							dimensions.spacing.px30,
							dimensions.spacing.px40,
							dimensions.spacing.px80,
						]}
					>
						{cards.map((card) => {
							const resolvedCard = getFragmentData(
								CrossLinkElementFieldsFragment,
								card,
							);

							return (
								<ServicesCard
									key={resolvedCard.id}
									card={resolvedCard}
								/>
							);
						})}
					</SimpleGrid>
				</ContentContainer>
			</div>
		</BaseSection>
	);
};
