"use client";

import React, { useCallback, useEffect, useState } from "react";
import { preconnect } from "react-dom";
import { useMedia } from "react-use";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { readAsSpacing } from "src/enums";
import { ApeeriEvent } from "src/lib/apeeri";
import { EventType } from "./Event";
import EventList from "./EventList";
import dayjs from "../../../dayjs";
import { breakpoints } from "../../../styles/variables";
import LeafletMap from "../../LeafletMap/LeafletMap";
import TitleDescription from "../../TitleDescription/TitleDescription";
import BaseSection from "../BaseSection";

export const MapSectionFragment = graphql(`
	fragment MapSectionItem on MapSectionRecord {
		id
		title
		sectionDescription
		spacingTop
		spacingBottom
	}
`);

export const PEERIGON_LAT_LON = { lat: 48.342852, lon: 10.905494 };

const useEvents = ({
	initialEvents,
	fetchEvents,
}: {
	initialEvents: Array<ApeeriEvent>;
	fetchEvents: () => Promise<Array<ApeeriEvent>>;
}) => {
	const [loading, setLoading] = useState(false);
	const [events, setEvents] = useState<Array<EventType>>(initialEvents);

	useEffect(() => {
		setLoading(true);
		fetchEvents()
			.then((events) => {
				const newEvents = [...events].sort((a, b) => {
					return (
						new Date(b.data.start).getTime() -
						new Date(a.data.start).getTime()
					);
				});

				setEvents(newEvents);

				setLoading(false);
			})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.warn("Could not load events from apeeri:", error);

				setEvents([]);
				setLoading(false);
			});
	}, [fetchEvents]);

	return {
		events,
		loading,
	};
};

const getInitialPageNumber = (events: Array<EventType>, pageSize: number) => {
	const now = dayjs();

	// create an array with all events starting date difference to now (= today)
	const eventsTimeFromNow = events.map((event) => {
		return dayjs(event.data.start).diff(now);
	});

	// skip rest if no events found
	if (eventsTimeFromNow.length <= 0) {
		return 0;
	}

	// retrieve the smallest positive time difference
	const nearestPointInFuture = Math.min(
		...eventsTimeFromNow.filter((n) => n >= 0),
	);

	// find index of the nearest event to calculate pageNumber
	const nearestEventsIndex = eventsTimeFromNow.indexOf(nearestPointInFuture);
	// round down
	const pageNumber = Math.floor(nearestEventsIndex / pageSize);

	// return 0 if pageNumber is negative
	return Math.max(0, pageNumber);
};

export const MapSection: React.FC<{
	graphQlEndpoint?: string;
	fetchEvents: () => Promise<Array<ApeeriEvent>>;
	accessToken: string;
	events: Array<ApeeriEvent>;
	section: FragmentType<typeof MapSectionFragment>;
}> = ({
	graphQlEndpoint,
	fetchEvents,
	accessToken,
	events: initialEvents,
	section,
}) => {
	const data = getFragmentData(MapSectionFragment, section);
	const isWide = useMedia(`(min-width: ${breakpoints.xl}px)`, false);
	const { events, loading } = useEvents({ initialEvents, fetchEvents });

	const [page, setPage] = useState(0);

	const pageSize = isWide ? 4 : 2;

	const pageEvents = events.slice(
		page * pageSize,
		page * pageSize + pageSize,
	);

	const [selectedEvent, setSelectedEvent] = useState<EventType | undefined>(
		undefined,
	);

	const handleSelectEvent = useCallback(
		(event: EventType | undefined) => {
			setSelectedEvent(event);
		},
		[setSelectedEvent],
	);

	useEffect(() => {
		const initialPage = getInitialPageNumber(events, pageSize);

		setPage(initialPage);
	}, [events, pageSize]);

	const handleChangePage = useCallback((page: number) => {
		setPage(page);
		setSelectedEvent(undefined);
	}, []);

	graphQlEndpoint &&
		preconnect(`https://${new URL(graphQlEndpoint).hostname}`, {
			crossOrigin: "anonymous",
		});
	preconnect("https://api.mapbox.com");

	return (
		<>
			<BaseSection
				paddingTop={readAsSpacing(data.spacingTop)}
				paddingBottom={readAsSpacing(data.spacingBottom)}
			>
				<TitleDescription
					title={data.title}
					description={data.sectionDescription}
				/>
				<div className="grid grid-cols-[repeat(4,_0)_repeat(4,_1fr)_repeat(4,_0)] gap-x-px-10 sm:grid-cols-12 sm:gap-x-px-20">
					<div className="chromatic-ignore col-span-blog-full mb-px-30 h-[500px]">
						<LeafletMap
							accessToken={accessToken}
							coordinates={PEERIGON_LAT_LON}
							events={events}
							pageEvents={pageEvents}
							selectedEvent={selectedEvent}
							placeholder={<div className="h-[500px] w-full" />}
							className="isolate h-[500px]"
						/>
					</div>
					<div className="z-default col-span-blog-full m-[-120px_0_30px_0] w-full lg:col-span-blog-center">
						<EventList
							onPreviousPage={() => handleChangePage(page + 1)}
							onNextPage={() =>
								handleChangePage(Math.max(0, page - 1))
							}
							events={pageEvents}
							loading={loading}
							selectedEvent={selectedEvent}
							onSelectEvent={handleSelectEvent}
							canPreviousPage={
								Math.floor(events.length / pageSize) - 1 > page
							}
							canNextPage={page > 0}
						/>
					</div>
				</div>
			</BaseSection>
		</>
	);
};
