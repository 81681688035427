"use client";

import { css } from "@emotion/react";
import { cn } from "@peerigon/pupper/tailwind";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { readCmsImageData } from "src/components/Image/utils";
import PhotographHeader from "./PhotographHeader";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions, heights } from "../../../styles/variables";
import Title from "../../Title/Title";
import ContentContainer from "../../styled/ContentContainer";
import HeadingAddOn from "../../styled/HeadingAddOn";
import { Markdown } from "../MarkdownSection/MarkdownSection";

const styleIntroText = css`
	text-align: center;
	margin: ${dimensions.spacing.px30} auto 0 auto;

	${mediaQuery.lg} {
		width: 80%;
	}
`;

export const PageHeaderPhotoSectionFragment = graphql(`
	fragment PageHeaderPhotoSectionItem on PageHeaderPhotoSectionRecord {
		id
		text
		headingAddOn
		heading
		opacity
		logo {
			...ImageFields
			responsiveImage(
				imgixParams: {
					w: 190
					h: 190
					fit: crop
					auto: [format, compress]
				}
			) {
				...ResponsiveImage
			}
		}
		photo {
			...ImageFields
			responsiveImage(
				imgixParams: { w: 2160, fit: crop, auto: [format, compress] }
			) {
				...ResponsiveImage
			}
		}
	}
`);

export const PageHeaderPhotoSection: React.FC<{
	section: FragmentType<typeof PageHeaderPhotoSectionFragment>;
}> = ({ section }) => {
	const { headingAddOn, heading, text, photo, logo, opacity } =
		getFragmentData(PageHeaderPhotoSectionFragment, section);
	const { mobileInPx, desktopInPx } = heights.pageHeaderSection;

	return (
		<section className="overflow-hidden">
			{photo && (
				<PhotographHeader
					photo={readCmsImageData(photo)}
					logo={readCmsImageData(logo)}
					opacity={opacity}
				/>
			)}

			<ContentContainer>
				<div
					style={{
						"--mobileInPx": mobileInPx + "px",
						"--desktopInPx": desktopInPx + "px",
					}}
					className={cn(
						"flex items-center",
						"w-4/5",
						"h-[var(--mobileInPx)] md:h-[var(--desktopInPx)]",
					)}
				>
					<div className="w-max">
						<Title headingLevel="h1" color="white">
							{headingAddOn && (
								<HeadingAddOn
									addOn={headingAddOn}
									color="white"
								/>
							)}
							{heading}
						</Title>
					</div>
				</div>
				{text && <Markdown source={text} css={styleIntroText} />}
			</ContentContainer>
		</section>
	);
};
