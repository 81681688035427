import { Box, Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import NextImage from "next/image";
import React from "react";
import { CmsImage } from "src/components/Image/CmsImage";
import { getUrlFromHandle } from "./rewrites";
import { dimensions } from "../../../styles/variables";
import { GridCenteredElement } from "../../styled/Grid";

const styleImage = (isInline = false) => css`
	position: relative;
	min-height: 500px;
	margin-top: ${dimensions.blog.margin.default};
	> img {
		width: 100%;
		height: auto;
	}

	${isInline &&
	css`
		text-align: center;

		> img {
			display: inline-block;
			width: auto;
			margin: auto;
		}
	`}
`;

const setCmsImageProps = (src: string, alt: string) => {
	if (src.includes("http")) {
		return null;
	}

	// if the image is not coming from the world wide web
	// we extract the handle, width and height from the src
	const imgSettings = src.split(",");
	const handle = imgSettings[0];
	const width = imgSettings[1];
	const height = imgSettings[2];

	if (!handle) {
		throw new Error("Image handle is missing");
	}

	const url = getUrlFromHandle(handle);

	return {
		__typename: "FileField",
		handle,
		url,
		width: width ? parseInt(width) : 800,
		height: height ? parseInt(height) : null,
		alt,
		mimeType: "",
		responsiveImage: null,
	} as const;
};

const Image = (src: string, alt: string) => {
	const isInline = src.endsWith("#inline");
	const cmsImg = setCmsImageProps(src, alt);

	if (cmsImg) {
		return (
			<GridCenteredElement>
				<Flex
					justify="center"
					marginTop={dimensions.blog.margin.default}
				>
					<Box width={cmsImg.width + "px"} height="auto">
						<CmsImage image={cmsImg} alt={alt} className="m-auto" />
					</Box>
				</Flex>
			</GridCenteredElement>
		);
	}

	// Stupid simple fix for some seemingly hardcoded asset links within our Markdown content fields?
	const migratedSrc = src.replace(
		"media.graphcms.com",
		"media.graphassets.com",
	);

	if (migratedSrc.includes("graphassets.com")) {
		// eslint-disable-next-line no-console
		console.warn(
			// Copilot wrote this warning and I'm kind of proud of it
			"Image src contains 'graphassets.com' - this is likely a hardcoded asset link in the Markdown content field. Please migrate this to a GraphCMS asset.",
		);
	}

	return (
		<GridCenteredElement css={styleImage(isInline)}>
			<NextImage src={migratedSrc} fill alt={alt} loading="lazy" />
		</GridCenteredElement>
	);
};

export default Image;
