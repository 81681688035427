// See https://github.com/chakra-ui/chakra-ui/issues/7878
// import { SpinnerIcon } from "@chakra-ui/icons";
import { useTheme } from "@chakra-ui/react";
import { darken } from "@chakra-ui/theme-tools";
import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { tw } from "@peerigon/pupper/tailwind";
import React, { useMemo } from "react";
import { disableAnchorShadowStyles, mediaQuery } from "../../styles/mixins";
import { animations, colors, fonts, transitions } from "../../styles/variables";

type ButtonProps = React.ButtonHTMLAttributes<
	HTMLButtonElement | HTMLAnchorElement
> & {
	backgroundColor?: string;
	backgroundColorHover?: string;
	isAnimated?: boolean;
	isLoading?: boolean;
	isDisabled?: boolean;
	as?: (props: any) => JSX.Element;
};

const DEFAULT_BG_COLOR = colors.blueberry;

// NOTE: These are the same styles as `buttonTextStyles` below, we will switch out the rest of the usages in future PRs
export const buttonTextClassName = tw`text-px-16 font-bold uppercase leading-medium tracking-button lg:text-px-20`;

const buttonTextStyles = css`
	font-size: ${fonts.fontSize.px16};
	font-weight: ${fonts.fontWeight.bold};
	line-height: ${fonts.lineHeight.medium};
	letter-spacing: ${fonts.letterSpacing.button};
	text-transform: uppercase;
	${mediaQuery.lg} {
		font-size: ${fonts.fontSize.px20};
	}
`;

const Label = styled.span`
	position: relative;
	z-index: 2;
`;

const buttonBaseStyles = (backgroundColor?: string) => css`
	position: relative;
	background-color: ${backgroundColor ?? DEFAULT_BG_COLOR}!important;
	border: none;
	text-align: center;
	display: inline-block;
	padding: 10px 20px 8px 20px;
	transition: background-color ${transitions.regular};
	max-width: 100%;
	color: ${colors.white};

	${animations.keyframes.buzzOut};
`;

const hoverStyles = (isAnimated = false, backgroundColorHover?: string) => {
	return css`
		&:hover {
			background-color: ${backgroundColorHover}!important;

			${isAnimated &&
			css`
				animation: buzzOut 0.75s linear 0s 1;
				text-decoration: none;
			`};
		}
	`;
};

const disabledStyles = css`
	background-color: ${colors.gray.line} !important;
	color: ${colors.white};
	cursor: not-allowed !important;

	&:hover {
		background-color: ${colors.gray.text} !important;
	}
`;

// FIXME: nextwww
// const LoadingIndicator = styled(SpinnerIcon)`
const LoadingIndicator = styled.span`
	display: none;
	position: absolute;
	width: 1em;
	height: 1em;
	left: 50%;
	top: 50%;
	color: ${colors.white};
	margin-top: -0.5em;
	margin-left: -0.5em;
`;

const loadingStyles = css`
	cursor: progress !important;
	color: transparent;
	${animations.keyframes.rotate};

	${LoadingIndicator} {
		display: block;
		animation: rotate 0.5s linear infinite;
	}
`;

const StyledButton = styled("button", {
	shouldForwardProp: isPropValid,
})<ButtonProps>`
	${buttonTextStyles}
	${({ backgroundColor }) => buttonBaseStyles(backgroundColor)}
  ${({ isAnimated, backgroundColorHover }) =>
		hoverStyles(isAnimated, backgroundColorHover)}
  ${({ isDisabled }) => isDisabled && disabledStyles}
  ${({ isLoading }) => isLoading && loadingStyles}
  ${disableAnchorShadowStyles}
`;

// eslint-disable-next-line react/display-name
const Button: React.FC<ButtonProps> = React.forwardRef<
	HTMLButtonElement,
	ButtonProps
>(({ children, backgroundColor, backgroundColorHover, ...props }, ref) => {
	const theme = useTheme();

	const bgColor = useMemo(
		() =>
			backgroundColorHover ??
			darken(backgroundColor ?? DEFAULT_BG_COLOR, 7)(theme),
		[backgroundColor, backgroundColorHover, theme],
	);

	return (
		<StyledButton
			backgroundColorHover={bgColor}
			backgroundColor={backgroundColor}
			{...props}
			ref={ref}
		>
			<LoadingIndicator />
			<Label>{children}</Label>
		</StyledButton>
	);
});

export default Button;
