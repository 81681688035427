"use client";

import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { useImageGalleryScroll } from "src/components/sections/FourImagesSection/FourImagesSection.hooks";

import { readAsSpacing } from "src/enums";
import { getColorTheme } from "../../../styles/variables";
import { CmsImage } from "../../Image/CmsImage";
import { readCmsImageData } from "../../Image/utils";
import BaseSection from "../BaseSection";
import type { FourImagesSectionItemFragment } from "src/__generated__/graphql";

export const FourImagesSectionFragment = graphql(`
	fragment FourImagesSectionItem on FourImagesSectionRecord {
		__typename
		id
		spacingTop
		spacingBottom
		upperLandscapeImage {
			...ImageFields
			responsiveImage(
				imgixParams: { h: 1080, fit: crop, auto: [format, compress] }
			) {
				...ResponsiveImage
			}
		}
		upperPortraitImage {
			...ImageFields
			responsiveImage(
				imgixParams: { h: 1080, fit: crop, auto: [format, compress] }
			) {
				...ResponsiveImage
			}
		}
		lowerPortraitImage {
			...ImageFields
			responsiveImage(
				imgixParams: { h: 1080, fit: crop, auto: [format, compress] }
			) {
				...ResponsiveImage
			}
		}
		lowerLandscapeImage {
			...ImageFields
			responsiveImage(
				imgixParams: { h: 1080, fit: crop, auto: [format, compress] }
			) {
				...ResponsiveImage
			}
		}
		galleryColorTheme: colorTheme
	}
`);

export const FourImagesSection: React.FC<{
	section: FragmentType<typeof FourImagesSectionFragment>;
}> = ({ section }) => {
	const {
		spacingTop,
		spacingBottom,
		upperLandscapeImage,
		upperPortraitImage,
		lowerLandscapeImage,
		lowerPortraitImage,
		galleryColorTheme,
	} = getFragmentData(FourImagesSectionFragment, section);

	const { marqueeRef, multiplier, marqueeScrollWidth } =
		useImageGalleryScroll();

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
			style={{
				"--color-theme-fg": galleryColorTheme
					? getColorTheme(galleryColorTheme).fg
					: "inherit",
				"--color-theme-bg": galleryColorTheme
					? getColorTheme(galleryColorTheme).bg
					: "inherit",
			}}
			className="bg-[var(--color-theme-bg)] text-[var(--color-theme-fg)]"
		>
			<div
				className="group relative flex overflow-hidden"
				style={{
					"--marquee-width": `-${marqueeScrollWidth}px`,
					"--marquee-multiplier-width": `${marqueeScrollWidth}px`,
					"--duration": `${marqueeScrollWidth / 100}s`,
				}}
			>
				<div
					ref={marqueeRef}
					className="flex h-[60vh] animate-marquee gap-5 whitespace-nowrap px-2.5 group-hover:paused [&_.cms-image-wrapper]:contents"
				>
					<MarqueeImages
						upperLandscapeImage={upperLandscapeImage}
						upperPortraitImage={upperPortraitImage}
						lowerLandscapeImage={lowerLandscapeImage}
						lowerPortraitImage={lowerPortraitImage}
					/>
				</div>
				<div
					className="absolute flex h-[60vh] animate-marquee-multiplier gap-5 whitespace-nowrap px-2.5 group-hover:paused [&_.cms-image-wrapper]:contents"
					aria-hidden="true"
				>
					{Array.from({ length: multiplier }, (_, i) => (
						<MarqueeImages
							key={i}
							upperLandscapeImage={upperLandscapeImage}
							upperPortraitImage={upperPortraitImage}
							lowerLandscapeImage={lowerLandscapeImage}
							lowerPortraitImage={lowerPortraitImage}
						/>
					))}
				</div>
			</div>
		</BaseSection>
	);
};

const MarqueeImages: React.FC<{
	upperLandscapeImage: FourImagesSectionItemFragment["upperLandscapeImage"];
	upperPortraitImage: FourImagesSectionItemFragment["upperPortraitImage"];
	lowerLandscapeImage: FourImagesSectionItemFragment["lowerLandscapeImage"];
	lowerPortraitImage: FourImagesSectionItemFragment["lowerPortraitImage"];
}> = ({
	upperLandscapeImage,
	upperPortraitImage,
	lowerLandscapeImage,
	lowerPortraitImage,
}) => {
	const resolvedUpperLandscapeImage = readCmsImageData(upperLandscapeImage);
	const resolvedUpperPortraitImage = readCmsImageData(upperPortraitImage);
	const resolvedLowerLandscapeImage = readCmsImageData(lowerLandscapeImage);
	const resolvedLowerPortraitImage = readCmsImageData(lowerPortraitImage);

	return (
		<>
			<CmsImage
				image={resolvedUpperLandscapeImage}
				className="h-[60vh] w-[80vw] rounded-xl border-2 border-red-500"
				objectFit="cover"
			/>
			<CmsImage
				image={resolvedUpperPortraitImage}
				className="h-[60vh] w-[80vw] rounded-xl"
				objectFit="cover"
			/>
			<CmsImage
				image={resolvedLowerPortraitImage}
				className="h-[60vh] w-[80vw] rounded-xl"
				objectFit="cover"
			/>
			<CmsImage
				image={resolvedLowerLandscapeImage}
				className="h-[60vh] w-[80vw] rounded-xl"
				objectFit="cover"
			/>
		</>
	);
};
