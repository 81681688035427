"use client";
import { cn } from "@peerigon/pupper/tailwind";
import NextImage from "next/image";
import { ComponentProps } from "react";
import { SRCImage } from "react-datocms";
import { graphql } from "src/__generated__";
import { CmsImageData } from "./utils";

graphql(`
	fragment ImageFields on FileField {
		__typename
		url
		mimeType
		width
		height
		alt
		mimeType
	}

	fragment ResponsiveImage on ResponsiveImage {
		__typename
		src
		srcSet
		width
		height
		alt
		title
		sizes
	}
`);

export type CmsImageProps = {
	objectFit?: "contain" | "cover";
	className?: string;
	alt?: string;
	style?: React.CSSProperties;
	title?: string;
	role?: ComponentProps<"img">["role"];
	priority?: boolean;
	sizes?: string;
	image: CmsImageData;
};

export const CmsImage: React.FC<CmsImageProps> = ({
	image,
	objectFit,
	className: classNameProp,
	alt,
	priority = false,
	style,
	sizes,
	...props
}) => {
	const className = cn("block h-full w-full max-w-none", classNameProp);

	const coverStyles = objectFit === "cover" ? { height: "100%" } : {};

	const imgStyle = {
		objectFit,
		...{ maxWidth: "100% !important" },
		...coverStyles,
		...style,
	};

	if (!image.responsiveImage) {
		const isSvg = image.mimeType.startsWith("image/svg");

		if (!isSvg) {
			// eslint-disable-next-line no-console
			console.warn(
				`Image ${image.url} does not have a responsive image. Why?`,
			);
		}

		return (
			<NextImage
				width={image.width ?? 0}
				height={image.height ?? 0}
				src={image.url}
				alt={alt ?? image.alt ?? ""}
				className={className}
				style={imgStyle}
				priority={priority}
				sizes={sizes}
				{...props}
			/>
		);
	}

	image.responsiveImage.alt = alt ?? image.alt ?? image.responsiveImage.alt;

	return (
		<SRCImage
			data={image.responsiveImage}
			imgStyle={imgStyle}
			pictureClassName="cms-image-wrapper"
			imgClassName={className}
			priority={priority}
			sizes={sizes ?? image.responsiveImage.sizes ?? undefined}
			{...props}
		/>
	);
};
