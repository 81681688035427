import { Box } from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import React from "react";
import { Card, Illu, textStyles } from "./ServicesCardContainer";
import { dimensions } from "../../../styles/variables";
import { CmsImage } from "../../Image/CmsImage";
import { readCmsImageData } from "../../Image/utils";
import TextAndAnimatedArrow from "../../TextAndAnimatedArrow/TextAndAnimatedArrow";
import Title from "../../Title/Title";
import { useOneOffWhenVisible } from "../../helpers";
import { CrossLinkElementFieldsFragment } from "../CrossLinkSection/CrossLink";
import { Markdown } from "../MarkdownSection/MarkdownSection";
import type { ResultOf } from "@graphql-typed-document-node/core";

const ServicesCard: React.FC<{
	card: ResultOf<typeof CrossLinkElementFieldsFragment>;
}> = ({ card }) => {
	const [ref, visible] = useOneOffWhenVisible();
	const t = useTranslations();

	const { destinationText, description, image, linkToPage } = card;

	const resolvedImage = readCmsImageData(image);

	return (
		<Card
			to={linkToPage?.routeName ?? "/"}
			isHidden={!visible}
			className="group"
		>
			<div ref={ref} />
			{resolvedImage && (
				<Illu>
					<CmsImage
						image={resolvedImage}
						style={{ width: resolvedImage.width ?? "100%" }}
					/>
				</Illu>
			)}

			<Title
				headingLevel="h3"
				textAlign="center"
				margin={`0 0 ${dimensions.spacing.px30} 0`}
			>
				{destinationText}
			</Title>

			{description && <Markdown source={description} css={textStyles} />}

			<Box paddingTop="10px" marginLeft="30px">
				<TextAndAnimatedArrow
					text={t("findOutMore")}
					alignSelf="center"
				/>
			</Box>
		</Card>
	);
};

export default ServicesCard;
