import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useState } from "react";
import CardCarousel from "src/components/Card/CardCarousel";
import { CmsImageData } from "src/components/Image/utils";

type CardCarouselContainerProps = {
	images: Array<CmsImageData>;
};

const CardCarouselContainer: React.FC<CardCarouselContainerProps> = ({
	images,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [startIndex, setStartIndex] = useState(0);

	const handleUpdateStartIndex = (startIndex: number) => {
		setStartIndex(startIndex);
	};

	return (
		<div className="h-full w-full">
			<CardCarousel
				images={images}
				startIndex={startIndex}
				onUpdateStartIndex={handleUpdateStartIndex}
				onMaximize={() => setIsOpen(true)}
			/>

			<Dialog
				open={isOpen}
				onClose={() => setIsOpen(false)}
				className="relative z-50 hidden md:block"
			>
				<DialogBackdrop className="fixed inset-0 bg-black/50" />
				<div className="fixed inset-0 flex w-screen items-center justify-center">
					<DialogPanel>
						<CardCarousel
							images={images}
							isMaximized={true}
							startIndex={startIndex}
							onUpdateStartIndex={handleUpdateStartIndex}
							onMaximize={() => setIsOpen(false)}
						/>
					</DialogPanel>
				</div>
			</Dialog>
		</div>
	);
};

export default CardCarouselContainer;
