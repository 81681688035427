"use client";

import React from "react";
import ReactMarkdown from "react-markdown";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { readAsSpacing } from "src/enums";
import { quotesStyles, BlockQuote } from "./QuoteImageSectionContainer";
import Quotes from "../../../assets/quotes.svg";
import { Author, SmallText } from "../../../styles/FontFaces";
import { colors } from "../../../styles/variables";
import { CmsImage } from "../../Image/CmsImage";
import { readCmsImageData } from "../../Image/utils";
import BaseSection from "../BaseSection";

export const QuoteImageSectionFragment = graphql(`
	fragment QuoteImageSectionItem on QuoteImageSectionRecord {
		id
		authorPosition
		spacingTop
		spacingBottom
		showQuotationMarks
		quote
		quoteAuthor: author
		quoteAuthorPosition: authorPosition
		image {
			...ImageFields
			responsiveImage(imgixParams: { w: 768 }) {
				# 🥃 TODO: find correct image dimensions
				...ResponsiveImage
			}
		}
		imageMobile {
			...ImageFields
			responsiveImage(imgixParams: { w: 768 }) {
				# 🥃 TODO: find correct image dimensions
				...ResponsiveImage
			}
		}
		showQuotationMarks
	}
`);

export const QuoteImageSection: React.FC<{
	section: FragmentType<typeof QuoteImageSectionFragment>;
}> = ({ section }) => {
	const {
		image,
		imageMobile,
		spacingTop,
		spacingBottom,
		showQuotationMarks,
		quote,
		quoteAuthor,
		quoteAuthorPosition,
	} = getFragmentData(QuoteImageSectionFragment, section);
	const imageData = readCmsImageData(image);
	const imageMobileData = readCmsImageData(imageMobile);

	return (
		<BaseSection
			className="relative"
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
		>
			<div className="hidden sm:block">
				<CmsImage image={imageData} />
			</div>
			<div className="block sm:hidden">
				<CmsImage image={imageMobileData} />
			</div>
			<BlockQuote as="blockquote">
				{showQuotationMarks && <Quotes css={quotesStyles} />}
				<ReactMarkdown>{quote}</ReactMarkdown>
				{quoteAuthor && (
					<footer>
						<cite>
							<Author>{quoteAuthor}</Author>
							<SmallText color={colors.gray.text}>
								{quoteAuthorPosition}
							</SmallText>
						</cite>
					</footer>
				)}
			</BlockQuote>
		</BaseSection>
	);
};
