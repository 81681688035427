import { Spinner } from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import React from "react";
import { colors } from "../../../styles/variables";

const LoadingScreen: React.FC = () => {
	const t = useTranslations();

	return (
		<div className="flex flex-col items-center justify-center p-px-30">
			<p className="text-size-7 m-0 pb-px-10 text-center font-sans">
				{t("loading")}
			</p>
			<Spinner
				data-chromatic="ignore"
				thickness="10px"
				speed="2s"
				emptyColor={colors.ice}
				color={colors.blueberry}
				size="xl"
				height="150px"
				width="150px"
				margin="25px"
			/>
		</div>
	);
};

export default LoadingScreen;
