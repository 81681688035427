"use client";

import React, { FC } from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { readAsSpacing } from "src/enums";
import TeamMemberTile from "./TeamMemberTile";
import ContentContainer from "../../styled/ContentContainer";
import Masonry from "../../styled/Masonry";
import BaseSection from "../BaseSection";

export const MeetTheTeamSectionFragment = graphql(`
	fragment MeetTheTeamSectionItem on MeetTheTeamSectionRecord {
		id
		spacingTop
		spacingBottom
		members {
			id
			description
			name
			contact
			githubUrl
			twitterUrl
			linkedInUrl
			xingUrl
			image {
				...ImageFields
				responsiveImage(imgixParams: { w: 768 }) {
					# 🥃 TODO: find correct image dimensions
					...ResponsiveImage
				}
			}
		}
	}
`);

export const MeetTheTeamSection: FC<{
	section: FragmentType<typeof MeetTheTeamSectionFragment>;
}> = ({ section }) => {
	const { members, spacingTop, spacingBottom } = getFragmentData(
		MeetTheTeamSectionFragment,
		section,
	);

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
		>
			<ContentContainer>
				<Masonry>
					{members.map((member, index) => (
						<TeamMemberTile
							key={member.id}
							member={member}
							index={index}
						/>
					))}
				</Masonry>
			</ContentContainer>
		</BaseSection>
	);
};
