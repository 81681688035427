import { cn } from "@peerigon/pupper/tailwind";
import { motion } from "framer-motion";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useToggle } from "react-use";
import { MeetTheTeamSectionItemFragment } from "src/__generated__/graphql";
import { readCmsImageData } from "src/components/Image/utils";
import StyledImage from "./StyledImage";
import TileWrapper from "./TileWrapper";
import { SmallText } from "../../../styles/FontFaces";

type TeamMemberTileProps = {
	member: MeetTheTeamSectionItemFragment["members"][0];
	index: number;
};

const TeamMemberTile: React.FC<TeamMemberTileProps> = ({ member, index }) => {
	const imageData = readCmsImageData(member.image);
	const columnIndex = index % 3;

	const [isOpen, onToggle] = useToggle(false);

	return (
		<TileWrapper
			className={cn(
				"relative overflow-hidden text-almostBlack shadow-light",
				columnIndex === 0 && "lg:m-0",
				columnIndex === 1
					? "self-end lg:mx-auto lg:my-0"
					: "lg:mb-auto lg:ml-auto lg:mr-0 lg:mt-0",
			)}
		>
			<button
				type="button"
				onClick={onToggle}
				className="absolute h-full w-full"
			>
				<StyledImage
					image={imageData}
					zoom={isOpen ? 1.04 : 1}
					alt={member.name}
				/>
				<motion.div
					initial="visible"
					animate={isOpen ? "hidden" : "visible"}
					variants={{
						visible: { bottom: "0rem" },
						hidden: { bottom: "-8rem" },
					}}
					className="absolute flex h-[8rem] w-full items-center justify-center bg-white text-[1.6rem] font-bold uppercase leading-[130%] tracking-[0.08em]"
				>
					{member.name}
				</motion.div>
			</button>
			<motion.dialog
				initial="hidden"
				onClick={onToggle}
				open={isOpen}
				transition={{ damping: 18, type: "spring" }}
				animate={isOpen ? "visible" : "hidden"}
				variants={{
					visible: {
						bottom: 0,
						backgroundColor: "rgb(255, 255, 255, 0.85)",
					},
					hidden: {
						bottom: "-100%",
						backgroundColor: "rgb(255, 255, 255, 0)",
					},
				}}
				className="b-0 font-normal m-auto flex h-full w-full flex-col items-center justify-end px-[20px] py-[30px] text-center text-[1.8rem] leading-[160%] hover:cursor-pointer"
			>
				<p className="mb-[5px] text-[1.6rem] font-[480] uppercase tracking-widest lg:text-[2rem]">
					{member.name}
				</p>
				<SmallText>{member.description}</SmallText>
				{member.contact && (
					<div className="mt-[0.3125rem]">
						{/* 🥃 TODO This className is a temporary fix, we should change the type of the contact field to Markdown in Dato */}
						<ReactMarkdown className="[&>p]:flex [&>p]:flex-col">
							{member.contact}
						</ReactMarkdown>
					</div>
				)}
			</motion.dialog>
		</TileWrapper>
	);
};

export default TeamMemberTile;
