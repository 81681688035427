import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { useTranslations } from "next-intl";
import React, { memo } from "react";
import { useMedia } from "react-use";
import LoadMoreButton from "src/components/sections/MapSection/LoadMoreButton";
import Event, { EventType } from "./Event";
import LoadingScreen from "./LoadingScreen";
import NoEventsScreen from "./NoEventsScreen";
import { breakpoints } from "../../../styles/variables";

const EventList: React.FC<{
	selectedEvent?: EventType;
	events: Array<EventType>;
	loading: boolean;
	onSelectEvent: (event: EventType | undefined) => void;
	onNextPage: () => void;
	onPreviousPage: () => void;
	canNextPage: boolean;
	canPreviousPage: boolean;
}> = ({
	canPreviousPage,
	canNextPage,
	loading,
	events,
	selectedEvent,
	onSelectEvent,
	onPreviousPage,
	onNextPage,
}) => {
	const isWide = useMedia(`(min-width: ${breakpoints.xl}px)`, false);
	const t = useTranslations();

	return (
		<div className="border border-gray-line bg-white">
			<div className="h-[2.6rem] bg-almostBlack" />
			{events.length > 0 ? (
				<ul className="m-0 h-[32.8rem] list-none p-0 xl:h-[37.6rem]">
					{events.map((event) => (
						<Event
							key={`${event.data.title}-${event.data.start}-${event.data.end}`}
							event={event}
							selected={
								selectedEvent
									? event.data.title ===
										selectedEvent.data.title
									: false
							}
							onSelectEvent={onSelectEvent}
						/>
					))}
				</ul>
			) : loading ? (
				<LoadingScreen />
			) : (
				<NoEventsScreen />
			)}
			<LoadMoreButton
				disabled={!canPreviousPage}
				onClick={onPreviousPage}
			>
				{isWide ? (
					t("earlierEvents")
				) : (
					<ArrowLeftIcon
						marginBottom="4px"
						aria-label={t("earlierEvents")}
					/>
				)}
			</LoadMoreButton>
			<LoadMoreButton disabled={!canNextPage} onClick={onNextPage}>
				{isWide ? (
					t("laterEvents")
				) : (
					<ArrowRightIcon
						marginBottom="4px"
						aria-label={t("laterEvents")}
					/>
				)}
			</LoadMoreButton>
		</div>
	);
};

export default memo(EventList);
