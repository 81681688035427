import { cn } from "@peerigon/pupper/tailwind";
import React from "react";
import type { ComponentProps } from "react";

type ContactFormRowProps = ComponentProps<"div">;

const ContactFormRow: React.FC<ContactFormRowProps> = (props) => {
	const { className, ...restProps } = props;
	return (
		<div
			className={cn(
				"w-full pb-[1.5rem]",
				"lg:flex lg:flex-row lg:flex-nowrap lg:justify-between lg:pb-0",
				"lg:[&_>_*]:mt-px-10 lg:[&_>_*]:w-[calc(50%-0.8rem)]",
				"lg:[&_>_*:first-of-type:last-of-type]:w-full",
				className,
			)}
			{...restProps}
		/>
	);
};

export default ContactFormRow;
