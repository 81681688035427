import { cn } from "@peerigon/pupper/tailwind";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import Badge from "./Badge";
import { colors } from "../../../styles/variables";
import Link from "../../Link/Link";
import TextAndAnimatedArrow from "../../TextAndAnimatedArrow/TextAndAnimatedArrow";

export const CrossLinkElementFieldsFragment = graphql(`
	fragment CrossLinkElementFields on CrossLinkRecord {
		id
		description
		destinationText
		image {
			...ImageFields
			responsiveImage(imgixParams: { w: 768 }) {
				# 🥃 TODO: find correct image dimensions
				...ResponsiveImage
			}
		}
		linkToPage {
			__typename
			... on PageRecord {
				id
				routeName
			}
			... on BlogPostPageRecord {
				id
				routeName
			}
		}
		badgeText
	}
`);

export const CrossLink: React.FC<{
	element: FragmentType<typeof CrossLinkElementFieldsFragment>;
	background?: string | null | undefined;
}> = ({ element, background }) => {
	const { description, destinationText, linkToPage, badgeText } =
		getFragmentData(CrossLinkElementFieldsFragment, element);

	if (!linkToPage) {
		return null;
	}

	return (
		<Link to={linkToPage.routeName} className="hover:no-underline">
			<div
				style={{
					"--backgroundColor": background
						? background
						: colors.nightSky,
				}}
				className="group relative m-auto flex min-h-[190px] flex-col justify-between bg-[var(--backgroundColor)] p-px-30 text-ice zoom-hover-effect-1.07 md:w-full"
			>
				{badgeText && <Badge text={badgeText} />}
				<div
					className={cn(
						"m-0 mb-px-30 font-sans text-px-20 font-bold leading-large-2 sm:mb-0 sm:w-[70%] md:text-px-24",
						badgeText && "mt-px-30 sm:ml-px-30 sm:mt-0",
					)}
				>
					{description}
				</div>
				<TextAndAnimatedArrow
					text={destinationText}
					alignSelf="flex-end"
				/>
			</div>
		</Link>
	);
};
