"use client";

import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { CmsImageData } from "src/components/Image/utils";
import { readAsSpacing } from "src/enums";
import { getColorTheme } from "src/styles/variables";
import { CmsImage } from "../../Image/CmsImage";
import BaseSection from "../BaseSection";
import { Markdown } from "../MarkdownSection/MarkdownSection";

type FullWidthPhotoTextSectionFragmentProps = {
	section: FragmentType<typeof FullWidthPhotoTextSectionFragment>;
};

export const FullWidthPhotoTextSectionFragment = graphql(`
	fragment FullWidthPhotoTextSectionItem on FullWidthPhotoTextSectionRecord {
		__typename
		id
		spacingTop
		spacingBottom
		sectionText: text
		images {
			...ImageFields
			responsiveImage(imgixParams: { w: 768 }) {
				# 🥃 TODO: find correct image dimensions
				...ResponsiveImage
			}
		}
		colorThemeEnum
	}
`);

export const FullWidthPhotoTextSection: React.FC<
	FullWidthPhotoTextSectionFragmentProps
> = ({ section }) => {
	const { spacingTop, spacingBottom, colorThemeEnum, sectionText, images } =
		getFragmentData(FullWidthPhotoTextSectionFragment, section);

	const firstImage = images.find((image): image is CmsImageData =>
		Boolean(image),
	);

	if (!firstImage) return null;

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
			style={{
				"--color-theme-fg": colorThemeEnum
					? getColorTheme(colorThemeEnum).fg
					: "inherit",
				"--color-theme-bg": colorThemeEnum
					? getColorTheme(colorThemeEnum).bg
					: "inherit",
			}}
			className="bg-[var(--color-theme-bg)] text-[var(--color-theme-fg)]"
		>
			<div className="h-[30vh] max-h-[90rem] min-h-[32rem] md:h-[50vh]">
				<CmsImage
					key={firstImage.url}
					image={firstImage}
					priority={true}
					objectFit="contain"
					title={firstImage.responsiveImage?.alt ?? undefined}
					className="h-full w-full object-cover"
				/>
			</div>
			{sectionText && (
				<div className="m-auto w-4/5 py-10 text-center md:w-3/5 md:py-20 2xl:w-2/5">
					<Markdown source={sectionText} />
				</div>
			)}
		</BaseSection>
	);
};
