import { cn, tw } from "@peerigon/pupper/tailwind";

type ButtonAlignments = "left" | "center" | "right";

type ButtonContainerProps = {
	align?: ButtonAlignments;
	alignSm?: ButtonAlignments;
	marginTop?: string;
	className?: string;
	children?: React.ReactNode;
};

const ButtonContainer: React.FC<ButtonContainerProps> = ({
	align,
	alignSm,
	marginTop,
	className,
	children,
}) => {
	const classNamesMap: Record<ButtonAlignments, string> = {
		left: tw`text-left`,
		center: tw`text-center`,
		right: tw`text-right`,
	};
	const classNamesSmMap: Record<ButtonAlignments, string> = {
		left: tw`sm:text-left`,
		center: tw`sm:text-center`,
		right: tw`sm:text-right`,
	};

	return (
		<div
			className={cn(
				"z-above-default mt-[var(--marginTop)] w-full",
				classNamesMap[align ?? "left"],
				alignSm && classNamesSmMap[alignSm],
				className,
			)}
			style={{
				"--marginTop": marginTop,
			}}
		>
			{children}
		</div>
	);
};

export default ButtonContainer;
