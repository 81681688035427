import styled from "@emotion/styled";
import { transitions } from "../../../styles/variables";
import { CmsImage } from "../../Image/CmsImage";

const StyledImage = styled(CmsImage)<{ zoom: number }>`
	height: auto;
	width: 100%;
	transform: scale(${({ zoom }) => zoom});
	transition: transform ${transitions.regular};

	:hover {
		text-decoration: none;
		transform: scale(1.02);
	}
`;

export default StyledImage;
