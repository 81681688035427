import { cn } from "@peerigon/pupper/tailwind";
import React from "react";
import { FigureFieldsFragment } from "src/__generated__/graphql";
import {
	FontSize,
	FontSizeType,
	readAsFontSize,
	readAsFontStyle,
} from "src/enums";
import FigureBuildUp from "./FigureBuildUp";
import FigureIncreasing from "./FigureIncreasing";
import { SmallText } from "../../../styles/FontFaces";
import { getColorTheme } from "../../../styles/variables";

const sizeDictTw = {
	xs: "text-[5.5rem] sm:text-[3.4rem] lg:text-[3.8rem] xl:text-[5.5rem]",
	sm: "text-[5.5rem] sm:text-[3.4rem] lg:text-[3.8rem] xl:text-[5.5rem]",
	md: "text-[6.8rem] sm:text-[4rem] lg:text-[4.4rem] xl:text-[6.8rem]",
	lg: "text-[6rem] sm:text-[8rem] lg:text-[11rem] xl:text-[14rem]",
	xl: "text-[14rem] sm:text-[11rem] xl:text-[22rem]",
	"2xl": "text-[14rem] sm:text-[11rem] xl:text-[22rem]",
};

const fontSizes = ["xs", "sm", "md", "lg", "xl", "2xl"] as const;
const getRandomizedDelay = (fontSize: FontSizeType, animationRate: number) => {
	const fontSizeIndex = fontSizes.indexOf(fontSize);
	const transitions = [
		"transition delay-150",
		"transition delay-300",
		"transition delay-500",
		"transition delay-700",
	];
	// fake randomization of delay to avoid hydration errors of .random()
	const hash = fontSizeIndex * Math.floor((animationRate + 1) * 3);
	const indexInRange = hash % transitions.length;
	return transitions[indexInRange];
};

const FigureCard: React.FC<{
	card: FigureFieldsFragment;
	visible?: boolean;
}> = ({ card, visible = false }) => {
	const {
		description,
		figure,
		figureSuffix,
		figurePrefix,
		fontSize: fontSizeRaw,
		fontStyle: fontStyleRaw,
		bigBlock,
		colorThemeEnum,
		animationRate,
	} = card;

	const fontSize = readAsFontSize(fontSizeRaw);
	const fontStyle = readAsFontStyle(fontStyleRaw);

	const transitionDelay = getRandomizedDelay(fontSize, animationRate);
	const fontSizeTw = sizeDictTw[fontSize];

	return (
		<div
			style={{
				"--color-theme-fg": colorThemeEnum
					? getColorTheme(colorThemeEnum).fg
					: "inherit",
				"--color-theme-bg": colorThemeEnum
					? getColorTheme(colorThemeEnum).bg
					: "inherit",
			}}
			className={cn(
				"relative",
				"bg-[var(--color-theme-bg)] text-[var(--color-theme-fg)]",
				bigBlock
					? "pt-[100%] sm:col-span-2 sm:col-start-auto sm:pt-[50%]"
					: "pt-[100%]",
			)}
		>
			<div
				className={cn(
					"flex flex-col flex-nowrap items-center justify-between",
					"absolute inset-0 p-px-20",
					visible
						? "translate-y-0 opacity-100"
						: "translate-y-[4rem] opacity-0",
					"transition delay-200 duration-500 ease-out",
					transitionDelay,
				)}
			>
				<p
					className={cn(
						"m-0 flex flex-grow items-center justify-center",
						fontSize === FontSize.xl
							? "gap-0"
							: "gap-[0.1ch]" /* reduce too large gap on 'xl' font sizes */,
						fontSizeTw,
					)}
				>
					<span
						className={cn(
							"text-[0.5em] font-lighter",
							fontStyle.endsWith("italic") ? "italic" : "",
						)}
					>
						{figurePrefix}
					</span>

					{animationRate > 0 ? (
						// animated figures are increasing
						<FigureIncreasing
							figure={figure}
							fontStyle={fontStyle}
							animationRate={animationRate}
						/>
					) : (
						// static figures get a build up animation
						<FigureBuildUp
							visible={visible}
							fontStyle={fontStyle}
							figure={figure}
						/>
					)}

					<span
						className={cn(
							"text-[0.5em] font-lighter",
							fontStyle.endsWith("italic") ? "italic" : "",
						)}
					>
						{figureSuffix}
					</span>
				</p>
				<SmallText textAlign="center">{description}</SmallText>
			</div>
		</div>
	);
};

export default FigureCard;
