import { cn } from "@peerigon/pupper/tailwind";
import React from "react";
import Title, { HeadingLevel } from "../Title/Title";
import { Markdown } from "../sections/MarkdownSection/MarkdownSection";

type Props = {
	title?: string | null;
	description?: string | null;
	headingLevel?: HeadingLevel;
	color?: string;
};

const TitleDescription: React.FC<Props> = ({
	title,
	description,
	headingLevel = "h2",
	color,
}) => {
	if (!title && !description) {
		return null;
	}

	return (
		<header className="mx-auto flex max-w-[80rem] flex-col flex-nowrap items-center px-5">
			{title && (
				<Title
					headingLevel={headingLevel}
					textAlign="center"
					color={color}
				>
					{title}
				</Title>
			)}
			{description && (
				<div className="mt-[3.5rem]">
					<Markdown
						source={description}
						css={{ color, textAlign: "center" }}
					/>
				</div>
			)}
			<div className={cn(description ? "mt-[3.5rem]" : "mt-[5rem]")} />
		</header>
	);
};

export default TitleDescription;
