"use client";

import { Box, Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import React, { useState } from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { readAsSpacing } from "src/enums";
import { mediaQuery } from "../../../styles/mixins";
import { colors, heights } from "../../../styles/variables";
import Button from "../../Button/Button";
import { CmsImage } from "../../Image/CmsImage";
import { readCmsImageData } from "../../Image/utils";
import StaticMap from "../../StaticMap/StaticMap";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

export const LocationSketchSectionFragment = graphql(`
	fragment LocationSketchSectionItem on LocationSketchSectionRecord {
		id
		switchToSketchText
		switchToMapText
		spacingTop
		spacingBottom
		coordinates {
			latitude
			longitude
		}
		sketch {
			...ImageFields
			responsiveImage(imgixParams: { w: 768 }) {
				# 🥃 TODO: find correct image dimensions
				...ResponsiveImage
			}
		}
	}
`);

const styleHeight = css`
	height: ${heights.locationSection.mobileInPx}px;
	${mediaQuery.md} {
		height: ${heights.locationSection.tabletInPx}px;
	}
	${mediaQuery.lg} {
		height: ${heights.locationSection.desktopInPx}px;
	}
`;

const positionRelative = css`
	position: relative;
`;

export const LocationSketchSection: React.FC<{
	section: FragmentType<typeof LocationSketchSectionFragment>;
	accessToken: string;
}> = ({ section, accessToken }) => {
	const {
		switchToMapText,
		switchToSketchText,
		coordinates,
		sketch,
		spacingTop,
		spacingBottom,
	} = getFragmentData(LocationSketchSectionFragment, section);
	const sketchData = readCmsImageData(sketch);
	const { mobileInPx, tabletInPx, desktopInPx } = heights.locationSection;

	const [sketchActive, toggleSketch] = useState(false);

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
		>
			<ContentContainer css={positionRelative}>
				<StaticMap
					accessToken={accessToken}
					center={{
						lat: coordinates.latitude,
						lon: coordinates.longitude,
					}}
					pins={[
						{
							lat: coordinates.latitude,
							lon: coordinates.longitude,
							label: "rocket",
							color: colors.blueberry.substring(1),
						},
					]}
					zoomLevel={6}
					css={styleHeight}
				/>
				{sketchData && sketchActive && (
					<Box
						position="absolute"
						z-index={300}
						top={0}
						width="100%"
						height={[
							mobileInPx,
							mobileInPx,
							tabletInPx,
							desktopInPx,
						]}
					>
						<CmsImage
							image={sketchData}
							css={styleHeight}
							objectFit="cover"
						/>
					</Box>
				)}

				{sketch && (
					<Flex
						justify="center"
						marginTop={[-8, -8, -8, -30]}
						zIndex={1}
					>
						<Button onClick={() => toggleSketch(!sketchActive)}>
							{sketchActive
								? switchToMapText
								: switchToSketchText}
						</Button>
					</Flex>
				)}
			</ContentContainer>
		</BaseSection>
	);
};
