"use client";

import { Box, Grid } from "@chakra-ui/react";
import { css } from "@emotion/react";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { readAsSpacing, TextAlignmentType } from "src/enums";
import { getColumnData } from "./helpers";
import { dimensions } from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import Downloadable from "../../Downloadable/Downloadable";
import TitleDescription from "../../TitleDescription/TitleDescription";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";
import { Markdown } from "../MarkdownSection/MarkdownSection";

const markdownStyles = (textAlignment: TextAlignmentType) => css`
	&,
	* {
		text-align: ${textAlignment} !important;
	}
`;

export const MulticolumnSectionFragment = graphql(`
	fragment MulticolumnSectionItem on MulticolumnSectionRecord {
		__typename
		id
		anchor
		columns {
			value
		}
		layout
		spacingTop
		spacingBottom
		textAlignment
		sectionTitle: title
		sectionDescription: descriptionMd
		downloadables: listItems {
			asset: icon {
				url
			}
			buttonText: text
			title: headline
		}
	}
`);

export const MulticolumnSection: React.FC<{
	section: FragmentType<typeof MulticolumnSectionFragment>;
}> = ({ section }) => {
	const resolvedSectionData = getFragmentData(
		MulticolumnSectionFragment,
		section,
	);
	const { columns, templateColumns, padding } =
		getColumnData(resolvedSectionData);
	const {
		anchor,
		spacingTop,
		spacingBottom,
		textAlignment,
		sectionTitle,
		sectionDescription,
		downloadables,
	} = resolvedSectionData;

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
		>
			<Anchor id={anchor} />
			<ContentContainer>
				<TitleDescription
					title={sectionTitle}
					description={sectionDescription}
				/>
				<Box paddingX={padding} textAlign={textAlignment as any}>
					<Grid
						columnGap={dimensions.spacing.px30}
						rowGap={dimensions.spacing.px30}
						templateColumns={templateColumns}
					>
						{columns.map(({ content, id }) => {
							return (
								<Box key={id}>
									<Markdown
										css={markdownStyles(
											(textAlignment as
												| TextAlignmentType
												| undefined
												| null) ?? "left",
										)}
										source={content.value}
									/>
								</Box>
							);
						})}
						{downloadables.map((element) => (
							<Downloadable
								key={element.buttonText}
								downloadable={element}
							/>
						))}
					</Grid>
				</Box>
			</ContentContainer>
		</BaseSection>
	);
};
