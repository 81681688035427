"use client";

import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";

import { readAsSpacing } from "src/enums";
import {
	StyledDurationContainer,
	StyledDurationAmount,
	ShadowContainer,
	ImageContainer,
	StyledDurationUnit,
	StyledGrid,
	ButtonContainer,
	StyledLink,
	Divider,
	StyledInfoItemTitle,
} from "./StyledContainer";
import { mediaQuery } from "../../../styles/mixins";
import { colors, textPresets } from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import Button from "../../Button/Button";
import { CmsImage } from "../../Image/CmsImage";
import { readCmsImageData } from "../../Image/utils";
import Title from "../../Title/Title";
import ContentContainer from "../../styled/ContentContainer";
import HeadingAddOn from "../../styled/HeadingAddOn";
import BaseSection from "../BaseSection";
import { Markdown } from "../MarkdownSection/MarkdownSection";

const styleAddOn = css`
	padding-bottom: 8px;
	${mediaQuery.md} {
		padding-bottom: 0;
	}
`;

const styleButton = css`
	width: 100%;
`;

const styleMarkdown = css`
	p {
		font-size: ${textPresets.size8.fontSize};
		line-height: ${textPresets.size8.lineHeight};

		${mediaQuery.lg} {
			font-size: ${textPresets.size8.fontSizeLg};
		}
	}
`;

const ConsultingOfferSectionFragment = graphql(`
	fragment ConsultingOfferSectionItem on ConsultingOfferSectionRecord {
		__typename
		anchor
		id
		consultingHeadline: headline
		consultingHeadlineAddOn: headlineAddOn
		consultingDescription: description
		spacingTop
		spacingBottom
		image {
			...ImageFields
			responsiveImage(imgixParams: { w: 755 }) {
				...ResponsiveImage
			}
		}
		duration
		linkText
		referencedPage {
			... on PageRecord {
				id
				routeName
			}
		}
		mailTo
		infoItems {
			headline
			text
		}
	}
`);

export const ConsultingOfferSection: React.FC<{
	section: FragmentType<typeof ConsultingOfferSectionFragment>;
}> = ({ section }) => {
	const {
		anchor,
		spacingTop,
		spacingBottom,
		consultingHeadline,
		consultingHeadlineAddOn,
		consultingDescription,
		image,
		duration,
		linkText,
		infoItems,
		mailTo,
		referencedPage,
	} = getFragmentData(ConsultingOfferSectionFragment, section);
	const resolvedImage = readCmsImageData(image);

	const [durationAmount, durationUnit] = duration.split(" ");

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
			hideOverflow
		>
			<Anchor id={anchor} />
			<ContentContainer>
				<ShadowContainer>
					<ImageContainer>
						<CmsImage
							image={resolvedImage}
							objectFit="cover"
							style={{ objectFit: "cover" }}
						/>
						<Flex justifyContent="center">
							<StyledDurationContainer>
								<StyledDurationAmount>
									{durationAmount}
								</StyledDurationAmount>
								<StyledDurationUnit>
									{durationUnit}
								</StyledDurationUnit>
							</StyledDurationContainer>
						</Flex>
					</ImageContainer>
					<StyledGrid>
						{consultingHeadlineAddOn && (
							<HeadingAddOn
								addOn={consultingHeadlineAddOn}
								color={colors.blueberry}
								css={styleAddOn}
							/>
						)}
						<Title headingLevel="h2" margin="0">
							{consultingHeadline}
						</Title>
						{consultingDescription && (
							<Box
								marginTop={{ xs: "10px", lg: "20px" }}
								minHeight={{ md: "105px", lg: "150px" }}
							>
								<Markdown source={consultingDescription} />
							</Box>
						)}
						{linkText && (mailTo || referencedPage?.routeName) && (
							<ButtonContainer>
								<StyledLink
									to={
										referencedPage?.routeName ??
										`mailto:${mailTo}`
									}
								>
									<Button
										backgroundColor={colors.nightSky}
										css={styleButton}
									>
										{linkText}
									</Button>
								</StyledLink>
							</ButtonContainer>
						)}
						<Divider />
						<SimpleGrid
							columns={{
								xs: 1,
								md: infoItems.length >= 2 ? 2 : 1,
							}}
							spacing={{ xs: "15px", md: "20px", lg: "40px" }}
							width={{
								xs: "100%",
								md: infoItems.length >= 2 ? "100%" : "70%",
							}}
						>
							{infoItems.map((item) => (
								<div key={item.headline}>
									<StyledInfoItemTitle>
										{item.headline}
									</StyledInfoItemTitle>
									<Markdown
										source={item.text}
										css={styleMarkdown}
									/>
								</div>
							))}
						</SimpleGrid>
					</StyledGrid>
				</ShadowContainer>
			</ContentContainer>
		</BaseSection>
	);
};
