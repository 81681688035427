"use client";

import { cn } from "@peerigon/pupper/tailwind";
import Script from "next/script";
import { useTranslations } from "next-intl";
import { Hx, HxBoundary } from "uberschrift";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { CmsImage } from "src/components/Image/CmsImage";
import { readCmsImageData } from "src/components/Image/utils";
import Link from "src/components/Link/Link";
import ContentContainer from "src/components/styled/ContentContainer";

import { readAsSpacing } from "src/enums";
import BaseSection from "../BaseSection";

export const PipedriveFormSectionFragment = graphql(`
	fragment PipedriveFormSectionItem on PipedriveFormSectionRecord {
		id
		heading
		subtitle
		pipedriveFormUrl
		pipedriveFormImage: image {
			...ImageFields
			responsiveImage(imgixParams: { w: 768 }) {
				# 🥃 TODO: find correct image dimensions
				...ResponsiveImage
			}
		}
		spacingTop
		spacingBottom
		formTitle
		email
		phone
	}
`);

export const PipedriveFormSection: React.FC<{
	section: FragmentType<typeof PipedriveFormSectionFragment>;
}> = ({ section }) => {
	const t = useTranslations();
	const {
		heading,
		subtitle,
		pipedriveFormUrl,
		pipedriveFormImage,
		spacingTop,
		spacingBottom,
		formTitle,
		email,
		phone,
	} = getFragmentData(PipedriveFormSectionFragment, section);

	const resolvedImage = readCmsImageData(pipedriveFormImage);

	return (
		<BaseSection
			paddingTop={readAsSpacing(spacingTop)}
			paddingBottom={readAsSpacing(spacingBottom)}
		>
			<Script src="https://webforms.pipedrive.com/f/loader" />
			<ContentContainer>
				<div className="leading-7 md:grid md:grid-cols-2">
					<HxBoundary>
						<div className="flex flex-col justify-center md:pr-10">
							<div className="order-2 md:order-1">
								<div
									// this is a hacky solution but ensures the heading is aligned with the form
									// on mobile if there's no formTitle
									className={cn(
										!(formTitle && formTitle.length > 0) &&
											"md:mx-none mx-auto w-full max-w-[48rem] md:max-w-full",
									)}
								>
									<span className="text-3xl font-extra-bold md:text-5xl">
										{heading}
									</span>
								</div>
								{subtitle && subtitle.length > 0 && (
									<p className="pt-6 text-px-16 md:pt-10 md:text-px-18">
										{subtitle}
									</p>
								)}
								{phone &&
									phone.length > 0 &&
									email &&
									email.length > 0 && (
										<div className="m-auto grid max-w-fit grid-cols-[1fr_3fr] gap-x-4 pt-6 text-px-14 md:m-0 md:max-w-full md:text-px-18">
											<div>
												<span className="uppercase">
													{t("phone")}
												</span>
											</div>
											<div>
												<Link
													key="telephone"
													to={`tel:${phone}`}
													className="whitespace-nowrap hover:underline focus:underline"
												>
													{phone}
												</Link>
											</div>
											<div>
												<span className="uppercase">
													{t("email")}
												</span>
											</div>
											<div>
												<Link
													key="email"
													to={`mailto:${email}`}
													className="whitespace-nowrap hover:underline focus:underline"
												>
													{email}
												</Link>
											</div>
										</div>
									)}
							</div>
							{resolvedImage && (
								<div className="overflow-hidden pb-6 md:order-1 md:pb-0 md:pt-10">
									<CmsImage
										image={resolvedImage}
										className="m-auto max-w-96 object-cover shadow-md md:m-0"
									/>
								</div>
							)}
						</div>
						<div className="flex flex-col justify-center pt-6 md:col-start-2 md:pl-10 md:pt-0">
							<div>
								<div
									className="pipedriveWebForms flex flex-col items-center"
									data-pd-webforms={pipedriveFormUrl}
								>
									{formTitle && formTitle.length > 0 && (
										<Hx className="w-full max-w-[48rem] pb-4 text-px-28 font-extra-bold">
											{formTitle}
										</Hx>
									)}
								</div>
							</div>
						</div>
					</HxBoundary>
				</div>
			</ContentContainer>
		</BaseSection>
	);
};
